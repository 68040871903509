<template>
  <div class="small-plan-part">
    <div class="title flex-row-between-center">
      <span>创意基本信息</span>
      <span class="edit" @click="editItem">编辑</span>
    </div>
    <div class="container">
      <p v-for="(item, index) in resultData" :key="index">
        <span class="item-title">{{ item.title }}：</span>
        <span class="item-result">{{ item.result }}</span>
      </p>
    </div>
    <el-dialog
      title="创意基本信息"
      append-to-body
      width="1000px"
      top="20px"
      v-dialogDrag
      :visible.sync="showDialog"
    >
      <div class="abse"></div>
      <div
        class="originality flex-row-between-start"
        style="height: 720px; overflow: auto"
      >
        <div>
          <section>
            <h3>制作创意</h3>
            <div class="margin-top:20px">
              <div class="per-part flex-row-start-center">
                <span class="required">创意方式</span>
                <el-radio-group v-model="params.type">
                  <el-radio-button :label="1">自定义创意</el-radio-button>
                  <el-radio-button :label="2">程序化创意</el-radio-button>
                </el-radio-group>
              </div>
              <!-- 广告位置 -->
              <!-- <div class="per-part flex-row-start-center">
                <span class="required">广告位置</span>
                <el-radio-group v-model="params.position">
                  <el-radio-button :label="1">优选广告位</el-radio-button>
                  <el-radio-button :label="2">按媒体制定位置</el-radio-button>
                </el-radio-group>
              </div> -->
            </div>
          </section>
          <section>
            <h3>创意分类</h3>
            <div>
              <div class="per-part flex-row-start-start">
                <span class="required" style="margin-top: 10px">创意分类</span>
                <div>
                  <el-cascader
                    :options="cascaderData"
                    :props="casProps"
                    v-model="params.thirdIndustryId"
                  ></el-cascader>
                </div>
              </div>
              <div class="per-part flex-row-start-center">
                <span class="">创意标签</span>
                <el-input
                  placeholder="最多二十个标签，每个不超过十个字，可以空格分隔"
                  style="width: 600px"
                  v-model="tmpcategoryString"
                  class="input-with-select"
                  clearable
                  @keyup.native.enter="handleAddCategory"
                >
                  <el-button @click="handleAddCategory" slot="append"
                    >添加(回车键)</el-button
                  >
                </el-input>
              </div>
              <div class="per-part flex-row-start-start">
                <el-card class="selected-box" style="width: 600px">
                  <div slot="header" class="selected-header">
                    <span>已选</span>
                    <el-button
                      @click="handleClearTags"
                      style="float: right; padding: 3px 0"
                      type="text"
                      >清空</el-button
                    >
                  </div>
                  <div class="selected-container">
                    <el-tag
                      :key="index"
                      v-for="(tag, index) in categorys"
                      closable
                      :disable-transitions="false"
                      @close="handleCloseCategory(tag, index)"
                    >
                      {{ tag }}
                    </el-tag>
                  </div>
                </el-card>
              </div>
            </div>
          </section>
          <section>
            <h3>创意信息</h3>
            <div>
              <div class="per-part flex-row-start-center">
                <span :class="{ required: adResourceReq }">广告来源</span>
                <el-input
                  minLength="4"
                  maxlength="20"
                  show-word-limit
                  v-model="params.source"
                  style="width: 400px"
                  placeholder="4-20个字"
                ></el-input>
              </div>
              <div
                class="per-part flex-row-start-center"
                v-if="
                  this.tmpData.planTarget &&
                  this.tmpData.planTarget.landingType === 'APP' &&
                  this.tmpData.planTarget.appType === 'APP_ANDROID'
                "
              >
                <span>APP副标题</span>
                <el-input
                  maxlength="24"
                  minlength="4"
                  v-model="params.subTitle"
                  style="width: 400px"
                  placeholder="4到24个字"
                ></el-input>
              </div>
              <div
                class="per-part flex-row-start-center"
                v-if="params.type === 2"
              >
                <span class="required">自动生成视频素材</span>
                <el-radio-group v-model="params.isPresentedVideo">
                  <el-radio-button :label="0">不启用</el-radio-button>
                  <el-radio-button :label="1">启用</el-radio-button>
                </el-radio-group>
              </div>
              <div
                class="per-part flex-row-start-center"
                v-if="params.type === 2"
              >
                <span class="required">最优创意衍生计划</span>
                <el-radio-group v-model="params.generateDerivedAd">
                  <el-radio-button :label="0">不启用</el-radio-button>
                  <el-radio-button :label="1">启用</el-radio-button>
                </el-radio-group>
              </div>
              <div class="per-part flex-row-start-center">
                <span class="required">广告评论</span>
                <el-radio-group v-model="params.isCommentDisable">
                  <el-radio-button :label="1">关闭</el-radio-button>
                  <el-radio-button :label="0">开启</el-radio-button>
                </el-radio-group>
              </div>
              <div class="per-part flex-row-start-center">
                <span class="required">客户端下载视频</span>
                <el-radio-group
                  v-model="params.adDownloadStatus"
                  :disabled="disableVideo"
                >
                  <el-radio-button :label="1">关闭</el-radio-button>
                  <el-radio-button :label="0">开启</el-radio-button>
                </el-radio-group>
              </div>
              <div class="per-part flex-row-start-start">
                <span class="required">创意展现</span>
                <div>
                  <el-radio-group v-model="params.creativeDisplayMode">
                    <el-radio-button label="CREATIVE_DISPLAY_MODE_CTR"
                      >优选模式</el-radio-button
                    >
                    <el-radio-button label="CREATIVE_DISPLAY_MODE_RANDOM"
                      >轮播模式</el-radio-button
                    >
                  </el-radio-group>
                  <p style="margin-top: 10px">
                    选择优选模式,系统会自动对效果好的创意进行展示量倾斜，创意效果越好展示量越高
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section v-if="showCreativeCard">
            <h3>推广卡片</h3>
            <div class="card-container">
              <div class="card-item" v-for="(item, index) in creativeCardsList">
                <span class="card-account">{{ item.advertiserName }}</span>
                <div class="card-choise" @click="handleChangeCard(item, index)">
                  <span class="required">卡片选择</span>
                  <div>
                    <el-input
                      readonly
                      v-model="item.componentId"
                      placeholder="点击选择卡片"
                      style="width: 160px"
                    ></el-input>
                  </div>
                </div>
                <div
                  v-if="downloadDetailReq"
                  class="url-choise"
                  @click="handleChangeSite(item, index)"
                >
                  <span class="required">下载详情</span>
                  <div>
                    <el-input
                      readonly
                      v-model="item.webUrl"
                      placeholder="点击选择站点"
                      style="width: 160px"
                    ></el-input>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="button-list">
        <div class="button-box">
          <el-button type="primary" @click="handleSubmit">确定</el-button>
          <el-button @click="showDialog = false">取消</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="系统推荐卖点"
      :visible.sync="showRPoint"
      :before-close="handlePRCancel"
      append-to-body
    >
      <el-checkbox-group v-model="checkbox1">
        <el-checkbox :label="1">海量精彩内容</el-checkbox>
        <el-checkbox :label="2">沉浸式阅读体验</el-checkbox>
        <el-checkbox :label="3">海量精品小说</el-checkbox>
        <el-checkbox :label="4">精彩剧情免费看</el-checkbox>
        <el-checkbox :label="5">商家强势推荐</el-checkbox>
        <el-checkbox :label="6">商家倾情推荐</el-checkbox>
      </el-checkbox-group>
      <div class="button-list">
        <el-button type="primary" @click="handlePROk">确定</el-button>
        <el-button @click="handlePRCancel">取消</el-button>
      </div>
    </el-dialog>
    <!-- 多账号标签配置 -->
    <el-dialog
      title="多账号编辑创意标签"
      :visible.sync="showTagDialog"
      append-to-body
      width="800px"
    >
      <div class="tag-container flex-row-start-start">
        <div class="account">
          <p
            @click="currentAccountTag = index"
            :class="{ active: currentAccountTag === index }"
            v-for="(item, index) in multipleAccount"
            :key="index"
          >
            {{ item.name }}
          </p>
        </div>
        <div class="choose-tag">
          <div class="per-part flex-row-start-center">
            <span class="required">创意标签</span>
            <el-input
              placeholder="最多二十个，每个不超过十个字，可空格分隔"
              style="width: 600px"
              v-model="categoryString"
              class="input-with-select"
              @keyup.native.enter="handleAddCategoryMultiple"
              clearable
            >
              <el-button @click="handleAddCategoryMultiple" slot="append"
                >添加(回车键)</el-button
              >
            </el-input>
          </div>
          <div class="per-part flex-row-start-start">
            <el-card class="selected-box" style="width: 600px">
              <div slot="header" class="selected-header">
                <span>已选</span>
                <el-button
                  @click="handleClearAccountTags"
                  style="float: right; padding: 3px 0"
                  type="text"
                  >清空</el-button
                >
              </div>
              <div class="selected-container">
                <el-tag
                  :key="index2"
                  v-for="(tag, index2) in multipleAccount[currentAccountTag]
                    .tag"
                  closable
                  :disable-transitions="false"
                  @close="handleCloseCategoryAccount(tag, index2)"
                >
                  {{ tag }}
                </el-tag>
              </div>
            </el-card>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showTagDialog = false">取 消</el-button>
        <el-button type="primary" @click="showTagDialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 多账号卖点配置  -->
    <el-dialog
      title="多账号编辑推广卖点"
      :visible.sync="showRecommendDialog"
      append-to-body
      width="800px"
    >
      <div class="tag-container flex-row-start-start">
        <div class="account">
          <p
            @click="currentAccountTag = index"
            :class="{ active: currentAccountTag === index }"
            v-for="(item, index) in multipleAccount"
            :key="index"
          >
            {{ item.name }}
          </p>
        </div>
        <div class="choose-tag">
          <div class="per-part flex-row-start-center">
            <span class="required">推广卖点</span>
            <el-button type="primary" size="mini" @click="">
              添加推广卖点
            </el-button>
            <el-button
              style="margin-left: 10px"
              type="primary"
              size="mini"
              @click="showRPoint = true"
            >
              系统推荐卖点
            </el-button>
          </div>
          <el-input
            placeholder="请输入推广卖点"
            v-model="categoryString"
            class="input-with-select"
            clearable
            style="width: 300px; margin-left: 160px; margin-right: 10px"
          >
          </el-input>
          <i class="el-icon-delete" style="cursor: pointer"></i>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showRecommendDialog = false">取 消</el-button>
        <el-button type="primary" @click="showRecommendDialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 卡片选择 -->
    <el-dialog
      title="推广卡片选择"
      :visible.sync="showSelectCard"
      append-to-body
      width="900px"
      top="20px"
    >
      <div class="cards-box">
        <div class="cards-filter">
          <el-input
            style="width: 200px; margin-right: 10px"
            placeholder="请输入卡片名"
            v-model="cardName"
            clearable
            class="input-with-select"
          >
            <el-button
              @click="handleCardPageChange"
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
          <el-input
            style="width: 200px; margin-right: 10px"
            placeholder="请输入卡片id"
            v-model="cardId"
            clearable
            class="input-with-select"
          >
            <el-button
              @click="handleCardPageChange"
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
          <el-select
            style="width: 200px; margin-right: 10px"
            v-model="cardStatus"
            placeholder="请选择"
            multiple
            clearable
            @change="handleCardPageChange"
          >
            <el-option
              v-for="item in cardStatusList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button type="primary" size="mini" @click="handleCardPageChange">
            刷新
          </el-button>
        </div>
        <div class="cards-container" v-loading="cardLoading">
          <p
            style="width: 100%; text-align: center"
            v-if="cardList.length === 0"
          >
            暂无数据,<el-button
              type="primary"
              size="mini"
              @click="handleAddCard"
              >添加卡片</el-button
            >
          </p>
          <div
            class="cards-item"
            @click="handleChangeChecked(index)"
            :class="{ itemchecked: item.checked }"
            v-for="(item, index) in cardList"
          >
            <div class="item-header" @click.stop="">
              <el-checkbox v-model="cardList[index].checked"></el-checkbox>
            </div>
            <div class="item-body">
              <img src="@/assets/img/douyincard.png" alt="" />
              <p class="status">{{ item.status | statusName }}</p>
            </div>
            <div class="item-footer">
              <p class="name">{{ item.component_name }}</p>
              <p class="id">{{ item.component_id }}</p>
            </div>
          </div>
        </div>
        <div class="cars-page">
          <el-pagination
            style="margin-top: 10px; text-align: right"
            background
            layout="total, prev, pager, next, jumper"
            @current-change="handleCardPageChange"
            :current-page="cardPage"
            :page-size="cardPageSize"
            :total="cardTotal"
          >
          </el-pagination>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="showSelectCard = false">取消</el-button>
        <el-button type="primary" @click="handleSendCards">确定</el-button>
      </span>
    </el-dialog>
    <!-- 下载详情 原站点选择 -->
    <el-dialog
      title="下载详情"
      :visible.sync="showSelectSite"
      append-to-body
      width="900px"
      top="20px"
    >
      <downloadDetail
        v-if="showSelectSite"
        :currenAccountId="currenAccountId"
        @selectedSuccess="handleSendSite"
        @close="showSelectSite = false"
      />
    </el-dialog>
    <!-- 添加卡片 -->
    <el-dialog
      title="添加推广卡片"
      class="edit-dialog"
      :visible.sync="showAddCard"
      width="500px"
    >
      <template v-if="showAddCard">
        <addCard
          :showLocal="false"
          :Foptions="cardOption"
          @handlePageChange="handleCardPageChange"
          @close="showAddCard = false"
        />
      </template>
    </el-dialog>
  </div>
</template>

<script>
import downloadDetail from "../components/downloadDetail.vue";
import addCard from "../components/addCard";
import {
  getIndustryList,
  getCreativeComponent,
  getOeRemark,
} from "@/api/toutiao";
export default {
  data() {
    return {
      showDialog: false,
      showRPoint: false,
      showTagDialog: false,
      checkbox1: [],
      params: {
        creativeDisplayMode: "CREATIVE_DISPLAY_MODE_CTR",
        isCommentDisable: 0,
        generateDerivedAd: 0,
        isPresentedVideo: 0,
        adDownloadStatus: 1,
        type: 1,
      },
      categoryString: null,
      tmpcategoryString: null,
      categoryArray: [],
      categorys: [],
      multipleAccount: [
        {
          name: "重庆吉吉猫01",
          tag: [],
        },
        {
          name: "重庆吉吉猫02",
          tag: [],
        },
      ],
      currentAccountTag: 0,
      showRecommendDialog: false,
      tmpData: null,
      adResourceReq: false,
      cascaderData: [],
      casProps: {
        value: "industry_id",
        label: "industry_name",
      },
      resultData: [],
      test: true,
      // 推广卡片相关
      showSelectCard: false, //选择卡片
      cardList: [],
      creativeCardsList: [],
      cardPage: 1,
      cardPageSize: 15,
      cardName: null,
      cardId: null,
      currenAccountIndex: null,
      currenAccountId: null,
      currentAccountName: null,
      cardStatus: null,
      tmpChecked: false,
      showSelectSite: false,
      cardStatusList: [
        {
          name: "通过",
          value: "PASS",
        },
        {
          name: "审核中",
          value: "UNDER",
        },
        {
          name: "未通过",
          value: "REJECT",
        },
      ],
      cardLoading: false,
      cardTotal: 0,
      cardRowList: [
        {
          label: "ID",
          prop: "component_id",
        },
        {
          label: "组件名",
          prop: "component_name",
        },
        {
          label: "操作",
          action: "operation",
        },
      ],
      showAddCard: false,
      cardOption: [],
      downloadDetailReq: false,
      disableDownloadVideo: false,
      showCreativeCard: true,
    };
  },
  inject: ["templateDetial"],
  mounted() {
    getIndustryList()
      .then((res) => {
        this.cascaderData = res;
      })
      .finally(() => {});
  },
  filters: {
    statusName: function (value) {
      switch (value) {
        case "PASS":
          return "通过";
        case "UNDER":
          return "审核中";
        case "REJECT":
          return "未通过";
        default:
          break;
      }
    },
  },
  watch: {},
  computed: {
    disableVideo() {
      // 创意方式为自定义可以修改视频下载
      if (this.params.type == 1) {
        return false;
      }
      if (this.disableDownloadVideo) {
        this.params.adDownloadStatus = 0;
        return true;
      }
    },
  },
  methods: {
    // 卡片组相关
    handleAddCard() {
      this.addCardLoading = true;
      getOeRemark({
        page: 1,
        pageSize: 15,
        name: this.currentAccountName,
      })
        .then((res) => {
          if (res.list.length) {
            let tmpid = res.list[0].id;
            this.cardOption = [
              {
                advertiserName: this.currentAccountName,
                id: tmpid,
              },
            ];
            this.showAddCard = true;
          }
        })
        .finally(() => {
          this.addCardLoading = false;
        });
    },
    handleChangeSite(item, index) {
      this.currenAccountIndex = index;
      this.currenAccountId = item.advertiserId;
      this.showSelectSite = true;
    },
    handleSendCards() {
      this.cardList.forEach((item) => {
        if (item.checked) {
          return (this.creativeCardsList[this.currenAccountIndex].componentId =
            item.component_id);
        }
      });
      this.showSelectCard = false;
    },
    handleSendSite(url) {
      this.creativeCardsList[this.currenAccountIndex].webUrl = url;
      this.showSelectSite = false;
    },
    handleChangeChecked(index1) {
      this.cardList.forEach((item, index2) => {
        if (index1 === index2) {
          if (this.cardList[index2].checked) {
            this.$set(this.cardList[index2], "checked", false);
          } else {
            this.$set(this.cardList[index2], "checked", true);
          }
        } else {
          this.$set(this.cardList[index2], "checked", false);
        }
      });
    },
    handleCardPageChange(page) {
      if (typeof page == "number") {
        this.cardPageSize = page;
      }
      this.cardLoading = true;
      getCreativeComponent({
        page: this.cardPage,
        pageSize: this.cardPageSize,
        advertiserId: this.currenAccountId,
        componentId: this.cardId,
        componentType: "PROMOTION_CARD",
        componentName: this.cardName,
        status: this.cardStatus ? this.cardStatus.join(",") : null,
      })
        .then((res) => {
          this.cardTotal = res.page_info.total_number;
          this.cardList = res.list;
        })
        .finally(() => {
          this.cardLoading = false;
        });
    },
    handleChangeCard(item, index) {
      this.currenAccountIndex = index;
      this.currenAccountId = item.advertiserId;
      this.currentAccountName = item.advertiserName;
      this.showSelectCard = true;
      setTimeout(() => {
        this.handleCardPageChange();
      }, 200);
    },
    handleUpdateData(type) {
      this.tmpData = this.templateDetial();
      if (type === "clear") {
        this.params = {
          creativeDisplayMode: "CREATIVE_DISPLAY_MODE_CTR",
          isCommentDisable: 0,
          generateDerivedAd: 0,
          isPresentedVideo: 0,
          adDownloadStatus: 1,
          type: 1,
        };
        if (
          this.tmpData.planRange &&
          this.tmpData.planRange.deliveryRange === "UNION"
        ) {
          this.params.adDownloadStatus = 0;
          this.disableDownloadVideo = true;
        }
        return this.handleEchoData("clear");
      }
      // 若不存在缓存数据不操作
      if (!this.tmpData.basisCreative) {
        return;
      }
      this.params = { ...this.tmpData.basisCreative };
      if (
        this.tmpData.planRange &&
        this.tmpData.planRange.deliveryRange === "UNION"
      ) {
        this.params.adDownloadStatus = 0;
        this.disableDownloadVideo = true;
        this.tmpData.creativeCards = [];
      }
      // 还原标签
      if (this.params.adKeywords) {
        this.categorys = this.params.adKeywords.split("$");
      } else {
        this.categorys = [];
      }
      this.categoryString = this.tmpData.basisCreative.adKeywords
        .split("$")
        .join(" ");
      let tmpstring = this.params.thirdIndustryId.toString();
      this.params.thirdIndustryId = [
        parseInt(tmpstring.substring(0, 4)),
        parseInt(tmpstring.substring(0, 6)),
        parseInt(tmpstring.substring(0, 8)),
      ];
      this.handleAddCategory();
      // 推广目的为非应用下载或者应用下载且download_type为"EXTERNAL_URL时"时广告来源必填
      if (
        this.tmpData.planTarget.landingType !== "APP" ||
        (this.tmpData.planTarget.landingType === "APP" &&
          this.tmpData.planTarget.downloadType === "EXTERNAL_URL")
      ) {
        this.adResourceReq = true;
      } else {
        this.adResourceReq = false;
      }
      // 推广目的为应用下载且下载方式为DOWNLOAD_URL下载链接 下载详情必选
      if (
        this.tmpData.planTarget.landingType === "APP" &&
        this.tmpData.planTarget.downloadType === "DOWNLOAD_URL"
      ) {
        this.downloadDetailReq = true;
      } else {
        this.downloadDetailReq = false;
      }
      this.creativeCardsList = [];
      this.tmpData.creativeCards.forEach((item) => {
        this.creativeCardsList.push({
          ...item,
        });
      });
      this.handleEchoData();
    },
    handleClearTags() {
      this.categorys = [];
    },
    handleClearAccountTags() {
      this.multipleAccount[this.currentAccountTag].tag = [];
    },
    handleCloseCategoryAccount(tag, index) {
      this.multipleAccount[this.currentAccountTag].tag.splice(index, 1);
    },
    handleCloseCategory(tag, index) {
      this.categorys.splice(index, 1);
    },
    handleAddCategoryMultiple() {
      let tmpcate = this.categoryString.includes(" ")
        ? this.categoryString.split(" ")
        : [this.categoryString];
      // 去重
      tmpcate = Array.from(new Set(tmpcate));
      this.params.adKeywords = this.categoryString;
      this.multipleAccount[this.currentAccountTag].tag = [
        ...this.multipleAccount[this.currentAccountTag].tag,
        ...tmpcate,
      ];
    },
    handleInputChange(query) {
      this.categoryString = query;
    },
    handleAddCategory() {
      if (!this.tmpcategoryString) {
        return;
      }
      this.tmpcategoryString = this.tmpcategoryString.trim();
      this.categoryString = this.tmpcategoryString;
      let tmpcate = this.categoryString.includes(" ")
        ? this.categoryString.split(" ")
        : [this.categoryString];
      // 去重
      tmpcate = Array.from(new Set([...this.categorys, ...tmpcate]));
      // 去除空格
      tmpcate = tmpcate.filter((item) => {
        if (item !== "") {
          return item;
        }
      });
      this.categorys = tmpcate;
      this.tmpcategoryString = "";
    },
    editItem() {
      if (!this.tmpData.channels || this.tmpData.channels.length === 0) {
        return this.$message.error("请先在账号模块添加账号");
      }
      // 推广范围为穿山甲不展示客户端下载视频
      if (!this.tmpData.planRange) {
        return this.$message.error("请先完善计划基本信息模块");
      }
      if (this.tmpData.planRange.deliveryRange === "UNION") {
        if (
          this.tmpData.basisCreative &&
          this.tmpData.basisCreative.type == 2
        ) {
          this.params.adDownloadStatus = 0;
          this.disableDownloadVideo = true;
        }
      }
      // 推广目的为非应用下载或者应用下载且download_type为"EXTERNAL_URL时"时广告来源必填
      if (!this.tmpData.planTarget || !this.tmpData.planTarget.landingType) {
        return this.$message.error("请先完善计划基础信息模块");
      }
      if (
        this.tmpData.planTarget.landingType !== "APP" ||
        (this.tmpData.planTarget.landingType === "APP" &&
          this.tmpData.planTarget.downloadType === "EXTERNAL_URL")
      ) {
        this.adResourceReq = true;
      } else {
        this.adResourceReq = false;
      }
      // 推广目的为应用下载且下载方式为DOWNLOAD_URL下载链接 下载详情必选
      if (
        this.tmpData.planTarget.landingType === "APP" &&
        this.tmpData.planTarget.downloadType === "DOWNLOAD_URL"
      ) {
        this.downloadDetailReq = true;
      } else {
        this.downloadDetailReq = false;
      }
      if (
        !this.tmpData.creativeCards ||
        this.tmpData.creativeCards.length === 0
      ) {
        this.tmpData.creativeCards = [];
        this.tmpData.channels.forEach((item) => {
          this.tmpData.creativeCards.push({
            advertiserId: item.advertiserId,
            webUrl: null,
            componentId: null,
          });
        });
      }
      // 穿山甲不展示推广卡片
      if (!this.handleJudgeShowCreatCards()) {
        this.showCreativeCard = false;
      } else {
        this.showCreativeCard = true;
      }
      // 深拷贝 到这里 到添加账号处去同步creaticve
      this.creativeCardsList = [];
      this.tmpData.creativeCards.forEach((item) => {
        this.creativeCardsList.push({
          ...item,
        });
      });
      this.showDialog = true;
    },
    handleSubmit() {
      // 广告来源字数限制
      if (
        !this.params.source ||
        this.params.source.length < 4 ||
        this.params.source.length > 20
      ) {
        return this.$message.error("广告来源字数必须在4-20个字");
      }
      // APP副标题字数限制
      if (
        this.tmpData &&
        this.tmpData.planTarget.landingType === "APP" &&
        this.tmpData.planTarget.appType === "APP_ANDROID" &&
        (this.params.source.subTitle < 4 || this.params.source.subTitle > 24)
      ) {
        return this.$message.error("APP副标题字数必须在4-20个字");
      }
      // 创意标签
      if (this.categorys.length > 20) {
        return this.$message.error("创意标签至多20个");
      }
      // 创意分类
      if (!this.params.thirdIndustryId || !this.params.thirdIndustryId.length) {
        return this.$message.error("请选择创意分类");
      }
      // 推广卡片
      // 如果为穿山甲...不需要推广卡片
      let flag = true;
      if (!this.handleJudgeShowCreatCards()) {
        this.tmpData.creativeCards = [];
      } else {
        this.creativeCardsList.forEach((item) => {
          if (this.downloadDetailReq) {
            if (!item.componentId || !item.webUrl) {
              if (flag) {
                this.$message.error("请完善推广卡片项");
              }
              return (flag = false);
            }
          } else {
            if (!item.componentId) {
              if (flag) {
                this.$message.error("请完善推广卡片项");
              }
              return (flag = false);
            }
          }
        });
        if (!flag) {
          return;
        }
        this.tmpData.creativeCards = this.creativeCardsList.map((item) => ({
          ...item,
        }));
      }

      // 同步到总数据
      this.tmpData.basisCreative = {
        ...this.params,
        thirdIndustryId:
          this.params.thirdIndustryId[this.params.thirdIndustryId.length - 1],
        adKeywords: this.categorys.join("$"),
      };
      // this.tmpData.creativeCards = this.creativeCardsList.map((item) => ({
      //   ...item,
      // }));
      this.handleEchoData();
      this.showDialog = false;
    },
    handleJudgeShowCreatCards() {
      // 穿山甲不需要推广卡片
      if (
        this.tmpData.planRange &&
        this.tmpData.planRange.deliveryRange === "UNION" &&
        this.tmpData.planRange.inventoryType.includes("INVENTORY_UNION_SLOT")
      ) {
        console.log("是穿山甲");
        return false;
      }
      //只选择头条不需要
      console.log(this.tmpData.planRange.inventoryType, "需要查看");
      if (this.tmpData.planRange.inventoryType == "INVENTORY_FEED") {
        console.log("是头条");
        return false;
      }
      //只选择西瓜不需要
      if (this.tmpData.planRange.inventoryType == "INVENTORY_VIDEO_FEED") {
        console.log("是西瓜");
        return false;
      }
      return true;
    },
    handleEchoData(type) {
      this.resultData = [];
      if (type === "clear") {
        return;
      }
      this.params.type === 1
        ? this.resultData.push({ title: "创意方式", result: "自定义创意" })
        : this.resultData.push({ title: "创意方式", result: "程序化创意" });
      this.resultData.push({ title: "创意标签", result: this.categoryString });
      this.resultData.push({ title: "广告来源", result: this.params.source });
      this.resultData.push({
        title: "创意分类",
        result:
          this.params.thirdIndustryId[this.params.thirdIndustryId.length - 1],
      });
      this.params.subTitle
        ? this.resultData.push({
            title: "APP副标题",
            result: this.params.subTitle,
          })
        : "";
      this.params.type === 1
        ? ""
        : this.params.isPresentedVideo
        ? this.resultData.push({
            title: "自动生成视频素材",
            result: "启用",
          })
        : this.resultData.push({
            title: "自动生成视频素材",
            result: "不启用",
          });
      this.params.type === 1
        ? ""
        : this.params.generateDerivedAd
        ? this.resultData.push({
            title: "最优创意衍生计划",
            result: "启用",
          })
        : this.resultData.push({
            title: "最优创意衍生计划",
            result: "不启用",
          });
      this.params.isCommentDisable
        ? this.resultData.push({
            title: "广告评论",
            result: "关闭",
          })
        : this.resultData.push({
            title: "广告评论",
            result: "开启",
          });
      this.params.adDownloadStatus
        ? this.resultData.push({
            title: "客户端下载视频",
            result: "关闭",
          })
        : this.resultData.push({
            title: "客户端下载视频",
            result: "开启",
          });

      this.params.creativeDisplayMode == "CREATIVE_DISPLAY_MODE_CTR"
        ? this.resultData.push({
            title: "创意展现",
            result: "优选模式",
          })
        : this.resultData.push({
            title: "创意展现",
            result: "轮播模式",
          });
    },
    handleCancel(cb) {},
    showSellingPoint() {
      this.showRPoint = true;
    },
    handlePROk() {
      this.handlePRCancel();
    },
    handlePRCancel() {
      this.showRPoint = false;
    },
  },
  created() {
    this.tmpData = this.templateDetial();
    this.creativeCardsList = this.tmpData.creativeCards;
  },
  components: {
    addCard,
    downloadDetail,
  },
};
</script>
<style lang='scss' scoped>
/deep/ .category-selector input {
  border-radius: 4px 0 0 4px !important;
}
h3 {
  font-size: 18px;
}
.per-part {
  margin-bottom: 20px;

  > span {
    width: 150px;
    text-align: right;
    margin-right: 10px;
  }
}
section {
  > div {
    margin-top: 20px;
  }
}
.upload-image {
  width: 108px;
  height: 108px;
  border: 1px solid #ccc;
  background: #f5f5f5;
}
.recommended-name {
  > span:not(:nth-of-type(1)) {
    color: #3bb8ed;
    margin-left: 10px;
    cursor: pointer;
  }
}
.button-list {
  border-top: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  .button-box {
    margin-top: 20px;
    width: 180px;
  }
}

/deep/ .el-checkbox {
  margin-bottom: 20px;
}

.tag-container {
  border: 1px solid #ccc;
  border-radius: 4px;
  > div {
    height: 500px;
  }
  .account {
    border-right: 1px solid #ccc;
    width: 200px;
    padding: 10px;
    p {
      line-height: 30px;
      cursor: pointer;
    }
    .active {
      color: #2fcba0;
    }
  }
  .choose-tag {
    padding: 10px;
    width: 600px;
    .selected-box {
      margin-left: 124px !important;
    }
  }
}
.selected-box {
  margin-left: 160px;
  /deep/ .el-card__header {
    padding: 4px !important;
  }
  /deep/ .el-card__body {
    padding: 10px;
    min-height: 48px;
  }
  .selected-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span,
    button {
      padding: 5px !important;
    }
  }
  .selected-container {
    .el-tag {
      margin-top: 4px;
      margin-right: 6px;
    }
  }
}
.container {
  padding: 10px;
  p {
    line-height: 20px;
    .item-title {
      color: #9a98a5;
      font-weight: bold;
    }
    .item-result {
      color: #878c8c;
    }
  }
}
// 推广卡片选择
.card-item {
  margin: 20px 0;
  .card-account {
    font-size: 16px;
  }
  .card-choise,
  .url-choise {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    .required {
      margin-right: 10px;
    }
  }
}
.cards-box {
  .cards-filter {
    padding: 0 20px;
  }
  .cards-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: 560px;
    overflow-y: auto;
    padding: 0 20px;
    .cards-item {
      width: 250px;
      padding: 15px;
      height: 450px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 10px;
      margin-right: 20px;
      .item-header {
        margin-left: auto;
        height: 20px;
      }
      .item-body {
        width: 200px;
        height: 370px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        p {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          z-index: 2;
          color: #fff;
          height: 28px;
          line-height: 28px;
          font-size: 14px;
          text-align: center;
          background-color: #2f88ff;
        }
      }
      .item-footer {
        width: 100%;
        padding: 10px;
        p {
          height: 24px;
          line-height: 24px;
        }
        .url {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 12px;
        }
        .name {
          font-size: 14px;
          font-weight: bold;
          color: #000;
        }
        .id {
          color: #666;
          font-size: 12px;
        }
      }
    }
    .itemchecked {
      border: 1px solid #00bf8a;
    }
  }
}
.card-container {
  width: 800px;
  margin-left: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .card-item {
    width: 240px;
    margin-right: 20px;
  }
}
</style>
