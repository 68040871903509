<template>
  <div class="base-batch-plan-configure">
    <div class="main-container">
      <add-account ref="accountTmp" />
      <ad-rules-config ref="adtRules" />
      <plan-base-info ref="planBaseInfo" />
      <orientation-package ref="oriPackage" />
      <originality-base-info ref="originalityBase" />
      <title-package ref="titlePackageRef" />
      <originality-resource ref="originalityRef" />
      <!-- <detection-link /> -->
    </div>
  </div>
</template>

<script>
import AddAccount from "./add-account.vue";
import AdRulesConfig from "./ad-rules-config.vue";
import PlanBaseInfo from "./plan-base-info.vue";
import OrientationPackage from "./orientation-package.vue";
import OriginalityBaseInfo from "./originality-base-info.vue";
import TitlePackage from "./title-package.vue";
import OriginalityResource from "./originality-resource.vue";
import DetectionLink from "./detection-link.vue";
export default {
  name: "OceanEngineTemplate",
  data() {
    return {};
  },
  mounted() {},
  watch: {},
  methods: {},
  components: {
    AddAccount,
    PlanBaseInfo,
    TitlePackage,
    AdRulesConfig,
    DetectionLink,
    OrientationPackage,
    OriginalityBaseInfo,
    OriginalityResource,
  },
};
</script>
<style lang='scss' scoped>
.base-batch-plan-configure {
  //   width:1600px;
  .main-container {
    > div {
      display: inline-block;
      &:nth-last-of-type(1) {
        border-right: 1px solid #ccc;
      }
    }
  }
}
</style>