<template>
  <div
    class="library-item box-shadow-light-grey"
    :class="{ 'is-active': selection.includes(info.id) }"
  >
    <div class="item-detail">
      <div class="top-info" v-if="info">
        <span class="wenan absolute" v-if="info.documentCount > 0"
          >{{ info.documentCount }}条文案</span
        >
        <span
          class="tags absolute"
          v-if="Array.isArray(info.tagItems) && info.tagItems.length > 0"
          >{{ info.tagItems.length }}个标签</span
        >
        <span
          class="doc line-overflow absolute"
          v-if="
            Array.isArray(info.documentWares) && info.documentWares.length > 0
          "
        >
          {{ info.documentWares[0].content }}</span
        >
        <i
          class="iconfont icon-bofang"
          v-if="info.materialType === 1 && !canPlayVideo"
          @click="playVideo($event, true)"
        ></i>
        <i
          class="iconfont icon-shanchu1"
          v-if="info.materialType === 1 && canPlayVideo"
          @click="playVideo($event, false)"
        ></i>
        <i
          v-if="editable"
          class="el-icon-delete-solid delete-solid"
          @click="delItem"
        ></i>
        <div
          v-if="[2, 3].includes(info.materialType)"
          class="part-item"
          @click.prevent="addClick"
        >
          <template v-if="info.materialType === 2">
            <!-- <img :src="info.resources[0].url" @load='handleImageOnload(2)' alt="" /> -->
            <c-image :src="info.resources[0].url" height="196"></c-image>
          </template>
          <template v-else>
            <div class="image-list">
              <img
                :style="getImageStyle(info.resources.length)"
                class="box-shadow-light-grey"
                v-for="(item, index) in info.resources.slice(0, 6)"
                :src="item.url"
                :key="index"
                alt=""
              />
            </div>
          </template>
        </div>
        <div v-else class="part-item" @click="addClick">
          <!-- 取第一帧图片 -->
          <img
            :style="getStyle()"
            :src="`${getUrl()}?vframe/jpg/offset/0`"
            v-if="!canPlayVideo"
            alt=""
          />
          <video
            :src="getUrl()"
            :style="getStyle()"
            controls
            autoplay
            v-else
          ></video>
        </div>
        <div class="title" @click.stop="handleItemClick">
          <el-tooltip :content="info.title" placement="top">
            <p class="line-overflow">{{ info.title | formatTitle }}</p>
          </el-tooltip>
          <div class="puter-info">
            <span v-if="info.materialType === 1"
              >时长:{{ info.resources[0].videoTime }}
            </span>
            <span> 上传者：{{ info.adminUserName }} </span>
            <div style="margin-left: auto">
              <span
                :title="`创建时间：${info.createdTime}   ${getDays(
                  info.createdTime,
                  new Date()
                )}天以前创建`"
                style="cursor: help"
              >
                <i class="el-icon-time"></i>
                {{ getDays(info.createdTime, new Date()) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-info">
        <div class="tags line-overflow flex-row-start-start">
          <template
            v-if="Array.isArray(info.tagItems) && info.tagItems.length > 0"
          >
            <el-tag
              type="info"
              v-for="item in info.tagItems.slice(0, 2)"
              :key="item.id"
              >{{ item.name }}</el-tag
            >
            <!-- <el-tag
              v-if="editable"
              type="primary"
              style="margin-left: auto; cursor: pointer"
              @click="editItem"
            >
              编辑
            </el-tag> -->
          </template>
          <template v-else>
            <span>--</span>
            <!-- <el-tag
              type="primary"
              style="margin-left: auto; cursor: pointer"
              @click="editItem"
            >
              编辑
            </el-tag> -->
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDays } from "@/assets/js/utils";
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    selection: {
      type: Array,
      default: () => {
        [];
      },
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      canPlayVideo: false,
    };
  },
  filters: {
    formatTitle(val) {
      return val.replace(/\.\w+/g, "");
    },
  },
  methods: {
    handleItemClick() {
      this.$emit("handleItemClick", this.info);
    },
    getUrl() {
      return this.info.resources ? this.info.resources[0].url : "";
    },
    getStyle() {
      if (this.info.materialType === 1) {
        return {
          height: "250px",
        };
      }
    },
    getImageStyle(len) {
      const obj = {
        1: {
          width: "100%",
          height: "100%",
        },
        2: {
          width: "50%",
          height: "196px",
        },
        3: {
          width: "33%",
          height: "196px",
        },
        4: {
          width: "150px",
          height: "98px",
        },
        5: {
          width: "100px",
          height: "98px",
        },
        6: {
          width: "100px",
          height: "98px",
        },
      };
      return len >= 6 ? obj[6] : obj[len];
    },
    delItem() {
      this.$emit("delLibrary", this.info.id);
    },
    getDays() {
      return getDays(this.info.createdTime, new Date());
    },
    editItem() {
      this.$emit("editItem", this.info);
    },
    playVideo(e, flag) {
      this.canPlayVideo = flag;
    },
    addClick() {
      const isSelected = !this.selection.includes(this.info.id);
      this.$emit("addClick", {
        id: this.info.id,
        isSelection: isSelected,
        videoSrc:
          this.info.materialType === 1
            ? {
                src: this.info.resources[0].url,
                title: this.info.title,
              }
            : null,
        imgUrl: this.info.resources[0].url,
        imgTitle: this.info.title,
      });
    },
  },
  components: {},
};
</script>
<style lang='scss' scoped>
.library-item {
  width: 250px;
  border-radius: 6px;
  overflow: hidden;
  .item-detail {
    .top-info {
      position: relative;
      transition: all 0.2s;
      .absolute {
      }
      .wenan {
        position: absolute;
        top: 0;
        left: 0;
        width: 58px;
        height: 26px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        font-weight: bold;
        line-height: 26px;
        background-color: rgba(0, 0, 0, 0.5);
        border-bottom-right-radius: 6px;
      }
      .tags {
        position: absolute;
        top: 40px;
        left: 0;
        width: 58px;
        height: 26px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        font-weight: bold;
        line-height: 26px;
        background-color: rgba(0, 0, 0, 0.3);
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;
      }
      .doc {
        position: absolute;
        left: 0;
        bottom: 74px;
        width: 100%;
        height: 26px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        font-weight: bold;
        line-height: 26px;
        background-color: rgba(0, 0, 0, 0.3);
        text-align: left;
        padding-left: 5px;
      }
      .icon-bofang {
        cursor: pointer;
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translateX(-50%);
        font-size: 40px;
        color: #ccc;
      }
      .delete-solid {
        position: absolute;
        cursor: pointer;
        display: none;
        left: 95%;
        top: 0%;
        font-size: 14px;
        color: #fd5d5a;
      }
      .icon-shanchu1 {
        position: absolute;
        cursor: pointer;
        left: 90%;
        top: 2%;
        font-size: 20px;
        color: #ccc;
        z-index: 999;
      }
      &:hover {
        .delete-solid {
          display: block;
        }
      }
      .part-item {
        // width:100%;
        // height:196px;
        background: #000;
        > img {
          width: 100%;
          height: 98px;
          object-fit: contain;
        }
        .image-list {
          height: 196px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: wrap;
          img {
            object-fit: cover;
          }
        }
        video {
          width: 100%;
          height: 196px;
          object-fit: contain;
        }
        p {
        }
      }
      .title {
        cursor: pointer;
        padding: 10px;
        line-height: 1.2;
        color: #676767;
        > p {
          font-size: 16px;
          color: #1f2d3d;
        }
        .remark {
          margin-top: 10px;
          color: #475669;
          font-size: 12px;
        }
        .puter-info {
          margin-top: 17px;
          font-size: 12px;
          color: #b7b7b7;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          > span:nth-of-type(2) {
            margin-left: 10px;
          }
        }
      }
    }
    .bottom-info {
      border-top: 1px solid #f1f4f8;
      padding: 0 10px 10px 10px;
      .tags {
        margin-top: 6px;
        /deep/ .el-tag--info {
          margin-right: 5px;
          background: #f1f1f1;
          color: #b7b7b7;
        }
      }
    }
  }
}
.is-active {
  box-shadow: 0 0 0 3px #07c160;
}
</style>
