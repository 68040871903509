<template>
  <div class="add-title small-plan-part">
    <div class="title flex-row-between-center">
      <span>标题包</span>
      <span class="edit" @click="editItem">新增</span>
    </div>
    <div class="container">
      <p
        class="account-item flex-row-between-center"
        v-for="(item, index) in documentList"
        :key="index"
      >
        <span>{{ item.name }}</span>
        <span style="margin-right: auto; color: #00bf8a">{{
          "（" + item.list.length + "）"
        }}</span>
        <i
          style="margin-right: 10px; cursor: pointer"
          class="el-icon-edit"
          @click.stop="handletest(item, index)"
        ></i>
        <i class="el-icon-close" @click.stop="handleDeleteDocument(index)"></i>
      </p>
    </div>
    <el-drawer
      class="firstDrawer"
      title="选择标题包"
      :visible.sync="showChoiceTitle"
      size="1000px"
      append-to-body
      width="1000px"
    >
      <dy-table
        :height="'700'"
        :loading="loading"
        :rowList="rowList"
        :dataList="dataList"
        :page="page"
        :pageSize="pageSize"
        :total="total"
        :showPagination="true"
        class="drawer-flex-1"
        @pageChange="handlePageChange"
      >
        <template #filter>
          <el-button class="margin-right-ten" @click="handlePageChange"
            >刷新数据</el-button
          >
          <c-input
            placeholder="请输入搜索内容"
            class="margin-right-ten"
            v-model="search"
            style="width: 200px"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handlePageChange"
            ></el-button>
          </c-input>
          <el-button
            type="primary"
            class="margin-right-ten"
            @click="addTitlePack"
            >新增标题包</el-button
          >
        </template>
        <template #operation="row">
          <el-button
            type="warning"
            size="small"
            @click="handlePackDetail(row.row)"
            >详情</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="handleUseTitle(row.row)"
            >应用</el-button
          >
        </template>
      </dy-table>
      <div class="demo-drawer__footer">
        <el-button @click="showChoiceTitle = false">取 消</el-button>
        <el-button type="primary" @click="showChoiceTitle = false"
          >确 定</el-button
        >
      </div>
    </el-drawer>
    <!-- 新建标题包 -->
    <el-drawer
      :title="isEditPack ? '修改标题包' : '新增标题包'"
      append-to-body
      size="700px"
      :visible.sync="showAddPackDialog"
    >
      <div class="add-title-pack" v-loading="packLoading">
        <div class="flex-row-start-start">
          <span>标题包名称</span>
          <el-input
            :disabled="isDetail"
            v-model="params.name"
            placeholder="输入名称!"
            style="width: 400px"
          ></el-input>
        </div>
        <div class="flex-row-start-start">
          <span>{{ isDetail ? "标题列表" : "新建标题" }}</span>
          <div class="title-list">
            <el-button v-if="!isDetail" type="primary" @click="handleaddDoc"
              >添加文案</el-button
            >
            <div
              style="width: 450px"
              v-for="(name, index) in params.list"
              :key="index"
            >
              <el-input
                :disabled="isDetail"
                v-model="params.list[index].document"
                class="key-word-single"
                placeholder="文案内容"
                style="width: 400px; padding-right: 50px"
                maxlength="30"
                show-word-limit
              ></el-input>
              <i
                v-if="!isDetail"
                @click="handleDeleteSingleTitle(index)"
                class="el-icon-delete"
              ></i>
              <p v-if="!isDetail">
                插入动态词包：<span
                  class="key-word"
                  @click="handleInsertSingleWord(item, index)"
                  v-for="(item, index2) in wordsPackage"
                  :key="index2"
                  >+{{ item.name }}</span
                >
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="flex-row-start-start">
            <span>选择历史标题</span>
            <div>
              <el-button type="primary" @click="chooseHistoryTitle"
                >选择历史标题</el-button
              >
              <div class="history-title flex-row-start-start-wrap">
                <el-tag
                  :key="index"
                  v-for="(tag, index) in titlePackageOld"
                  closable
                  @close="handleDeleteTitleOld(index)"
                  style="margin: 5px 5px 0 0"
                >
                  {{ tag.title }}
                </el-tag>
              </div>
            </div>
          </div> -->
      </div>
      <div class="button-list" v-if="!isDetail">
        <el-button type="primary" @click="handleSubmit">确定</el-button>
        <el-button @click="showAddPackDialog = false">取消</el-button>
      </div>
    </el-drawer>
    <!-- 选择模板标题 -->
    <add-doc
      :show="showAddDocDialog"
      :curInfo="curInfo"
      :limit="limitNumber"
      @close="handleCloseSelect"
      @success="handleSelectSuccess"
    ></add-doc>
    <!-- 选择历史标签 -->
    <el-dialog
      title="选择历史标题"
      width="800px"
      append-to-body
      :visible.sync="showHistoryTitle"
    >
      <dy-table
        :isUseCheckbox="true"
        :dataList="historyTitle"
        :rowList="historyTitleRow"
        :total="1"
        :pageSize="pageSize"
        :page="pageWithTitle"
        :showPagination="true"
        @selectionChange="handleHistorySelectionChange"
      >
        <template #filter>
          <c-input placeholder="请输入搜索内容" style="width: 300px">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchHistoryTitle"
            ></el-button>
          </c-input>
        </template>
      </dy-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showHistoryTitle = false">取 消</el-button>
        <el-button type="primary" @click="handleHistoryTitleAdd"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import addDoc from "../../material-warehouse/library/add-doc.vue";
import { getCreativeWord, getDocumentPackage } from "@/api/toutiao";
export default {
  data() {
    return {
      page: 1,
      pageWithTitle: 0,
      pageSize: 15,
      search: "",
      total: 0,
      totalWithTitle: 0,
      dataList: [{ id: 1, name: "测试标题", num: 10 }],
      loading: false,
      curTitlePackage: {},
      title: "",
      showHistoryTitle: false,
      showChoiceTitle: false,
      showAddPackDialog: false,
      keyWordList: ["地点", "日期", "星期"],
      rowList: [
        {
          label: "标题包Id",
          prop: "id",
        },
        {
          label: "标题包名称",
          prop: "name",
        },
        {
          label: "标题数量",
          prop: "documentNum",
        },
        {
          label: "创建时间",
          prop: "createdTime",
        },
        {
          label: "操作",
          action: "operation",
        },
      ],
      historyTitle: [
        {
          title: "测试",
        },
      ],
      historyTitleRow: [
        {
          label: "标题",
          prop: "title",
        },
      ],
      titleRowList: [
        {
          label: "标题名称",
          prop: "name",
          align: "center",
        },
      ],
      params: {
        list: [],
      },
      curInfo: {},
      showAddDocDialog: false,
      titlePackageOld: [
        {
          title: "测试01",
        },
      ],
      histtorySelectionData: [],
      wordsPackage: [],
      tmpData: [],
      isDetail: false,
      documentList: [],
      isEditPack: false,
      editPackIndex: null,
      packLoading: false,
      limitNumber: 10,
    };
  },
  inject: ["templateDetial"],
  mounted() {},
  methods: {
    handletest(item, index) {
      let tmpArr = item.list.map((element) => ({
        ...element,
      }));
      this.params = {
        name: item.name,
        list: tmpArr,
      };
      this.isEditPack = true;
      this.editPackIndex = index;
      this.showAddPackDialog = true;
      this.handleGetWordsPackage();
    },
    handleDeleteDocument(index) {
      this.$confirm("确定要删除该文案?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.documentList.splice(index, 1);
      });
    },
    handleUpdateData(type) {
      this.tmpData = this.templateDetial();
      if (type === "clear") {
        this.tmpData.documents = [];
      }
      this.documentList = this.tmpData.documents;
    },
    handleHistoryTitleAdd() {
      this.titlePackageOld = [
        ...this.titlePackageOld,
        ...this.histtorySelectionData,
      ];
      this.showHistoryTitle = false;
    },
    handleHistorySelectionChange(value) {
      this.histtorySelectionData = value;
    },
    handleAddHistoryTitle() {
      this.titlePackageOld.push;
    },
    handleUseTitle(row) {
      let tmpList = [];
      row.list.forEach((item) => {
        tmpList.push({
          document: item.document,
          documentWareId: item.documentWareId,
          id: item.id,
        });
      });
      let tmpDocument = {
        id: row.id,
        list: tmpList,
        name: row.name,
      };
      let isUsedFlag = false;
      this.documentList.forEach((item) => {
        if (tmpDocument.id === item.id) {
          return (isUsedFlag = true);
        }
      });
      if (!isUsedFlag) {
        this.documentList.push(tmpDocument);
        this.$message.success("应用成功");
      } else {
        this.$message.error("已使用过该文案");
      }
    },
    handleSubmit() {
      if (!this.params.name) {
        return this.$message.error("请填写标题包名称");
      }
      if (!this.params.list.length) {
        return this.$message.error("请添加文案数据");
      }
      if (this.params.list.length > 10) {
        return this.$message.error("标题数不能超过十条，请删减");
      }
      // 处理文案数据结构
      let isLanger = true;
      this.params.list.forEach((item) => {
        if (!isLanger) {
          return;
        }
        if (item.document.length > 30) {
          isLanger = false;
          return this.$message.error("文案长度过长，请调整");
        }
        let wordArray = item.document.match(/\{[^\}]+\}/g);
        item.wordId = "";
        if (wordArray && wordArray.length) {
          wordArray.forEach((element) => {
            this.wordsPackage.forEach((words) => {
              if (element.includes(words.name)) {
                if (item.wordId) {
                  item.wordId += "," + words.id;
                } else {
                  item.wordId = words.id + "";
                }
                return;
              }
            });
          });
        } else {
          item.wordId = "";
        }
      });
      if (isLanger) {
        if (this.isEditPack) {
          this.tmpData.documents[this.editPackIndex] = this.params;
        } else {
          this.tmpData.documents.push(this.params);
        }

        this.params = {
          list: [],
        };
        this.curInfo = {};
        this.handleUpdateData();
        this.showAddPackDialog = false;
      }
    },
    handleDeleteTitleOld(index) {
      this.titlePackageOld.splice(index, 1);
    },
    handleCloseSelect() {
      this.showAddDocDialog = false;
    },
    handleSelectSuccess(data) {
      this.params.list = [];
      this.curInfo.documentWareIds = data;
      data.forEach((item) => {
        this.params.list.push({
          document: item.content,
          documentWareId: item.id,
          wordId: "",
        });
      });

      this.showAddDocDialog = false;
    },
    handleInsertSingleWord(word, index) {
      var inputDom = document.querySelectorAll(".key-word-single input")[index];
      var start = inputDom.selectionStart,
        end = inputDom.selectionEnd;
      inputDom.focus(); //输入元素textara获取焦点
      this.params.list[index].document =
        inputDom.value.substring(0, start) +
        "{" +
        word.name +
        "}" +
        inputDom.value.substring(end, inputDom.value.length);
      // this.params.list[index].wordId = word.id;
      inputDom.value = this.params.list[index].document;
      inputDom.focus();
    },
    handleDeleteSingleTitle(index) {
      // documentWareIds未做初始化可能不存在
      if (this.curInfo.documentWareIds) {
        this.curInfo.documentWareIds.splice(index, 1);
      }
      this.params.list.splice(index, 1);
    },
    handlePageChange(page) {
      this.page = typeof page == "number" ? page : this.page;
      this.loading = true;
      getDocumentPackage({
        page: this.page,
        pageSize: this.pageSize,
        name: this.search,
      })
        .then((res) => {
          this.dataList = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editItem() {
      if (
        !this.tmpData ||
        !this.tmpData.channels ||
        !this.tmpData.channels.length
      ) {
        return this.$message.error("请先完善账号模块");
      }
      this.handleGetWordsPackage();
      // if (
      //   this.tmpData &&
      //   this.tmpData.channels &&
      //   this.tmpData.channels.length
      // ) {
      //   this.handleGetWordsPackage();
      // }
      // this.showChoiceTitle = true;
      this.isEditPack = false;
      this.params = {
        name: "",
        list: [],
      };
      this.showAddPackDialog = true;
      if (!this.tmpData.documents) {
        this.tmpData.documents = [];
      }
      // setTimeout(() => {
      //   this.handlePageChange();
      // }, 200);
    },
    handleGetWordsPackage() {
      this.packLoading = true;
      getCreativeWord({
        advertiserId: this.tmpData.channels[0].advertiserId,
      })
        .then((res) => {
          this.wordsPackage = res.map((item) => ({
            name: item.name,
            id: item.creative_word_id,
          }));
        })
        .finally(() => {
          this.packLoading = false;
        });
    },
    addTitlePack() {
      this.params = {
        list: [],
      };
      this.isDetail = false;
      this.showAddPackDialog = true;
      this.curInfo = {};
    },
    handleaddDoc() {
      this.limitNumber = 10 - this.params.list.length;
      this.showAddDocDialog = true;
    },
    handlePackDetail(row) {
      this.showAddPackDialog = true;
      this.curTitlePackage = row;
      this.isDetail = true;
      this.params = this.curTitlePackage;
    },
    chooseHistoryTitle() {
      this.showHistoryTitle = true;
    },
    searchHistoryTitle() {},
    handleConfirm(cb) {
      setTimeout(() => {
        cb();
      }, 2000);
    },
    handleCancel(cb) {
      setTimeout(() => {
        cb();
      }, 2000);
    },
  },
  created() {
    this.tmpData = this.templateDetial();
  },
  components: {
    addDoc,
  },
};
</script>
<style lang='scss' scoped>
.drawer-flex-1 {
  padding: 0 20px;
}
.demo-drawer__footer {
  margin-top: 20px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.title-package {
  margin: 4px 0;
}
.box-card {
  /deep/ .el-card__header {
    padding: 4px !important;
    .title-header {
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .buttons {
        width: 140px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  /deep/ .el-card__body {
    padding: 0px;
    min-height: 48px;
  }
  .card-body {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 531px;
    .body-right {
      margin-top: 4px;
      width: 531px;
    }
  }
}
.close-icon {
  position: absolute;
  left: 90%;
  top: 2%;
  color: #fd5d5a;
  display: none;
  font-size: 14px;
}
.add-title-pack {
  > div {
    margin: 20px 0;
    > span {
      width: 120px;
      text-align: right;
      padding-right: 20px;
    }
    .title-list {
      > div {
        padding-top: 20px;
        > p {
          margin-top: 10px;
          line-height: 1.5;
          width: 100%;
          .key-word {
            color: #55aee6;
            margin-right: 10px;
            cursor: pointer;
          }
        }
      }
      .el-icon-delete {
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
}
.history-title {
  margin-top: 10px;
  .title {
    background: #e9ecf1;
    padding: 6px;
    border-radius: 4px;
    font-size: 14px;
    transition: all 0.2s;
    position: relative;
    .close-icon {
      position: absolute;
      left: 92%;
      top: -10%;
      color: #fd5d5a;
      display: none;
      font-size: 14px;
    }
    &:hover {
      color: #fd5d5a;
      background: #fad4d3;
      cursor: pointer;
      .close-icon {
        display: inline-block;
      }
    }
  }
}
.button-list {
  margin: 20px 20px 20px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.add-title {
  .container {
    .account-item {
      border-radius: 4px;
      background: #e9ecf1;
      font-size: 13px;
      padding: 8px;
      margin: 10px;
      transition: all 0.2s;
      &:hover {
        color: #00bf8a;
      }
    }
  }
}
</style>
