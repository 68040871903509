<template>
  <div class="ad-rules-config small-plan-part">
    <div class="title flex-row-between-center">
      <span>广告组规则配置</span>
      <span class="edit" @click="editItem">编辑</span>
    </div>
    <div class="container">
      <p v-for="(item, index) in resultData" :key="index">
        <span class="item-title">{{ item.title }}：</span>
        <span class="item-result">{{ item.result }}</span>
      </p>
    </div>
    <el-dialog
      class="ad-dialog"
      title="编辑广告组"
      append-to-body
      v-dialogDrag
      :visible.sync="showAd"
      width="800px"
      top="100px"
    >
      <div>
        <el-tabs v-model="tmpUseAd" type="card">
          <el-tab-pane label="新增广告组" name="add">
            <el-form label-width="160px" style="height: 500px">
              <!-- 新建 -->
              <el-form-item prop="" label="多账号分配">
                <el-radio-group v-model="params.accountType">
                  <el-radio-button label="1">多账号一致</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="" label="广告组和广告计划规则">
                <el-radio-group v-model="params.planType">
                  <el-radio-button label="1">所有计划一个组</el-radio-button>
                  <el-radio-button label="2">一个组一个计划</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="" label="营销目的">
                <el-radio-group v-model="params.marketingPurpose">
                  <el-radio-button label="CONVERSION">行动转化</el-radio-button>
                  <el-radio-button label="INTENTION">用户意向</el-radio-button>
                  <el-radio-button label="ACKNOWLEDGE"
                    >品牌认知</el-radio-button
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label-width="80"
                style="margin-left: 92px"
                class="necessary"
                prop=""
                label="推广目的"
              >
                <div>
                  <el-radio-group
                    @change="handleLandingTypeChange"
                    v-model="params.landingType"
                  >
                    <el-radio-button label="LINK">销售线索收集</el-radio-button>
                    <el-radio-button label="APP">应用推广</el-radio-button>
                    <el-radio-button label="QUICK_APP">快应用</el-radio-button>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item prop="" label="广告组预算">
                <el-radio-group v-model="params.budgetMode">
                  <el-radio-button label="BUDGET_MODE_INFINITE"
                    >不限</el-radio-button
                  >
                  <el-radio-button label="BUDGET_MODE_DAY"
                    >指定预算</el-radio-button
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item
                v-if="params.budgetMode == 'BUDGET_MODE_DAY'"
                class="necessary"
                prop=""
                label-width="60"
                style="margin-left: 105px"
                label="日预算"
              >
                <el-input
                  :onlyNum="true"
                  type="number"
                  v-model.number="params.budget"
                  style="width: 300px"
                  placeholder="请输入日预算"
                ></el-input>
              </el-form-item>
              <el-form-item prop="" label="开启状态">
                <el-radio-group v-model="params.operation">
                  <el-radio-button label="enable">开启</el-radio-button>
                  <el-radio-button label="disable">关闭</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label-width="80"
                style="margin-left: 78px"
                class="necessary"
                prop=""
                label="广告组名称"
              >
                <div>
                  <el-input
                    placeholder="广告组名称"
                    v-model="params.campaignName"
                    style="width: 300px"
                    show-word-limit
                    maxlength="35"
                  ></el-input>
                </div>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="已有广告组" name="use">
            <div style="height: 500px">
              <el-form label-width="160px">
                <el-form-item
                  label-width="80"
                  style="margin-left: 12px"
                  class="necessary"
                  prop=""
                  label="推广目的"
                >
                  <div>
                    <el-radio-group
                      @change="handleLandingTypeChange"
                      v-model="params.landingType"
                    >
                      <el-radio-button label="LINK"
                        >销售线索收集</el-radio-button
                      >
                      <el-radio-button label="APP">应用推广</el-radio-button>
                      <el-radio-button label="QUICK_APP"
                        >快应用</el-radio-button
                      >
                    </el-radio-group>
                    <!-- <el-select
                      v-model="params.landingType"
                      @change="handleLandingTypeChange"
                      placeholder="选择推广目的"
                      filterable
                    >
                      <el-option
                        v-for="item in landingTypeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select> -->
                  </div>
                </el-form-item>
              </el-form>
              <div class="use-ad-box">
                <div
                  class="choise-ad"
                  v-for="(item, index) in tmpCampaigns"
                  :key="index"
                >
                  <span class="required choise-account">{{
                    item.advertiserName
                  }}</span>
                  <div
                    class="choise-detail"
                    @click="handleChoiseAd(item, index)"
                  >
                    <span class="required">广告组名: </span>
                    <el-input
                      readonly
                      v-model="item.campaignName"
                      placeholder="点击选择广告组"
                      style="width: 140px"
                    ></el-input>
                  </div>
                  <!-- <template v-if="item.campaignName">
                  <p>
                    <span>预算模式:</span
                    >{{
                      item.budgetMode === "BUDGET_MODE_DAY"
                        ? "日预算"
                        : "总预算"
                    }}
                  </p>
                  <p v-if="item.budget"><span>预算:</span>{{ item.budget }}</p>
                  <p>
                    <span>推广目的:</span
                    >{{
                      item.landingType === "LINK"
                        ? "销售线索收集"
                        : item.landingType === "APP"
                        ? "应用推广"
                        : "快应用"
                    }}
                  </p>
                  <p>
                    <span>营销目的:</span
                    >{{
                      item.marketingPurpose === "CONVERSION"
                        ? "行动转化"
                        : item.marketingPurpose === "CONVERSION"
                        ? "用户意向"
                        : "品牌认知"
                    }}
                  </p>
                  <p>
                    <span>广告组状态:</span
                    >{{ item.operation === "enable" ? "开启" : "关闭" }}
                  </p>
                </template> -->
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="button-list">
          <div>
            <el-button @click="handleSubmit" type="primary">确定</el-button>
            <el-button @click="showAd = false">取消</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 选用广告组 -->
    <el-dialog title="已上传广告组" :visible.sync="showUseAd" width="500">
      <div>
        <dy-table
          ref="tableOut"
          :loading="useAdLoading"
          :dataList="useAdData"
          :rowList="useRowList"
          :showPagination="true"
          :page="useAdPage"
          :pageSize="useAdPageSize"
          :total="useTotal"
          :height="'400'"
          @pageChange="handleUseAdPageChange"
        >
          <template #filter>
            <c-input
              placeholder="请输入广告组名称"
              v-model="useAdSearch"
              class="margin-right-ten"
              style="width: 200px"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="handleUseAdPageChange(1)"
              ></el-button>
            </c-input>
            <el-button
              type="primary"
              size="mini"
              @click="handleUseAdPageChange(1)"
            >
              刷新
            </el-button>
          </template>
          <template #accountType="{ row }">
            <span>{{ row.accountType === 1 ? "多账号一致" : "" }}</span>
          </template>
          <template #budgetMode="{ row }">
            <span>{{
              row.budgetMode === "BUDGET_MODE_INFINITE"
                ? "不限 "
                : row.budgetMode === "BUDGET_MODE_DAY"
                ? "日预算"
                : "总预算"
            }}</span>
          </template>
          <template #marketingPurpose="{ row }">
            <span>{{
              row.marketingPurpose === "CONVERSION"
                ? "行动转化"
                : row.marketingPurpose === "INTENTION"
                ? "用户意向"
                : "品牌认知"
            }}</span>
          </template>
          <template #operation="{ row }">
            <span>{{ row.operation === "enable" ? "开启" : "关闭" }}</span>
          </template>
          <template #landingType="{ row }">
            <span>{{
              row.landingType === "LINK"
                ? "销售线索收集"
                : row.landingType === "APP"
                ? "应用推广"
                : "快应用"
            }}</span>
          </template>
          <template #budget="{ row }">
            <span>{{
              !row.budget || row.budgetMode == "BUDGET_MODE_INFINITE"
                ? "不限"
                : row.budget
            }}</span>
          </template>
          <template #dyoperation="row">
            <el-button type="primary" size="small" @click="handleUseAd(row.row)"
              >应用</el-button
            >
          </template>
        </dy-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getuploadAd } from "@/api/toutiao";
export default {
  data() {
    return {
      showAd: false,
      rules: {},
      curForm: {},
      params: {
        accountType: 1,
        planType: 1,
        marketingPurpose: "CONVERSION",
        budgetMode: "BUDGET_MODE_INFINITE",
        operation: "enable",
        budget: "",
        campaignName: "",
      },
      resultData: [],
      tmpData: null,
      // 已上传广告组相关
      showUseAd: false,
      useAdLoading: false,
      useAdData: [],
      useRowList: [
        {
          label: "广告组名称",
          prop: "campaignName",
        },
        {
          label: "广告组预算",
          prop: "budget",
          slot: true,
        },
        {
          label: "预算类型",
          prop: "budgetMode",
          slot: true,
        },
        {
          label: "广告组状态",
          prop: "operation",
          slot: true,
        },
        {
          label: "营销目的",
          prop: "marketingPurpose",
          slot: true,
        },
        {
          label: "推广目的",
          prop: "landingType",
          slot: true,
        },
        {
          label: "操作",
          action: "dyoperation",
        },
      ],
      useAd: false,
      tmpUseAd: "add",
      useAdPage: 1,
      useAdPageSize: 10,
      useTotal: 0,
      useAdSearch: "",
      useAdAdvertiserId: null,
      tmpCampaigns: [], //临时已选广告组
      useAdParams: {},
      tmpchannels: [],
      currentAdIndex: null,
      currentAdAccountId: null,
      // 推广目的
      landingTypeList: [
        {
          label: "销售线索收集",
          value: "LINK",
        },
        {
          label: " 应用推广",
          value: "APP",
        },
        {
          label: "快应用",
          value: "QUICK_APP",
        },
      ],
    };
  },
  inject: ["templateDetial", "updateAllTemplate"],
  mounted() {},
  watch: {
    tmpUseAd: function (nv, ov) {
      if (nv === "use") {
        this.useAd = true;
      } else {
        this.useAd = false;
      }
    },
  },
  methods: {
    // 切换推广目的时清除已选广告组
    handleLandingTypeChange(type) {
      this.tmpCampaigns.forEach((item) => {
        item.campaignId = null;
        item.campaignName = null;
      });
    },
    editItem() {
      this.tmpchannels = this.tmpData.channels;
      this.tmpCampaigns = this.tmpData.campaigns;
      if (!this.tmpchannels || !this.tmpchannels.length) {
        return this.$message.error("请先完善账号模块");
      }
      if (this.tmpData.campaigns && this.tmpData.campaigns.length) {
        this.tmpCampaigns = this.tmpData.campaigns.map((item) => {
          return {
            advertiserId: item.advertiserId,
            advertiserName: item.advertiserName,
            campaignId: item.campaignId,
            campaignName: item.campaignName,
          };
        });
        this.tmpUseAd = "use";
      } else {
        this.tmpCampaigns = this.tmpchannels.map((item) => {
          return {
            advertiserId: item.advertiserId,
            advertiserName: item.advertiserName,
            campaignId: null,
            campaignName: null,
          };
        });
        this.tmpUseAd = "add";
      }
      this.showAd = true;
    },
    handleUseAdPageChange(page = this.useAdPage) {
      this.useAdPage = page;
      this.useAdLoading = true;
      getuploadAd({
        page: this.useAdPage,
        pageSize: this.useAdPageSize,
        advertiserId: this.currentAdAccountId,
        campaignName: this.useAdSearch,
        landingType: this.params.landingType,
      })
        .then((res) => {
          this.useAdData = res.list;
          this.useTotal = res.total;
        })
        .finally(() => {
          this.useAdLoading = false;
        });
    },
    handleChoiseAd(item, index) {
      if (!this.params.landingType) {
        return this.$message.error("请先选择推广目的");
      }
      this.currentAdAccountId = item.advertiserId;
      this.currentAdIndex = index;
      this.showUseAd = true;
      this.handleUseAdPageChange();
    },
    handleUseAd(row) {
      this.tmpCampaigns[this.currentAdIndex].campaignId = row.campaignId;
      this.tmpCampaigns[this.currentAdIndex].campaignName = row.campaignName;
      // this.tmpCampaigns[this.currentAdIndex].budgetMode = row.budgetMode;
      // this.tmpCampaigns[this.currentAdIndex].budget = row.budget;
      // this.tmpCampaigns[this.currentAdIndex].landingType = row.landingType;
      // this.tmpCampaigns[this.currentAdIndex].marketingPurpose =
      //   row.marketingPurpose;
      // this.tmpCampaigns[this.currentAdIndex].operation = row.operation;
      this.showUseAd = false;
    },
    handleShowUseAd() {
      // this.showUseAd = true;

      this.useAd = !this.useAd;
    },
    handleUpdateData(type) {
      this.tmpData = this.templateDetial();
      if (type === "clear") {
        this.params = {
          accountType: 1,
          planType: 1,
          marketingPurpose: "CONVERSION",
          budgetMode: "BUDGET_MODE_INFINITE",
          operation: "enable",
          budget: "",
          campaignName: "",
          landingType: "LINK",
        };
        this.handleEchoData("clear");
        return;
      }
      //判定是否使用广告组
      if (this.tmpData.campaigns && this.tmpData.campaigns.length) {
        this.useAd = true;
        this.tmpUseAd = "use";
      } else {
        this.useAd = false;
        this.tmpUseAd = "add";
      }
      if (this.tmpData.adRule) {
        this.params = { ...this.tmpData.adRule };
        this.handleEchoData();
      }
    },
    handleEchoData(type) {
      this.resultData = [];
      if (type === "clear") {
        return;
      }
      this.resultData.push({ title: "多账号分配", result: "多账号一致" });
      this.params.planType == 1
        ? this.resultData.push({
            title: "广告组计划规则",
            result: "所有计划一个组",
          })
        : this.resultData.push({
            title: "广告组计划规则",
            result: "一个组一个计划",
          });
      this.params.landingType === "LINK"
        ? this.resultData.push({
            title: "推广目的",
            result: "销售线索收集",
          })
        : this.params.landingType === "APP"
        ? this.resultData.push({
            title: "推广目的",
            result: "应用推广",
          })
        : this.resultData.push({
            title: "推广目的",
            result: "快应用",
          });
      // 使用已有广告组
      if (this.useAd) {
        this.resultData.push({
          title: "使用已有广告组",
          result: "是",
        });
        return;
      } else {
        this.resultData.push({
          title: "使用已有广告组",
          result: "否",
        });
      }
      this.params.marketingPurpose == "CONVERSION"
        ? this.resultData.push({ title: "营销目的", result: "行动转化" })
        : this.params.marketingPurpose == "INTENTION"
        ? this.resultData.push({ title: "营销目的", result: "用户意向" })
        : this.resultData.push({ title: "营销目的", result: "品牌认知" });
      this.params.budgetMode == "BUDGET_MODE_INFINITE"
        ? this.resultData.push({ title: "广告组预算", result: "不限" })
        : this.resultData.push({ title: "广告组预算", result: "指定预算" });
      this.params.budgetMode == "BUDGET_MODE_INFINITE"
        ? ""
        : this.resultData.push({
            title: "日预算",
            result: this.params.budget,
          });
      this.params.operation == "disable"
        ? this.resultData.push({ title: "开启状态", result: "关闭" })
        : this.resultData.push({ title: "开启状态", result: "开启" });
      this.resultData.push({
        title: "广告组名称",
        result: this.params.campaignName,
      });
    },
    handleSubmit() {
      const tmp = this.templateDetial();
      tmp.createdTime = "change";
      if (!this.params.landingType) {
        return this.$message.error("请选择推广目的");
      }
      // 新增广告组
      if (!this.useAd) {
        let paramsList = [
          {
            flag:
              this.params.budgetMode == "BUDGET_MODE_DAY" &&
              !this.params.budget,
            text: "请输入日预算",
          },
          {
            flag: !this.params.campaignName,
            text: "请输入广告组名称",
          },
        ];
        if (!this.$tyParams(paramsList)) {
          return;
        }
        this.tmpData.campaigns = [];
        // 使用广告组
      } else {
        let tmpCampaignsFlag = this.tmpCampaigns.some((item) => {
          if (!item.campaignId) {
            return true;
          } else {
            return false;
          }
        });
        if (tmpCampaignsFlag) {
          return this.$message.error("请选择广告组");
        }
        this.tmpData.campaigns = [...this.tmpCampaigns];
      }
      this.tmpData.adRule = { ...this.params };
      if (this.useAd) {
        this.tmpData.adRule.campaignName = null;
      }
      this.updateAllTemplate();
      this.handleEchoData();
      this.showAd = false;
    },
  },
  components: {},
  created() {
    this.tmpData = this.templateDetial();
  },
};
</script>
<style lang='scss' scoped>
/deep/ .necessary > .el-form-item__label {
  position: relative;
}
/deep/ .necessary > .el-form-item__label::before {
  position: absolute;
  top: 0;
  left: -8px;
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
.ad-rules-config {
}
.button-list {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container {
  padding: 10px;
  p {
    line-height: 20px;
    .item-title {
      color: #9a98a5;
      font-weight: bold;
    }
    .item-result {
      color: #878c8c;
    }
  }
}
.mini-button {
  padding: 4px 10px !important;
  margin: 4px 0 0 4px;
}
/deep/ .mini-button span {
  font-size: 12px !important;
}
.use-ad-box {
  width: 760px;
  height: 400px;
  overflow: auto;
  // margin-left: 90px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.choise-ad {
  width: 350px;
  height: 150px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
  .choise-account {
    height: 50px;
    line-height: 50px;
  }
  .choise-detail {
    margin-top: 6px;
  }
  p {
    line-height: 24px;
  }
}
</style>
