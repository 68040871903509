<template>
  <div class="add-account small-plan-part">
    <div class="title flex-row-between-center">
      <span>账号</span>
      <span class="edit" @click="editItem">添加</span>
    </div>
    <div class="container">
      <p
        class="account-item flex-row-between-center"
        v-for="(item, index) in accountList"
        :key="item.id"
      >
        <span>{{ item.advertiserName }}</span>
        <i
          class="el-icon-close"
          @click="handleDeleteAccount(item.advertiserId)"
        ></i>
      </p>
    </div>
    <!-- 账号选择器 -->
    <select-account
      @cancel="showAccount = false"
      @confirm="handleSelected"
      :showAccount="showAccount"
      :seleted="accountList"
    />
  </div>
</template>

<script>
import selectAccount from "../components/selectAccount";
export default {
  data() {
    return {
      page: 0,
      search: "",
      showAccount: false,
      options: [],
      accountList: [],
      tmpData: [],
    };
  },
  inject: ["templateDetial"],
  created() {
    this.tmpData = this.templateDetial();
  },
  watch: {},
  methods: {
    handleUpdateData(type) {
      this.tmpData = this.templateDetial();
      if (type === "clear") {
        return (this.accountList = []);
      }
      this.accountList = this.tmpData.channels.map((item) => ({ ...item }));
    },
    initData() {},
    // 添加账号
    handleSelected(item) {
      // this.options = [];
      // 修改 channels广告组 urls转化目标  creativeCards推广卡片
      let creativeCards = this.tmpData.creativeCards;
      let urls = this.tmpData.urls;
      let tmpArr1 = this.accountList.map((item) => item.advertiserId);
      let tmpArr2 = item.map((item) => item.advertiserId);
      let campaigns = this.tmpData.campaigns;
      item.forEach((item1, index1) => {
        // 如果原账号列表无则新增
        if (!tmpArr1.includes(item1.advertiserId)) {
          this.accountList.push({
            ...item1,
          });
          creativeCards.push({
            advertiserId: item1.advertiserId,
            advertiserName: item1.advertiserName,
            componentId: null,
            webUrl: null,
          });
          urls.push({
            advertiserId: item1.advertiserId,
            advertiserName: item1.advertiserName,
            appName: null,
            applyName: null,
            convertId: null,
            convertName: null,
            url: null,
          });
          if (campaigns) {
            campaigns.push({
              advertiserId: item1.advertiserId,
              advertiserName: item1.advertiserName,
              campaignId: null,
              campaignName: null,
            });
          }
        }
      });
      let deleteArr = [];
      this.accountList.forEach((item2, index2) => {
        // 如果勾选数据中无原列表数据则删除
        if (!tmpArr2.includes(item2.advertiserId)) {
          deleteArr.push(item2.advertiserId);
        }
      });
      this.tmpData.channels = this.accountList;
      deleteArr.forEach((item) => {
        this.handleDeleteAccount(item);
      });
      this.showAccount = false;
    },
    // 删除账号
    handleDeleteAccount(id) {
      let index = this.accountList.findIndex((item) => {
        return item.advertiserId == id;
      });
      if (index === -1) {
        return;
      }
      // this.options.splice(index, 1);
      let creativeCards = this.tmpData.creativeCards;
      let urls = this.tmpData.urls;
      let campaigns = this.tmpData.campaigns;
      creativeCards.splice(index, 1);
      urls.splice(index, 1);
      this.accountList.splice(index, 1);
      if (campaigns && campaigns[index]) {
        campaigns.splice(index, 1);
      }
      this.tmpData.channels = this.accountList;
    },
    editItem() {
      if (this.tmpData && this.tmpData.channels) {
        this.accountList = this.tmpData.channels;
        // this.options = this.tmpData.channels;
      } else {
        this.tmpData.channels = [];
        this.accountList = this.tmpData.channels;
      }
      if (!this.tmpData.creativeCards) {
        this.tmpData.creativeCards = [];
      }
      if (!this.tmpData.urls) {
        this.tmpData.urls = [];
      }
      this.showAccount = true;
    },
  },
  components: {
    selectAccount,
  },
};
</script>
<style lang='scss' scoped>
.el-icon-close {
  font-weight: bold;
  color: #777;
}
.add-account {
  .container {
    .account-item {
      border-radius: 4px;
      background: #e9ecf1;
      font-size: 13px;
      padding: 8px;
      margin: 10px;
      transition: all 0.2s;
      &:hover {
        color: #00bf8a;
      }
    }
  }
}
</style>
