<template>
  <div>
    <div class="cards-box">
      <div class="cards-filter">
        <el-select
          style="width: 150px; margin-right: 10px"
          @change="handleSelectChange"
          v-model="siteType"
        >
          <el-option label="橙子建站" :value="1"> </el-option>
          <el-option label="第三方建站" :value="2"> </el-option>
          <el-option label="程序化落地页" :value="3"> </el-option>
        </el-select>
        <el-input
          style="width: 250px; margin-right: 10px"
          placeholder="请输入站点id或站点名"
          v-model="siteSearch"
          clearable
          class="input-with-select"
        >
          <el-button
            @click="handleSitePageChange"
            slot="append"
            icon="el-icon-search"
          ></el-button>
        </el-input>
        <el-date-picker
          style="width: 300px; margin-right: 10px"
          v-model="siteTime"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
        <el-button type="primary" size="mini" @click="handleSitePageChange">
          搜索
        </el-button>
      </div>
      <div class="cards-container" v-loading="siteLoading">
        <p style="width: 100%; text-align: center" v-if="siteList.length === 0">
          暂无数据,请前往添加
        </p>
        <div
          class="cards-item"
          @click="handleChangeChecked(index, 'site')"
          :class="{ itemchecked: item.checked }"
          v-for="(item, index) in siteList"
        >
          <div class="item-header" @click.stop>
            <el-checkbox v-model="siteList[index].checked"></el-checkbox>
          </div>
          <div class="item-body" v-if="siteType != 3">
            <img :src="item.thumbnail" alt="" />
            <p class="status">{{ item.name }}</p>
          </div>
          <div class="item-body item-pro" v-else>
            <div
              class="pro-item"
              v-for="(item2, index2) in item.innerDate"
              :key="index2"
            >
              <div
                class="pro-title"
                @click="handleProClick(item.innerDate, index2)"
              >
                <span class="id">{{ item2.site_id }}</span>
                <el-popover placement="right" width="650" trigger="click">
                  <div class="pro-overview" v-if="item2.isCheck">
                    <iframe
                      width="600px"
                      height="800px"
                      :src="item2.site_url"
                      frameborder="0"
                    ></iframe>
                  </div>
                  <span slot="reference" @click="item" class="overview"
                    >预览</span
                  >
                </el-popover>
              </div>
            </div>
          </div>
          <div class="item-footer">
            <p class="id">{{ item.siteId }}</p>
            <p class="url">{{ item.url }}</p>
          </div>
        </div>
      </div>
      <div class="cars-page">
        <el-pagination
          style="margin-top: 10px; text-align: right"
          background
          layout="total, prev, pager, next, jumper"
          @current-change="handleSitePageChange"
          :current-page="sitePage"
          :page-size="sitePageSize"
          :total="siteTotal"
        >
        </el-pagination>
      </div>
    </div>
    <div class="detail-footer">
      <div>
        <el-button @click="$emit('close')">取消</el-button>
        <el-button type="primary" @click="handleSendSite">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getToolSite,
  getAppManagement,
  getProceduralSite,
} from "@/api/toutiao";
import { formatTime } from "@/assets/js/utils";
export default {
  props: {
    currenAccountId: Number,
  },
  data() {
    return {
      sitePage: 1,
      sitePageSize: 15,
      siteTotal: 0,
      siteSearch: null,
      siteList: [],
      siteLoading: false,
      siteType: 1,
      siteTime: [],
      selectedUrl: null,
    };
  },
  methods: {
    handleProClick(list, index) {
      list.forEach((item, idx) => {
        if (index == idx) {
          item.isCheck = true;
        } else {
          item.isCheck = false;
        }
      });
      this.$forceUpdate();
    },
    handleSelectChange(val) {
      if (val === 3) {
        this.handleSitePageChange();
      }
    },
    handleChangeChecked(index1, type) {
      this.siteList.forEach((item, index2) => {
        if (index1 === index2) {
          if (this.siteList[index2].checked) {
            this.$set(this.siteList[index2], "checked", false);
          } else {
            this.$set(this.siteList[index2], "checked", true);
          }
        } else {
          this.$set(this.siteList[index2], "checked", false);
        }
      });
    },
    handleSendSite() {
      this.siteList.forEach((item) => {
        if (item.checked) {
          this.selectedUrl = item.url;
          this.$emit("selectedSuccess", this.selectedUrl);
          return;
        }
      });
    },
    handleSitePageChange(page) {
      if (typeof page == "number") {
        this.sitePage = page;
      }
      // 橙子建站
      if (this.siteType === 1) {
        this.siteLoading = true;
        getToolSite({
          page: this.sitePage,
          pageSize: this.sitePageSize,
          advertiserId: this.currenAccountId,
          search: this.siteSearch,
        })
          .then((res) => {
            this.siteList = res.list;
            this.siteTotal = res.page_info.total_number;
          })
          .finally(() => {
            this.siteLoading = false;
          });
        // 第三方建站
      } else if (this.siteType === 2) {
        if (!this.siteTime.length) {
          return this.$message.error("请选择时间段");
        }
        this.siteLoading = true;
        getAppManagement({
          page: this.sitePage,
          pageSize: this.sitePageSize,
          advertiserId: this.currenAccountId,
          search: this.siteSearch,
          startDate: this.siteTime[0],
          endDate: this.siteTime[1],
        })
          .then((res) => {
            this.siteList = res.list.map((item) => {
              return {
                name: item.app_name,
                siteId: item.app_cloud_id,
                url: item.download_url,
                thumbnail: item.icon_url,
              };
            });
            this.siteTotal = res.page_info.total_number;
          })
          .finally(() => {
            this.siteLoading = false;
          });
        //程序化落地页
      } else if (this.siteType === 3) {
        getProceduralSite({
          page: this.sitePage,
          pageSize: this.sitePageSize,
          advertiserId: this.currenAccountId,
        })
          .then((res) => {
            this.siteList = res.list.map((item) => {
              return {
                siteId: item.group_title,
                url: item.group_url,
                innerDate: item.sites,
              };
            });
            this.siteTotal = res.total_number;
          })
          .finally(() => {
            this.siteLoading = false;
          });
      }
    },
  },
  created() {
    // 默认今年一月一号到今日
    let toYear = new Date().getFullYear() + "-01-01";
    let today = formatTime(new Date(), "yyyy-MM-dd");
    this.siteTime = [toYear, today];
    this.handleSitePageChange();
  },
};
</script>

<style lang="scss" scoped>
.cards-box {
  .cards-filter {
    padding: 0 20px;
  }
  .cards-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: 560px;
    overflow-y: auto;
    padding: 0 20px;
    .cards-item {
      width: 250px;
      padding: 15px;
      height: 450px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 10px;
      margin-right: 20px;
      .item-header {
        margin-left: auto;
        height: 20px;
      }
      .item-body {
        width: 200px;
        height: 370px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        p {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          z-index: 2;
          color: #fff;
          height: 28px;
          line-height: 28px;
          font-size: 14px;
          text-align: center;
          background-color: #2f88ff;
        }
      }
      .item-pro {
        .pro-item {
          .pro-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            line-height: 30px;
            cursor: pointer;
            .id-checked {
              color: #00bf8a;
            }
          }
        }
      }
      .item-footer {
        width: 100%;
        padding: 10px;
        p {
          height: 24px;
          line-height: 24px;
        }
        .url {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 12px;
        }
        .name {
          font-size: 14px;
          font-weight: bold;
          color: #000;
        }
        .id {
          color: #666;
          font-size: 12px;
        }
      }
    }
    .itemchecked {
      border: 1px solid #00bf8a;
    }
  }
}
.detail-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
</style>