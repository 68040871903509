<template>
  <el-drawer
    append-to-body
    title="选择资产"
    :visible="showAssets"
    size="800px"
    :destroy-on-close="true"
    :before-close="handleClose"
  >
    <div class="link-container">
      <dy-table
        :height="'700'"
        :dataList="dataList"
        :rowList="rowList"
        :showPagination="true"
        :page="page"
        :pageSize="pageSize"
        :total="total"
      >
        <template #filter>
          <el-select
            style="width: 200px; margin-left: 10px"
            v-model="assetType"
            placeholder="请选择"
            clearable
            @change="getList"
          >
            <el-option label="三方落地页" :value="'THIRD_EXTERNAL'"></el-option>
            <el-option label="应用" :value="'APP'"></el-option>
            <el-option label="快应用" :value="'QUICK_APP'"></el-option>
          </el-select>
        </template>
        <template #operation="{ row }">
          <el-button
            class="button-small"
            size="small"
            type="primary"
            @click="handleApply(row)"
          >应用
          </el-button
          >
        </template>
      </dy-table>
    </div>
  </el-drawer>

</template>
<script>
import {
  assetsGet,
} from "@/api/toutiao";

export default {
  props: {
    currenAccountId: Number,
    showAssets: {
      type: Boolean,
      default: false
    },
    showExternalAction: {
      type: Boolean,
      default: false
    },
    landingType: String
  },
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      assetType: 'QUICK_APP',
      rowList: [
        {
          label: "资产ID",
          prop: "asset_id",
        },
        {
          label: "快应用名称",
          prop: "asset_name",
        },
        {
          label: "快应用包名",
          prop: "package_name",
        },
        {
          label: "操作",
          action: "operation",
        },
      ],
      dataList: [],
    }
  },
  mounted() {
  },
  watch: {
    showAssets(newVal) {
      if (newVal) {
        this.getList();
      }
    },
    showExternalAction(newVal) {
      if (newVal) {
        this.getList();
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
      this.assetType = 'QUICK_APP';
      this.dataList = [];
    },
    getList() {
      assetsGet({
        page: this.page,
        pageSize: this.pageSize,
        advertiserId: this.currenAccountId,
        assetType: this.assetType
      }).then((res) => {
        this.total = res.page_info.total_number
        let array;
        if (this.landingType === 'QUICK_APP') {
          array = res.quick_app;
        } else if (this.landingType === 'APP') {
          array = res.app;
        } else {
          array = res.landing_pages;
        }
        this.dataList = array;
      })
    },
    handleApply(row) {
      this.$emit("selectedAssets", row);
      this.$emit("close");
    }
  },
}
</script>
