<template>
  <div class="resoure-choice" id="resoureChoice">
    <div
      class="detail border-radius-6 box-shadow-light-grey height-calc-type1"
      style="background: #fff"
      id="detailBox"
    >
      <div class="nav-filter">
        <div class="filter-list">
          <div class="advantage-filter">
            <!-- <span>高级筛选</span> -->
            <div class="flex-row-start-start-wrap" style="flex-grow: 1">
              <el-input
                v-model="params.title"
                placeholder="素材标题/文案"
                clearable
                class="margin-right-ten"
                style="width: 300px"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="searchData"
                ></el-button>
              </el-input>
              <!-- 只针对视频素材 -->
              <el-select
                v-if="params && params.materialType === 1"
                v-model="params.duration"
                placeholder="时长"
                class="margin-right-ten"
                style="width: 180px"
                clearable
                filterable
                @change="changeDuration"
              >
                <el-option
                  v-for="item in durationOptions"
                  :key="JSON.stringify(item.value)"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-date-picker
                class="margin-right-ten"
                v-model="params.createStartTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="上传开始"
                @change="handleTimeChange('start')"
              >
              </el-date-picker>
              <el-date-picker
                class="margin-right-ten"
                v-model="params.createEndTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="上传结束"
                @change="handleTimeChange('end')"
              >
              </el-date-picker>
              <el-select
                v-model="params.adminUserId"
                class="margin-right-ten"
                placeholder="发布人"
                style="width: 180px"
                clearable
                filterable
                @change="searchData"
              >
                <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-button
                type="primary"
                class="margin-right-ten"
                @click="handleSelect"
              >
                提交选中素材
              </el-button>
            </div>
          </div>
          <div class="tags-filter" v-loading="loading">
            <span>标签筛选</span>
            <div class="tag-list" @mouseleave.stop="handleContainerMouseLeave">
              <ul class="underline-navbar">
                <li
                  v-for="(item, index) in tagsList"
                  :key="index"
                  @mouseenter="handleMouseEnter($event, index, item)"
                  @click.stop="
                    ($event) => {
                      handleTagGroupClick(item, $event, index);
                    }
                  "
                >
                  <span>{{ item.tagGroupName }}</span>
                  <div
                    :ref="`navItemList`"
                    class="subtag-list"
                    @mouseleave.stop="handleMouseLeave($event, index)"
                  >
                    <c-input
                      class="search-input"
                      :ref="`cInputList`"
                      v-model="search"
                      placeholder="请输入标签名称!"
                      style="width: 200px; margin-bottom: 10px"
                    ></c-input>
                    <ul class="subtag-ul">
                      <template
                        v-if="
                          Array.isArray(item.tagItems) &&
                          item.tagItems.length > 0
                        "
                      >
                        <li
                          v-for="(tag, index2) in getTargetList(item)"
                          :data-index="index"
                          :key="index2"
                          @click.stop="
                            ($event) => {
                              handleTagClick(tag, item, $event);
                            }
                          "
                        >
                          {{ tag.name }}
                        </li>
                      </template>
                      <template v-else>
                        <custom-icon tipsText="暂无标签项"></custom-icon>
                      </template>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div
              v-if="tagsList.length === 0"
              style="margin-top: 5px; color: #ccc"
            >
              暂无标签组
            </div>
          </div>
          <div
            v-if="getChooseTags().length > 0"
            class="flex-row-start-start-wrap choose-tags"
          >
            <el-button
              type="warning"
              class="button-small"
              size="small"
              @click="delFilterTagAll"
              >清除所有</el-button
            >
            <div>
              所选{{ isTagGroup ? "项" : "" }}：
              <span
                v-for="(item, index) in getChooseTags()"
                style="margin-right: 10px"
                :key="index"
              >
                <span style="color: #00bf8a">{{ item.name }}</span>
                <i
                  class="el-icon-error"
                  style="
                    font-size: 14px;
                    color: #fd5d5a;
                    cursor: pointer;
                    padding-left: 2px;
                  "
                  @click="delFilterTag(index, item)"
                ></i>
              </span>
            </div>
          </div>
          <div
            class="hover-part"
            ref="hoverPart"
            @mouseleave="handleMouseLeave"
          ></div>
        </div>
      </div>
      <!-- 列表主体 -->
      <div class="main-container">
        <div class="library-container">
          <div class="library-header">
            <div>共{{ total }}条数据</div>
          </div>
          <div class="library-container-list" v-infinite-scroll="handleTest">
            <library-item
              v-for="(item, index) in dataList"
              :key="index"
              :info="item"
              :selection="selection"
              :editable="false"
              @handleItemClick="handleItemClick(item, index)"
              @addClick="addClick"
            >
            </library-item>
          </div>
        </div>
      </div>
    </div>
    <el-backtop
      class="testbacktop"
      target="#resoureChoice"
      :visibility-height="500"
    ></el-backtop>
  </div>
</template>

<script>
import {
  getMaterialWareList,
  getMaterialDetail,
  getTagGroupList,
  delMaterialWare,
  getTagItemList,
  batchLinkTags
} from '@/api/material'
import { mapGetters } from 'vuex'
import LibraryItem from './libraryItem'
export default {
  props: {
    multipleable: Boolean,
    resourceType: Number,
    tabData: Object
  },
  data () {
    return {
      params: {
        materialType: 1
      },
      page: 1,
      pageSize: 14,
      total: 0,
      canContionalLoading: true,
      loading: false,
      debounceFlag: false,
      searchAddTagName: null,
      selection: [],
      tagSelection: [],
      startTime: null,
      endTime: null,
      tagItemIds: [], // 标签项ids
      groupTagIds: [], //  标签组ids
      canPushTagList: false,
      tagPageSize: 1, // 针对标签组设置的分页值
      showMoreTag: false,
      resetTagFlag: false,
      firstInitData: true,
      tagsList: [],
      dataList: [],
      tagName: [],
      targetGroupIds: [],
      durationOptions: [
        {
          label: '10秒以内',
          value: 1
        },
        {
          label: '10秒 ~ 30秒',
          value: 2
        },
        {
          label: '30秒 ~ 1分钟',
          value: 3
        },
        {
          label: '1分钟 ~ 2分钟',
          value: 4
        },
        {
          label: '2分钟以上',
          value: 5
        }
      ],
      isTagGroup: false, // 是否点击了标签组进行筛选
      targetTagIds: [],
      videoSelection: [],
      curInfo: {},
      isShowPreview: false,
      mySelection: [],
      getListFlag: false,
      imageList: [],
      search: '',
      booksNameIds: [], // 书籍名称标签
      putChannelIds: [], // 投放渠道标签
      imageTypeIds: [], // 图片类型标签
      videoTypeIds: [], // 视频类型标签
      booksTypeIds: [], // 书籍类型标签
      info: [],
      curTagInfo: {},
      copyTagsList: [], // !
      mappingIds: {
        2: {
          en: 'booksTypeIds',
          cn: '书籍类型'
        },
        3: {
          en: 'videoTypeIds',
          cn: '视频类型'
        },
        4: {
          en: 'imageTypeIds',
          cn: '图片类型'
        },
        5: {
          en: 'putChannelIds',
          cn: '投放渠道'
        },
        6: {
          en: 'booksNameIds',
          cn: '书籍名称'
        }
      }
    }
  },
  methods: {
    handleSelect () {
      const tmpList = this.tabData.list
      if (tmpList.length + this.mySelection.length > 10) {
        return this.$message.error(
          '素材总个数不能超过10个，你最多还可选择' +
            (10 - tmpList.length) +
            '个素材'
        )
      }
      // return;
      this.$emit('selectSuccess', this.mySelection)
    },
    // 筛选条件相关
    handleContainerMouseLeave () {
      if (this.$refs.navItemList) {
        this.$refs.hoverPart.style.display = 'none'
        this.$refs.navItemList.forEach((item, i) => {
          item.style.display = 'none'
        })
      }
    },
    searchData () {
      this.page = 1
      this.canContionalLoading = true
      this.debounceFlag = false
      this.clearSelection()
      this.initData()
    },
    clearSelection () {
      this.searchAddTagName = ''
      this.selection = []
      this.tagSelection = []
    },
    changeDuration (val) {
      if (val) {
        const obj = {
          1: {
            startTime: '00:00:00',
            endTime: '00:00:10'
          },
          2: {
            startTime: '00:00:10',
            endTime: '00:00:30'
          },
          3: {
            startTime: '00:00:30',
            endTime: '00:01:00'
          },
          4: {
            startTime: '00:01:00',
            endTime: '00:02:00'
          },
          5: {
            startTime: '00:02:00',
            endTime: '23:59:59'
          }
        }
        this.startTime = obj[val].startTime
        this.endTime = obj[val].endTime
      } else {
        this.startTime = null
        this.endTime = null
      }
      this.initData()
    },
    initData (flag = true) {
      getMaterialWareList(this.page, this.pageSize, this.takeParams()).then(
        (res) => {
          this.canPushTagList = false
          if (flag) {
            this.getTagGroup()
          }
          this.dataList = res.list
          this.total = res.total
          this.debounceFlag = false
        }
      )
    },
    getTagGroup (flag = true) {
      getTagGroupList({
        page: this.tagPageSize,
        pageSize: 10,
        status: 1
      }).then((tagGroup) => {
        if (tagGroup.list.length < 10) {
          this.showMoreTag = true
          this.resetTagFlag = true
        } else {
          this.resetTagFlag = false
          this.showMoreTag = false
        }
        const list = tagGroup.list
        if (this.firstInitData) {
          this.tagsList = list
          this.copyTagsList = JSON.parse(JSON.stringify(list)).map((item) => {
            return {
              ...item,
              allItemLen: item.tagItems.length, // ! 标签组下标签项的长度
              checkedTagList: [], // ! 标签组下被选中的标签项信息
              isChecked: false // !标签组是否被选中
            }
          })
          this.firstInitData = false
        }
      })
    },
    handleTimeChange () {
      this.searchData()
    },
    delFilterTag (index, value) {
      const { isGroup, groupIndex } = value
      if (isGroup) {
        // todo=>
        this.$set(this.copyTagsList, groupIndex, {
          ...this.copyTagsList[groupIndex],
          checkedTagList: [],
          isChecked: false
        })
      } else {
        const { checkedTagList } = this.copyTagsList[groupIndex]
        const targetIndex = checkedTagList.findIndex(
          (item) => item.id === value.id
        )
        checkedTagList.splice(targetIndex, 1)
        this.$set(this.copyTagsList, groupIndex, {
          ...this.copyTagsList[groupIndex],
          checkedTagList,
          isChecked: false
        })
      }
      this.dataList = []
      this.canContionalLoading = true
      this.debounceFlag = false
      this.page = 1
      this.initData(false)
    },
    delFilterTagAll () {
      this.page = 1
      this.dataList = []
      this.tagName = []
      this.tagItemIds = []
      this.groupTagIds = []
      this.booksNameIds = []
      this.imageTypeIds = []
      this.videoTypeIds = []
      this.booksTypeIds = []
      this.targetTagIds = []
      this.putChannelIds = []
      this.targetGroupIds = []
      this.debounceFlag = false
      this.canContionalLoading = true
      this.copyTagsList.forEach((item, index) => {
        this.$set(this.copyTagsList, index, {
          ...this.copyTagsList[index],
          isChecked: false,
          checkedTagList: []
        })
      })
      this.initData()
    },
    handleMouseEnter (e, index) {
      if (!this.$refs.navItemList[index]) {
        return this.handleMouseLeave()
      }
      this.$refs.hoverPart.style.display = 'block'
      this.$refs.navItemList.forEach((item, i) => {
        if (i === index) {
          item.style.display = 'block'
        } else {
          item.style.display = 'none'
        }
      })
      this.search = ''
    },
    handleMouseLeave () {
      this.$refs.hoverPart.style.display = 'none'
      this.$refs.navItemList.forEach((item, i) => {
        item.style.display = 'none'
      })
    },
    handleTagGroupClick (val, e, index) {
      const target = e.target
      if (
        target.nodeName.toLowerCase() === 'input' ||
        target.classList.contains('subtag-list') ||
        target.classList.contains('subtag-ul') ||
        target.classList.contains('el-input__icon')
      ) {
        return
      }
      if (this.copyTagsList[index].isChecked) { return this.$message.warning('请勿重复选择标签组!') }
      // const { id, tagItems } = val
      this.$set(this.copyTagsList, index, {
        ...this.copyTagsList[index],
        checkedTagList: [...this.copyTagsList[index].tagItems],
        isChecked: true
      })
      this.debounceFlag = false
      this.canContionalLoading = true
      this.page = 0
      this.getList(false)
    },
    getList (flag = true) {
      return new Promise((resolve) => {
        if (this.debounceFlag) {
          return resolve()
        }
        if (!this.canContionalLoading) return resolve()
        this.debounceFlag = true
        this.page++
        getMaterialWareList(this.page, this.pageSize, this.takeParams())
          .then((res) => {
            if (flag) {
              this.dataList = [...this.dataList, ...res.list]
            } else {
              this.dataList = res.list
            }
            this.total = res.total
            this.debounceFlag = false
            if (res.list.length < this.pageSize) {
              this.canContionalLoading = false
            }
            return resolve()
          })
          .finally(() => {
            return resolve()
          })
      })
    },
    handleTagClick (tag) {
      // !点击了标签项
      const groupItem = this.copyTagsList.find(
        (item) => item.id === tag.tagGroupId
      )
      const groupIndex = this.copyTagsList.findIndex(
        (item) => item.id === tag.tagGroupId
      )
      if (groupItem.isChecked) { return this.$message.warning('已勾选该标签的标签组!') }
      // !
      if (groupItem.checkedTagList.find((item) => item.id === tag.id)) { return this.$message.warning('请勿重复勾选标签项!') }
      groupItem.checkedTagList = [...groupItem.checkedTagList, tag]
      if (groupItem.checkedTagList.length === groupItem.allItemLen) { groupItem.isChecked = true }
      this.$set(this.copyTagsList, groupIndex, { ...groupItem })

      this.debounceFlag = false
      this.canContionalLoading = true
      this.dataList = []
      this.page = 0
      this.getList(false)
    },
    checkMore () {
      this.canPushTagList = true
      if (this.resetTagFlag) {
        this.resetTagFlag = false
        this.tagPageSize = 1
        this.tagsList = this.tagsList.slice(0, 10)
        this.showMoreTag = false
      } else {
        this.tagPageSize++
        this.getTagGroup()
      }
    },
    changeType (type = 1) {
      this.resetData()
      this.$set(this.params, 'materialType', type)
      this.clearSelection()
      this.initData()
    },
    resetData () {
      this.clearSelection()
      this.page = 1
      this.params = {}
      this.tagName = []
      this.curInfo = {}
      this.dataList = []
      this.endTime = null
      this.tagPageSize = 1
      this.tagItemIds = []
      this.groupTagIds = []
      this.startTime = null
      this.targetTagIds = []
      this.isTagGroup = false
      this.targetGroupIds = []
      this.debounceFlag = false
      this.canContionalLoading = true
    },
    handleItemClick (val) {
      getMaterialDetail(val.id).then((res) => {
        this.curInfo = res
        this.isShowPreview = true
      })
    },
    // addClick(value) {
    addClick ({ id, isSelection, imgUrl, videoSrc, imgTitle }) {
      if (isSelection) {
        this.selection.push(id)
        if (videoSrc) {
          this.mySelection.push({
            materialId: id,
            type: 2,
            url: videoSrc.src,
            title: videoSrc.title
          })
          this.videoSelection.push(videoSrc)
        } else {
          this.mySelection.push({
            materialId: id,
            type: 1,
            url: imgUrl,
            title: imgTitle.substring(0, imgTitle.lastIndexOf('.'))
          })
        }
      } else {
        const targetIndex = this.selection.findIndex((item) => item === id)
        const myTargetIndex = this.mySelection.findIndex(
          (item) => item.materialId === id
        )
        this.selection.splice(targetIndex, 1)
        this.mySelection.splice(myTargetIndex, 1)
        if (videoSrc) {
          const videoIndex = this.videoSelection.findIndex(
            (item) => item.src === videoSrc.src
          )
          this.videoSelection.splice(videoIndex, 1)
        }
      }
      // 修改视频或图片直接返回选择数据
      if (!this.multipleable) {
        return this.$emit('selectSuccess', this.mySelection)
      }
    },
    handleTest () {
      this.getList()
    },
    getTargetList (item) {
      return this.search
        ? item.tagItems.filter((tag) => tag.name.includes(this.search))
        : item.tagItems
    },
    getChooseTags () {
      let res = []
      res = this.copyTagsList.reduce((acc, b, c) => {
        if (b.checkedTagList.length === b.allItemLen) {
          acc.push({
            id: b.id,
            name: b.tagGroupName,
            isGroup: true,
            groupIndex: c
          })
        } else {
          const { checkedTagList } = b
          const arr = []
          if (checkedTagList.length > 0) {
            checkedTagList.forEach((item, index) => {
              arr.push({
                id: item.id,
                isGroup: false,
                groupIndex: c,
                name: `${this.mappingIds[item.tagGroupId].cn}-${item.name}`
              })
            })
            acc.push(...arr)
          }
        }
        return acc
      }, [])
      return res
    },
    takeParams () {
      const params = {
        materialTypes: this.params.materialType
          ? [2, 3].includes(this.params.materialType)
            ? [2, 3]
            : [1]
          : [1],
        content: this.params.content,
        title: this.params.title,
        videoTime: this.params.videoTime,
        adminUserId: this.params.adminUserId,
        startTime: this.startTime,
        endTime: this.endTime,
        createStartTime: this.params.createStartTime,
        createEndTime: this.params.createEndTime
      }
      this.copyTagsList.forEach((item, index) => {
        const { checkedTagList } = item
        if (checkedTagList.length > 0) {
          params[this.mappingIds[6 - index].en] = item.checkedTagList.map(
            (item) => item.id
          )
        }
      })
      return params
    }
  },
  components: {
    LibraryItem
  },
  computed: {
    ...mapGetters(['userList'])
  },
  mounted () {
    this.initData()
    // this.$nextTick(() => {
    //   this.initEvent();
    // });
  },
  created () {
    if (this.resourceType === 1) {
      this.params.materialType = 2
    } else {
      this.params.materialType = 1
    }
  }
}
</script>

<style lang='scss' scoped>
.resoure-choice {
  height: 850px;
  overflow-y: auto;
  .upload-files {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 14px;
    > span {
      margin-right: auto;
      font-size: 20px;
      font-weight: bold;
    }
    > div {
      margin-left: auto;
    }
  }
  .detail {
    padding: 0 20px 20px 20px;
    .nav-filter {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      border-bottom: 1px solid #e9f0f7;
      position: sticky;
      background: #fff;
      z-index: 999;
      top: -15px;
      padding-top: 20px;
      .filter-list {
        padding-bottom: 20px;
        width: 100%;
        .advantage-filter {
          color: #475669;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          > span {
            padding-right: 42px;
          }
        }
        .tags-filter {
          margin-top: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          > span {
            padding-right: 42px;
            margin-top: 5px;
          }
          .underline-navbar {
            display: flex;
            flex-wrap: wrap;
            max-width: 1300px;
            li {
              position: relative;
              padding: 9px 22px;
              cursor: pointer;
              font-size: 14px;
              margin-right: 10px;
              margin-bottom: 12px;
              color: #b7b7b7;
              background: #f1f1f1;
              transition: all 300ms;
              border-radius: 3px;
              &:hover {
                background: #00bf8a;
                color: #fff;
              }
              &::before {
                position: absolute;
                left: 100%;
                top: 0;
                border-bottom: 2px solid transparent;
                width: 0;
                height: 100%;
                content: "";
                transition: all 300ms;
              }
              &:active {
                color: #fff;
              }
              .subtag-list {
                position: absolute;
                display: none;
                top: 140%;
                left: 0;
                width: 500px;
                background: #f1f4f6;
                padding: 15px;
                border-radius: 6px;
                z-index: 99;
                .subtag-ul {
                  max-height: 525px;
                  overflow: auto;
                  li {
                    margin-bottom: 10px;
                    display: inline-block;
                    min-width: 70px;
                    text-align: center;
                    background: #fff;
                    border: 1px solid #b7b7b7;
                    color: #b7b7b7;
                    &:hover {
                      color: #00bf8a;
                      border-color: #00bf8a;
                    }
                  }
                }
                &::before {
                  content: "";
                  position: absolute;
                  background: transparent;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-color: transparent transparent #f1f4f6 transparent;
                  border-width: 0 20px 20px 20px;
                  top: -8px;
                  left: 10px;
                }
              }
            }
          }
        }
        .choose-tags {
          > div {
            line-height: 26px;
            font-size: 14px;
            margin-left: 24px;
            width: calc(100% - 150px);
          }
        }
      }
    }
    .main-container {
      width: 100%;
      margin-top: 30px;
      .nav-type {
        font-size: 18px;
        font-weight: bold;
        position: relative;
        > span {
          position: relative;
          cursor: pointer;
          margin-right: 58px;
          transition: all 0.2s;
          .active-line {
            position: absolute;
            display: inline-block;
            bottom: -10px;
            left: 0;
            width: 100%;
            height: 4px;
            border-radius: 4px;
            background-color: #00bf8a;
          }
          &:hover {
            color: #00bf8a;
          }
        }
        .tips {
          position: absolute;
          top: 50%;
          left: 90%;
          transform: translateY(-50%);
          color: #ccc;
          font-size: 14px;
          font-weight: normal;
        }
        .active-text {
          color: #00bf8a;
        }
      }
      .library-container {
        margin-top: 30px;

        .library-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          > div:nth-of-type(1) {
            font-weight: bold;
          }
          .filter-types {
          }
        }
        .library-container-list {
          display: flex;
          margin-top: 10px;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          > div {
            margin-right: 20px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
.tags-list {
  margin-top: 20px;
  max-height: 200px;
  overflow: auto;
  .tag-item {
    margin: 0 10px 20px 0;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      color: #00bf8a;
      border-color: #00bf8a;
    }
  }
  .is-active {
    color: #fff !important;
    background-color: #00bf8a;
    border-color: #00bf8a;
  }
}
.file-download-status {
  position: fixed;
  z-index: 9999;
  overflow: auto;
  top: 100px;
  left: 75%;
  background: #fff;
  width: 300px;
  ul {
    list-style: none;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 460px;
    min-height: 120px;
    li {
      position: relative;
      padding: 15px 4px;
      padding-left: 65px;
      padding-right: 18px;
      // height: 60px;
      border-bottom: 1px solid rgb(223, 223, 223);
      .item-icon {
        position: absolute;
        left: 0;
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #fff;
      }
      .item-info {
        color: #303942;
        font-size: 12px;
        > span {
          display: block;
          height: 20px;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: default;
        }
        .item-url {
          color: #1e1eaf;
        }
      }
    }
  }
}
</style>
