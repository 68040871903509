<template>
  <div class="plan-base-info small-plan-part">
    <div class="title flex-row-between-center">
      <span>计划基本信息</span>
      <span class="edit" @click="editItem">编辑</span>
    </div>
    <div class="container">
      <p v-for="(item, index) in echoData" :key="index">
        <span class="item-title">{{ item.title }}：</span>
        <span class="item-result">{{ item.result }}</span>
      </p>
    </div>
    <el-drawer
      title="编辑计划"
      :append-to-body="true"
      size="1200px"
      :visible.sync="showPlan"
    >
      <div class="transfor-box">
        <div class="plan-dialog-container transform-container">
          <!-- 改用创意包名 -->
          <!-- <div class="plan-part">
            <h3>计划名称</h3>
            <div class="plan-name flex-row-start-center">
              <span class="required">计划名称</span>
              <div>
                <el-input
                  style="width: 400px"
                  v-model="params.name"
                  placeholder="计划名称"
                  show-word-limit
                  maxlength="47"
                ></el-input>
              </div>
            </div>
          </div> -->
          <div class="plan-part">
            <h3>投放范围</h3>
            <div class="flex-row-start-start">
              <span class="required" style="margin-top: 10px">投放范围</span>
              <div>
                <!-- <span class="simulation-checkbox">默认</span> -->
                <el-radio-group v-model="params.deliveryRange">
                  <el-radio-button label="DEFAULT">默 认</el-radio-button>
                  <el-radio-button label="UNION">穿山甲</el-radio-button>
                  <el-radio-button label="UNIVERSAL">通投智选</el-radio-button>
                </el-radio-group>
                <!-- <p style="margin-top: 10px; color: #ccc">
                  投放范围为通投智选时，仅支持激活，白名单激活-广告变现ROI、激活-次留、付费ROI、ROI自动化、每次付费、预约下载为转化目标
                </p> -->
              </div>
            </div>
            <div class="flex-row-start-start">
              <span class="required" style="margin-top: 10px">广告位大类</span>
              <div>
                <el-radio-group v-model="params.inventoryCatalog">
                  <el-radio-button label="MANUAL">首选媒体</el-radio-button>
                  <el-radio-button label="SCENE">场景广告位</el-radio-button>
                  <el-radio-button label="SMART">优选广告位</el-radio-button>
                  <el-radio-button label="UNIVERSAL">通投智选</el-radio-button>
                </el-radio-group>
                <el-checkbox-group
                  style="margin-top: 10px"
                  v-if="params.inventoryCatalog === 'MANUAL'"
                  v-model="tmpInventoryType"
                >
                  <el-checkbox
                    v-for="(item, index) in inventoryCatalogList"
                    :key="index"
                    :label="item.value"
                  >{{ item.name }}
                  </el-checkbox
                  >
                </el-checkbox-group>
                <!-- 优选广告位时 -->
                <div
                  style="margin-top: 10px"
                  v-if="params.inventoryCatalog === 'SMART'"
                >
                  <span style="margin-right: 6px; font-size: 14px"
                  >使用优选</span
                  >
                  <el-radio v-model="params.smartInventory" label="SMART"
                  >是
                  </el-radio
                  >
                  <el-radio v-model="params.smartInventory" label="NORMAL"
                  >否
                  </el-radio
                  >
                </div>
              </div>
            </div>
            <div
              class="flex-row-start-start"
              v-if="params.deliveryRange === 'UNION'"
            >
              <span class="required" style="margin-top: 10px">投放形式</span>
              <div>
                <el-radio-group v-model="params.unionVideoType">
                  <el-radio-button label="ORIGINAL_VIDEO"
                  >原生视频
                  </el-radio-button
                  >
                  <el-radio-button label="REWARDED_VIDEO"
                  >激励视频
                  </el-radio-button
                  >
                  <el-radio-button label="SPLASH_VIDEO"
                  >穿山甲开屏
                  </el-radio-button
                  >
                </el-radio-group>
              </div>
            </div>
            <!-- 场景广告位 -->
            <div
              class="flex-row-start-start"
              v-if="params.inventoryCatalog === 'SCENE'"
            >
              <span class="required" style="margin-top: 10px">场景广告位</span>
              <div>
                <el-radio-group v-model="params.sceneInventory">
                  <el-radio-button label="VIDEO_SCENE"
                  >沉浸式竖版视频首选
                  </el-radio-button
                  >
                  <el-radio-button label="FEED_SCENE"
                  >信息流首选场景
                  </el-radio-button
                  >
                  <el-radio-button label="TAIL_SCENE"
                  >视频后贴和尾帧首选
                  </el-radio-button
                  >
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="plan-part">
            <h3>投放目标</h3>
            <div class="flex-row-start-center">
              <span class="required">推广目的</span>
              <el-select
                v-model="params.landingType"
                disabled
                @change="handletest"
                placeholder="选择推广目的"
                filterable
              >
                <el-option
                  v-for="item in landingTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <!-- 下载方式 -->
            <div class="flex-row-start-center">
              <span class="required">下载方式</span>
              <el-radio-group v-model="params.downloadType">
                <el-radio-button
                  :disabled="params.landingType === 'LINK'"
                  label="DOWNLOAD_URL"
                >下载链接
                </el-radio-button
                >
                <el-radio-button
                  :disabled="params.landingType === 'QUICK_APP'"
                  label="EXTERNAL_URL"
                >落地页链接
                </el-radio-button
                >
              </el-radio-group>
            </div>
            <!-- 应用类型 -->
            <!-- v-if="params.downloadType === 'DOWNLOAD_URL'" -->
            <div
              class="flex-row-start-center"
              v-if="params.landingType !== 'LINK'"
            >
              <span class="required">应用类型</span>
              <el-radio-group v-model="params.appType">
                <el-radio-button label="APP_ANDROID">Android</el-radio-button>
              </el-radio-group>
            </div>
            <!-- 多账号分别填写 -->
            <div
              class="flex-row-start-start"
              v-if="params.downloadType === 'DOWNLOAD_URL'"
            >
              <span class="required" style="margin-top: 10px">下载链接</span>
              <div>
                <el-button
                  type="text"
                  @click="handleaddLink(1)"
                  style="padding: 9px 0 !important"
                >多账号分别填写
                </el-button
                >
                <p style="margin-top: 5px; margin-bottom: 10px">
                  <el-checkbox v-model="priorityDownload"
                  >优先从应用商店下载（请确保应用在应用商店上线）
                  </el-checkbox
                  >
                </p>
                <div v-if="accountList.every((item) => !!item.url)">
                  <p class="per-p" v-for="item in accountList" :key="item.id">
                    <span>{{ item.advertiserName }}：{{ item.url }}</span>
                  </p>
                </div>
              </div>
            </div>
            <!-- 落地页链接 -->
            <div
              v-if="params.downloadType === 'EXTERNAL_URL'"
              class="flex-row-start-start"
            >
              <span class="required">落地页链接</span>
              <div>
                <el-button
                  type="text"
                  style="padding: 0 !important"
                  @click="handleaddLink(2)"
                >多账号分别填写
                </el-button
                >
                <div
                  style="margin-top: 5px"
                  v-if="accountList.every((item) => !!item.url)"
                >
                  <p class="per-p" v-for="item in accountList" :key="item.id">
                    <span>{{ item.advertiserName }}：{{ item.url }}</span>
                  </p>
                </div>
              </div>
            </div>
            <!-- 直达链接 -->
            <div
              class="flex-row-start-center"
              v-if="params.landingType === 'QUICK_APP'"
            >
              <span class="required">直达链接</span>
              <div>
                <c-input
                  v-model="params.openUrl"
                  style="width: 400px"
                ></c-input>
              </div>
            </div>
          </div>
          <div class="plan-part">
            <h3>预算与出价</h3>
            <div class="flex-row-start-center">
              <span class="required">投放场景</span>
              <el-radio-group
                v-model="params.smartBidType"
                @change="handleSmartBidTypeChange"
              >
                <el-radio-button label="SMART_BID_CUSTOM"
                >常规投放
                </el-radio-button
                >
                <el-radio-button label="SMART_BID_CONSERVATIVE"
                >放量投放
                </el-radio-button
                >
              </el-radio-group>
              <!-- <p style="margin-left: 20px; color: #ccc">
                <span v-if="params.smartBidType === 'SMART_BID_CUSTOM'"
                  >控制成本，尽量消耗完预算</span
                >
                <span v-else>接收成本上浮，尽量消耗更多预算</span>
              </p> -->
            </div>
            <template v-if="params.smartBidType === 'SMART_BID_CUSTOM'">
              <div class="flex-row-start-center">
                <span class="required">竞价策略</span>
                <el-radio-group v-model="params.flowControlMode">
                  <el-radio-button label="FLOW_CONTROL_MODE_FAST"
                  >优先跑量
                  </el-radio-button
                  >
                </el-radio-group>
              </div>
              <div class="flex-row-start-start">
                <span class="required" style="margin-top: 10px">预算</span>
                <div>
                  <el-select
                    style="width: 100px"
                    class="margin-right-ten"
                    v-model="params.budgetMode"
                    placeholder="预算"
                    filterable
                  >
                    <el-option
                      label="日预算"
                      value="BUDGET_MODE_DAY"
                    ></el-option>
                    <el-option
                      label="总预算"
                      value="BUDGET_MODE_TOTAL"
                    ></el-option>
                  </el-select>
                  <el-input
                    style="width: 370px"
                    v-model="params.budget"
                    placeholder="请输入预算，不少于300元，不超过9999999.99元"
                  ></el-input>
                  元
                  <p
                    style="margin-top: 5px; color: #fd5d5a"
                    v-if="checkShowOrNot"
                  >
                    请输入预算，不少于300元，不超过9999999.99元
                  </p>
                </div>
              </div>
              <div class="flex-row-start-start">
                <span class="required" style="margin-top: 10px">投放时间</span>
                <div>
                  <el-radio-group v-model="params.scheduleType">
                    <el-radio-button label="SCHEDULE_FROM_NOW"
                    >从今天起长期投放
                    </el-radio-button
                    >
                    <el-radio-button label="SCHEDULE_START_END"
                    >设置开始和结束日期
                    </el-radio-button
                    >
                  </el-radio-group>
                  <p
                    style="margin-top: 20px"
                    v-if="params.scheduleType === 'SCHEDULE_START_END'"
                  >
                    <el-date-picker
                      v-model="params.daterange"
                      type="datetimerange"
                      format="yyyy-MM-dd HH:mm"
                      value-format="yyyy-MM-dd HH:mm"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                    >
                    </el-date-picker>
                  </p>
                </div>
              </div>
              <div class="flex-row-start-start">
                <span class="required" style="margin-top: 10px">投放时段</span>
                <div>
                  <el-radio-group v-model="params.scheduleTimeTmp">
                    <el-radio-button :label="1">不限</el-radio-button>
                    <el-radio-button :label="2">指定时间段</el-radio-button>
                  </el-radio-group>
                  <!-- <span class="simulation-checkbox">不限</span>
              <span class="normal-checkbox">指定时间段</span> -->
                  <div class="time-range" v-if="params.scheduleTimeTmp === 2">
                    <custom-schedule
                      :inputData="tmpscheduleTime"
                      @returnData="handleGetScheduleTime"
                    />
                  </div>
                </div>
              </div>
              <div class="flex-row-start-center">
                <span class="required">付费方式</span>
                <el-radio-group v-model="params.pricing">
                  <el-radio-button label="PRICING_OCPM"
                  >按展示付费(oCPM)
                  </el-radio-button
                  >
                </el-radio-group>
              </div>
              <div class="flex-row-start-start">
                <span class="required" style="margin-top: 10px">出价</span>
                <div style="width: 80%">
                  <!-- 原转化出价改为出价范围 -->
                  <!-- <el-input
                    v-model="params.cpaBid"
                    placeholder="取值范围：0.1-10000元"
                    style="width: 100px"
                  >
                  </el-input> -->
                  <el-input
                    v-model="params.bidMin"
                    placeholder="0.1-10000"
                    style="width: 100px"
                  >
                  </el-input>
                  <span>-</span>
                  <el-input
                    v-model="params.bidMax"
                    placeholder="0.1-10000"
                    style="width: 100px"
                  >
                  </el-input>
                  <span style="line-height: 32px; padding-left: 5px">元</span>
                  <p
                    style="margin-top: 5px; color: #fd5d5a"
                    v-if="checkShowOrNot2"
                  >
                    取值范围：0.1-10000元
                  </p>
                </div>
              </div>
            </template>

            <template v-else>
              <div class="flex-row-start-start">
                <span class="required" style="margin-top: 10px">预算</span>
                <div>
                  <el-select
                    style="width: 100px"
                    class="margin-right-ten"
                    v-model="params.budgetMode"
                    placeholder="预算"
                    filterable
                  >
                    <el-option
                      label="日预算"
                      value="BUDGET_MODE_DAY"
                    ></el-option>
                    <el-option
                      label="总预算"
                      value="BUDGET_MODE_TOTAL"
                    ></el-option>
                  </el-select>
                  <el-input
                    style="width: 370px"
                    v-model="params.budget"
                    placeholder="请输入预算，不少于300元，不超过9999999.99元"
                  ></el-input>
                  元
                  <p
                    style="margin-top: 5px; color: #fd5d5a"
                    v-if="checkShowOrNot"
                  >
                    请输入预算，不少于300元，不超过9999999.99元
                  </p>
                </div>
              </div>
              <!-- <div class="flex-row-start-start">
                <span class="required" style="margin-top: 10px">日预算</span>
                <div>
                  <el-input
                    style="width: 300px"
                    v-model="params.budget"
                    placeholder="不少于300元"
                  ></el-input>
                  元
                </div>
              </div> -->
              <div class="flex-row-start-start">
                <span></span>
                <div>
                  <!-- false-label="FLOW_CONTROL_MODE_BALANCE" -->
                  <p style="margin-top: 5px">
                    <el-checkbox
                      v-model="params.flowControlMode"
                      true-label="FLOW_CONTROL_MODE_SMOOTH"
                      false-label="FLOW_CONTROL_MODE_FAST"
                    >通过学习期后，尝试优化转化成本
                    </el-checkbox
                    >
                  </p>
                </div>
              </div>
              <div class="flex-row-start-start">
                <span class="required" style="margin-top: 10px">投放时间</span>
                <div>
                  <el-radio-group v-model="params.scheduleType">
                    <el-radio-button label="SCHEDULE_FROM_NOW"
                    >从今天起长期投放
                    </el-radio-button
                    >
                    <el-radio-button label="SCHEDULE_START_END"
                    >设置开始和结束日期
                    </el-radio-button
                    >
                  </el-radio-group>
                  <p
                    style="margin-top: 20px"
                    v-if="params.scheduleType === 'SCHEDULE_START_END'"
                  >
                    <el-date-picker
                      v-model="params.daterange"
                      type="datetimerange"
                      format="yyyy-MM-dd HH:mm"
                      value-format="yyyy-MM-dd HH:mm"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                    >
                    </el-date-picker>
                  </p>
                </div>
              </div>
              <div class="flex-row-start-start">
                <span class="required" style="margin-top: 10px">投放时段</span>
                <div>
                  <el-radio-group v-model="params.scheduleTimeTmp">
                    <el-radio-button :label="1">不限</el-radio-button>
                    <el-radio-button :label="2">指定时间段</el-radio-button>
                  </el-radio-group>
                  <div class="time-range" v-if="params.scheduleTimeTmp === 2">
                    <custom-schedule @returnData="handleGetScheduleTime"/>
                  </div>
                </div>
              </div>
              <div class="flex-row-start-start">
                <span></span>
                <p>
                  <el-checkbox
                    v-model="params.adjustCpa"
                    :true-label="1"
                    :false-label="0"
                  >我的预期成本不在此范围内，我需要在此基础上调整
                  </el-checkbox
                  >
                </p>
              </div>
              <div class="flex-row-start-start" v-if="params.adjustCpa">
                <span
                  :class="{ required: params.adjustCpa === '1' }"
                  style="margin-top: 10px"
                >出价</span
                >
                <div style="width: 80%">
                  <el-input
                    v-model="params.bidMin"
                    placeholder="0.1-10000"
                    style="width: 100px"
                  >
                  </el-input>
                  <span>-</span>
                  <el-input
                    v-model="params.bidMax"
                    placeholder="0.1-10000"
                    style="width: 100px"
                  >
                  </el-input>
                  <span style="line-height: 32px; padding-left: 5px">元</span>
                  <p
                    style="margin-top: 5px; color: #fd5d5a"
                    v-if="checkShowOrNot2"
                  >
                    取值范围：0.1-10000元
                  </p>
                </div>
              </div>
            </template>
          </div>
        </div>
        <!-- footer -->
        <div class="button-list change-target-footer">
          <el-button type="primary" @click="handleSubmit">确定</el-button>
          <el-button @click="showPlan = false">取消</el-button>
        </div>
      </div>
      <!-- 多账号添加 -->
      <el-drawer
        append-to-body
        :visible.sync="showAccount"
        size="900px"
        @close="handleCloseAccount"
      >
        <account-addLink
          v-if="showAccount"
          :showD="showAccount"
          :accountList="accountList"
          :accountUrl="accountUrl"
          :type="params.downloadType"
          :priorityDownload="priorityDownload"
          :landingType="params.landingType"
          :eventsUrl="eventsUrl"
          :marketingPurpose="params.marketingPurpose"
          @closeD="handleCloseAccount"
          @selectedAccountList="handleMultipleAccounts"
        />
      </el-drawer>
    </el-drawer>
  </div>
</template>

<script>
import customSchedule from "@/components/custom/custom-schedule";
import accountAddLink from "../components/accountAddLink.vue";
// import { getConvertLine, getAppManagement } from "@/api/toutiao";
import {mapMutations} from "vuex";

export default {
  data() {
    return {
      tmpInventoryType: [],
      showPlan: false,
      checkbox1: [],
      name: "",
      budgetValue: "",
      value2: "",
      daterange: null,
      timevalue: null,
      showTransformDialog: false,
      showAccount: false,
      tmpData: {
        channels: [],
      },
      priorityDownload: false,
      bidType: 1,
      params: {
        deliveryRange: "DEFAULT",
        objective: "1",
        downloadType: "DOWNLOAD_URL", // 下载方式
        appType: "APP_ANDROID",
        smartBidType: "SMART_BID_CUSTOM", // 投放场景
        flowControlMode: "FLOW_CONTROL_MODE_FAST", // 竞价策略
        scheduleType: "SCHEDULE_FROM_NOW", // 投放时间类型
        scheduleTimeTmp: 1, //投放时段
        landingType: "APP",
        marketingPurpose: "CONVERSION",
        scheduleTime: 1,
        budgetMode: "BUDGET_MODE_DAY",
        pricing: "PRICING_OCPM", // 付费方式
        cpaBid: "", // 目标转化出价
        adjustCpa: 0, //自动出价
        bidMin: "", //出价改为出价范围
        bidMax: "",
        openUrl: "", // 直达链接
        smartInventory: "NORMAL", //使用优选广告
      },
      accountList: [],
      landingTypeList: [
        {
          label: "销售线索收集",
          value: "LINK",
        },
        {
          label: " 应用推广",
          value: "APP",
        },
        {
          label: "快应用",
          value: "QUICK_APP",
        },
        // {
        //   label: '商品目录推广',
        //   value: 'DPA'
        // },
        // {
        //   label: '商品推广（鲁班）',
        //   value: 'GOODS'
        // },
        // {
        //   label: '门店推广',
        //   value: 'STORE'
        // },
        // {
        //   label: '抖音号推广',
        //   value: 'AWEME'
        // },
        // {
        //   label: '电商店铺推广',
        //   value: 'SHOP'
        // },
        // {
        //   label: '头条文章推广',
        //   value: 'ARTICAL'
        // }
      ],
      echoData: [],
      inventoryCatalogList: [
        {
          name: "头条",
          value: "INVENTORY_FEED",
        },
        {
          name: "西瓜视频",
          value: "INVENTORY_VIDEO_FEED",
        },
        {
          name: "火山短视频",
          value: "INVENTORY_HOTSOON_FEED",
        },
        {
          name: "抖音短视频",
          value: "INVENTORY_AWEME_FEED",
        },
        {
          name: "番茄小说",
          value: "INVENTORY_TOMATO_NOVEL",
        },
        {
          name: "穿山甲",
          value: "INVENTORY_UNION_SLOT",
        },
      ],
      tmpscheduleTime: [],
      eventsUrl: [],
    };
  },
  inject: ["templateDetial", "updateAllTemplate"],
  computed: {
    checkShowOrNot() {
      const flag =
        (this.showPlan &&
          this.budgetValue &&
          typeof +this.budgetValue === "number" &&
          !isNaN(+this.budgetValue) &&
          +this.budgetValue < 300) ||
        +this.budgetValue > 9999999.99;
      return flag;
    },
    checkShowOrNot2() {
      return (
        (this.showPlan &&
          this.value2 &&
          typeof +this.value2 === "number" &&
          !isNaN(+this.value2) &&
          +this.value2 < 0.1) ||
        +this.value2 > 10000
      );
    },
  },
  methods: {
    // 切换投放场景时变化竞价策略
    handleSmartBidTypeChange(value) {
      if (value === "SMART_BID_CONSERVATIVE") {
        this.params.flowControlMode = "FLOW_CONTROL_MODE_SMOOTH";
      } else {
        this.params.flowControlMode = "FLOW_CONTROL_MODE_FAST";
      }
    },
    handleGetScheduleTime(data) {
      this.params.scheduleTime = data;
    },
    handleUpdateData(type) {
      this.tmpData = this.templateDetial();
      if (type === "clear") {
        this.params = {
          deliveryRange: "DEFAULT",
          objective: "1",
          downloadType: "DOWNLOAD_URL", // 下载方式
          appType: "APP_ANDROID",
          landingType: "APP", //推广目的
          smartBidType: "SMART_BID_CUSTOM", // 投放场景
          flowControlMode: "FLOW_CONTROL_MODE_FAST", // 竞价策略
          scheduleType: "SCHEDULE_FROM_NOW", // 投放时间类型
          scheduleTimeTmp: 1, //投放时段
          scheduleTime: [],
          budgetMode: "BUDGET_MODE_DAY",
          pricing: "PRICING_OCPM", // 付费方式
          cpaBid: "", // 目标转化出价
          adjustCpa: 0, //自动出价
          bidMin: "", //出价改为出价范围
          bidMax: "",
          openUrl: "", // 直达链接
          smartInventory: "NORMAL", //使用优选广告
        };
        this.eventsUrl = [];
        return this.handleEchoData("clear");
      }
      // 处理
      if (
        !this.tmpData.planRule ||
        !this.tmpData.planTarget ||
        !this.tmpData.planRange ||
        !this.tmpData.planBid ||
        !this.tmpData.urls
      ) {
        return;
      }
      if (this.params.landingType === 'QUICK_APP') {
        // 处理
        if (this.eventsUrl.length === 0) {
          return;
        }
        this.eventsUrl = this.tmpData.events;
      }
      this.params = {
        ...this.tmpData.planTarget,

        ...this.tmpData.planRange,

        ...this.tmpData.planBid,

        ...this.tmpData.urls,

        ...this.tmpData.planRule,
        landingType: this.tmpData.adRule.landingType,
        marketingPurpose: this.tmpData.adRule.marketingPurpose,
      };
      // 还原出价方式
      if (
        this.tmpData.planBid &&
        (this.tmpData.planBid.adjustCpa === "" ||
          !this.tmpData.planBid.adjustCpa)
      ) {
        this.$set(this.params, "adjustCpa", 0);
        // this.params.adjustCpa = 0;
      }
      this.params.appType = "APP_ANDROID";
      // 回显投放时间
      this.$set(this.params, "daterange", [
        this.tmpData.planBid.startTime || "",
        this.tmpData.planBid.endTime || "",
      ]);
      if (this.params.landingType === "LINK") {
        this.params.downloadType = "EXTERNAL_URL";
      }
      // 还原投放时段
      if (!this.params.scheduleTime) {
        this.$set(this.params, "scheduleTimeTmp", 1); //若不存在则默认不限
      } else {
        if (this.params.scheduleTime.includes("0")) {
          this.$set(this.params, "scheduleTimeTmp", 2);
          this.tmpscheduleTime = this.params.scheduleTime.split("");
        } else {
          this.$set(this.params, "scheduleTimeTmp", 1);
        }
      }
      // 还原优先从商店下载
      if (this.params.downloadMode === "DEFAULT") {
        this.priorityDownload = false;
      } else {
        this.priorityDownload = true;
      }
      // 还原
      // 还原媒体
      this.tmpInventoryType = this.tmpData.planRange.inventoryType
        ? this.tmpData.planRange.inventoryType.split(",")
        : [];
      this.handleEchoData();
    },
    handletest(item) {
      if (item === "LINK") {
        this.params.downloadType = "EXTERNAL_URL";
      }
      if (item === "QUICK_APP") {
        this.params.downloadType = "DOWNLOAD_URL";
      }
    },
    handleEchoData(type) {
      this.echoData = [];
      if (type === "clear") {
        return;
      }
      // this.echoData.push({
      //   title: "计划名称",
      //   result: this.params.name,
      // });
      // 投放范围
      this.echoData.push({
        title: "投放范围",
        result:
          this.params.deliveryRange === "DEFAULT"
            ? "默认"
            : this.params.deliveryRange === "UNION"
              ? "穿山甲"
              : "通投智选",
      });
      this.echoData.push({
        title: "广告位大类",
        result:
          this.params.inventoryCatalog === "MANUAL"
            ? "首选媒体"
            : this.params.inventoryCatalog === "SCENE"
              ? "场景广告位"
              : this.params.inventoryCatalog === "SMART"
                ? "优选广告位"
                : "通投智选",
      });
      if (this.params.sceneInventory) {
        this.echoData.push({
          title: "首选场景广告位",
          result:
            this.params.inventoryCatalog === "VIDEO_SCENE"
              ? "沉浸式竖版视频首选"
              : this.params.deliveryRange === "FEED_SCENE"
                ? "信息流首选场景"
                : "视频后贴和尾帧首选",
        });
      }
      if (this.params.inventoryCatalog === "MANUAL") {
        let tmpArr = this.tmpInventoryType.map((item) => {
          switch (item) {
            case "INVENTORY_FEED":
              return "头条";
            case "INVENTORY_VIDEO_FEED":
              return "西瓜视频";
            case "INVENTORY_HOTSOON_FEED":
              return "火山短视频";
            case "INVENTORY_AWEME_FEED":
              return "抖音短视频";
            case "INVENTORY_TOMATO_NOVEL":
              return "番茄小说";
            case "INVENTORY_UNION_SLOT":
              return "穿山甲";
          }
        });
        this.echoData.push({
          title: "广告投放位置",
          result: tmpArr.join(","),
        });
      }
      if (this.params.inventoryCatalog === "SMART") {
        this.echoData.push({
          title: "使用优选",
          result: this.params.smartInventory === "SMART" ? "是" : "否",
        });
      }
      // 投放目标
      this.echoData.push({
        title: "推广目的",
        result:
          this.params.landingType === "LINK"
            ? "销售线索收集"
            : this.params.landingType === "APP"
              ? "应用推广"
              : "快应用",
      });
      // 不为快应用时
      if (this.params.landingType !== "QUICK_APP") {
        this.echoData.push({
          title: "下载方式",
          result:
            this.params.downloadType === "DOWNLOAD_URL"
              ? "应用下载"
              : "落地页链接",
        });
        let tmpStrr = "";
        let tmparr = this.tmpData.urls.map((item) => item.url);
        tmpStrr = tmparr.join(",");
        if (this.params.downloadType === "DOWNLOAD_URL") {
          this.echoData.push({
            title: "下载链接",
            result: tmpStrr,
          });
        } else {
          this.echoData.push({
            title: "落地页链接",
            result: tmpStrr,
          });
        }
        this.priorityDownload
          ? this.echoData.push({
            title: "优先从应用商店下载",
            result: "是",
          })
          : "";
      } else {
        this.echoData.push({
          title: "直达链接",
          result: this.params.openUrl,
        });
        this.echoData.push({
          title: "优先从商店下载",
          result: this.priorityDownload ? "是" : "否",
        });
      }

      // 预算出价
      this.echoData.push({
        title: "投放场景",
        result:
          this.params.smartBidType === "SMART_BID_CUSTOM"
            ? "常规投放"
            : "放量投放",
      });
      this.echoData.push({
        title: "预算方式",
        result:
          this.params.budgetMode === "BUDGET_MODE_DAY" ? "日预算" : "总预算",
      });
      this.echoData.push({
        title: "预算",
        result: this.params.budget,
      });
      this.echoData.push({
        title: "投放时段",
        result: this.params.scheduleTimeTmp === 1 ? "不限" : "指定时间段",
      });
      if (this.params.deliveryRange === "UNION") {
        this.echoData.push({
          title: "投放形式",
          result:
            this.params.unionVideoType === "ORIGINAL_VIDEO"
              ? "原生视频"
              : this.params.unionVideoType === "REWARDED_VIDEO"
                ? "激励视频"
                : "穿山甲开屏",
        });
      }

      this.params.scheduleType === "SCHEDULE_FROM_NOW"
        ? this.echoData.push({
          title: "投放时间",
          result: "今日开始",
        })
        : this.echoData.push({
          title: "投放时间",
          result: this.params.daterange[0] + "-" + this.params.daterange[1],
        });
      this.params.smartBidType === "SMART_BID_CUSTOM"
        ? this.echoData.push({
          title: "付费方式",
          result: "按展示付费",
        })
        : "";
      // 放量投放并且我的预算成本||常规投放展示出价
      if (
        (this.params.smartBidType === "SMART_BID_CONSERVATIVE" &&
          this.params.adjustCpa === 1) ||
        this.params.smartBidType === "SMART_BID_CUSTOM"
      ) {
        this.echoData.push({
          title: "出价",
          result: this.params.bidMin + "-" + this.params.bidMax,
        });
      }
    },
    editItem() {
      // 多账号
      this.accountList = this.tmpData.channels || [];
      this.accountUrl = this.tmpData.urls || [];
      this.eventsUrl = this.tmpData.events || [];
      if (!this.accountList.length || !this.accountUrl.length) {
        return this.$message.error("请先完善账号模块");
      }
      if (!this.tmpData.planBid) {
        this.tmpData.planBid = {};
      }
      // 设置推广目的
      this.params.landingType = this.tmpData.adRule.landingType;

      if (this.params.landingType === "LINK") {
        this.params.downloadType = "EXTERNAL_URL";
      }
      if (this.params.landingType === "QUICK_APP") {
        this.params.downloadType = "DOWNLOAD_URL";
      }
      this.showPlan = true;
    },
    handleCloseAccount() {
      this.showAccount = false;
    },
    handleaddLink(type) {
      if (!this.accountList.length) {
        return this.$message.error("请先在账号模块添加账号");
      }
      if (!this.params.landingType) {
        return this.$message.error("请先选择推广目的");
      }
      this.showAccount = true;
    },
    handleSubmit() {
      if (!this.params.landingType) {
        return this.$message.error("请选择推广目的");
      }
      // 投放目标相关=======
      // 广告位为场景广告位时必选首选场景广告位
      if (
        this.params.inventoryCatalog === "SCENE" &&
        !this.params.sceneInventory
      ) {
        return this.$message.error("请选择首选场景广告位");
      }
      if (this.params.inventoryCatalog !== "SCENE") {
        this.params.sceneInventory = null;
      }
      if (
        !this.params.downloadType ||
        (this.params.landingType === "QUICK_APP" &&
          this.params.downloadType === "EXTERNAL_URL ")
      ) {
        return this.$message.error("请选择下载方式");
      }
      if (this.params.landingType === "QUICK_APP" && !this.params.openUrl) {
        return this.$message.error("请填入直达链接");
      }
      // 多账号判定 推广目的为线索搜集不需要包名和应用名
      if (this.params.landingType === "LINK") {
        // 链接和计划同存
        let AccountFlag = this.accountUrl.some((item) => {
          if (!item.url || !item.convertId) {
            return true;
          } else {
            return false;
          }
        });
        if (AccountFlag) {
          return this.$message.error("请完善多账号填写");
        }
      } else {
        // 链接和计划、包名、应用名同存
        let AccountFlag = this.accountUrl.some((item, index) => {
          if (this.params.landingType === "QUICK_APP") {
            if (
              !item.webUrl ||
              !this.eventsUrl[index].externalAction ||
              !this.eventsUrl[index].url ||
              !item.appName ||
              !item.applyName
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            if (
              !item.url ||
              !item.convertId ||
              !item.appName ||
              !item.applyName
            ) {
              return true;
            } else {
              return false;
            }
          }
        });
        if (AccountFlag) {
          return this.$message.error("请完善多账号填写");
        }
      }
      // 投放范围相关=======
      if (!this.params.inventoryCatalog) {
        return this.$message.error("请选择广告位大类");
      }
      // 媒体首选
      if (this.params.inventoryCatalog === "MANUAL") {
        if (!this.tmpInventoryType.length) {
          return this.$message.error("首选媒体请勾选广告位投放平台");
        }
      }
      // 穿山甲必填投放形式
      if (this.params.deliveryRange === "UNION") {
        if (!this.params.unionVideoType) {
          return this.$message.error("请选择投放形式");
        }
        if (
          //  如果广告位为首选媒体时必须勾选穿山甲
          this.params.inventoryCatalog === "MANUAL" &&
          !this.tmpInventoryType.includes("INVENTORY_UNION_SLOT")
        ) {
          return this.$message.error("投放穿山甲广告只能且必须勾选穿山甲");
        }
        if (
          this.params.inventoryCatalog === "MANUAL" &&
          this.tmpInventoryType.length > 1
        ) {
          return this.$message.error("投放穿山甲广告只能且必须勾选穿山甲");
        }
      }
      // 使用优选
      if (this.params.inventoryCatalog !== "SMART") {
        this.params.smartInventory = null;
      }
      // 预算与出价相关=======
      // 日预算
      if (
        !this.params.budget ||
        this.params.budget < 300 ||
        this.params.budget > 9999999.99
      ) {
        return this.$message.error("请填写正确范围内的预算");
      }
      if (!this.params.scheduleTimeTmp) {
        return this.$message.error("请选择投放时段");
      }
      if (this.params.scheduleTimeTmp === 1) {
        this.params.scheduleTime = new Array(48 * 7).fill(1).join("");
      }
      // 投放时间
      if (this.params.scheduleType === "SCHEDULE_START_END") {
        if (
          !this.params.daterange ||
          this.params.daterange.length !== 2 ||
          this.params.daterange.includes("")
        ) {
          return this.$message.error("请设置投放时间");
        }
      }
      // 常规投放
      if (this.params.smartBidType === "SMART_BID_CUSTOM") {
        // 转化价
        if (
          !this.params.bidMin ||
          !this.params.bidMax ||
          this.params.bidMin < 0.1 ||
          this.params.bidMax < 0.1 ||
          this.params.bidMin > 10000 ||
          this.params.bidMax > 10000 ||
          this.params.bidMin > this.params.bidMax
        ) {
          return this.$message.error("请填写正确范围内的目标转化出价");
        }
      } else {
        // 放量投放
        // this.$set(this.params, "budgetMode", "BUDGET_MODE_DAY");
        if (this.params.adjustCpa) {
          // 若选择手动房价必填出价范围
          if (
            !this.params.bidMin ||
            !this.params.bidMax ||
            this.params.bidMin < 0.1 ||
            this.params.bidMax < 0.1 ||
            this.params.bidMin > 10000 ||
            this.params.bidMax > 10000 ||
            this.params.bidMin > this.params.bidMax
          ) {
            return this.$message.error("请填写正确范围内的目标转化出价");
          }
        }
      }
      // 多账号
      // 非选择应用推广并且为落地页链接 清空weburl
      if (!(this.params.downloadType === "EXTERNAL_URL" && this.params.landingType === "APP")) {
        if (this.params.landingType !== "QUICK_APP") {
          this.accountUrl.forEach((item) => {
            item.webUrl = null;
          });
        }
      }
      this.tmpData.urls = this.accountUrl;
      // 快应用事件
      if (this.params.landingType === "QUICK_APP") {
        this.tmpData.events = this.eventsUrl;
      }
      // 计划名称及状态
      this.tmpData.planRule = {
        // name: this.params.name,
        operation: this.params.operation || "enable",
      };
      // if (!this.tmpData.adRule) {
      //   this.tmpData.adRule = {};
      // }
      // 投放目标
      this.tmpData.planTarget = {
        landingType: this.params.landingType,
        appType: this.params.appType,
        downloadMode: this.priorityDownload ? "APP_STORE_DELIVERY" : "DEFAULT",
        downloadType: this.params.downloadType,
        openUrl: this.params.openUrl,
      };
      // 投放范围相关
      this.tmpData.planRange = {
        deliveryRange: this.params.deliveryRange,
        inventoryCatalog: this.params.inventoryCatalog,
        inventoryType: this.tmpInventoryType.join(","),
        sceneInventory: this.params.sceneInventory,
        unionVideoType: this.params.unionVideoType,
      };
      if (this.params.smartInventory) {
        this.$set(
          this.tmpData.planRange,
          "smartInventory",
          this.params.smartInventory
        );
      }
      // 穿山甲修改创意基本信息
      if (
        this.params.deliveryRange === "UNION" &&
        this.params.inventoryCatalog === "MANUAL" &&
        this.tmpInventoryType.includes("INVENTORY_UNION_SLOT")
      ) {
        if (this.tmpData.basisCreative) {
          this.tmpData.basisCreative.adDownloadStatus = 0;
        }
      }
      // 预算保存相关=======
      this.tmpData.planBid.budgetMode = this.params.budgetMode;
      this.tmpData.planBid.budget = this.params.budget;
      // this.tmpData.planBid.cpaBid = this.params.cpaBid;
      this.tmpData.planBid.bidMin = this.params.bidMin;
      this.tmpData.planBid.bidMax = this.params.bidMax;
      this.tmpData.planBid.flowControlMode = this.params.flowControlMode;
      // 投放场景
      this.tmpData.planBid.smartBidType = this.params.smartBidType;
      // 投放时间
      this.tmpData.planBid.scheduleType = this.params.scheduleType;
      if (this.params.scheduleType === "SCHEDULE_START_END") {
        this.tmpData.planBid.startTime = this.params.daterange[0];
        this.tmpData.planBid.endTime = this.params.daterange[1];
      }
      // 出价方式 投放场景为放量投放传值 否则传''
      if (this.params.smartBidType === "SMART_BID_CONSERVATIVE") {
        this.tmpData.planBid.adjustCpa = this.params.adjustCpa;
      } else {
        this.tmpData.planBid.adjustCpa = "";
      }
      // 投放时段
      this.tmpData.planBid.scheduleTime = this.params.scheduleTime;
      this.tmpData.planBid.pricing = this.params.pricing;
      this.updateAllTemplate();
      this.handleEchoData();
      this.showPlan = false;
    },
    handleMultipleAccounts(selectList) {
      // 修改临时数据urls
      this.accountUrl = [];
      this.eventsUrl = [];
      selectList.forEach((item) => {
        this.accountUrl.push({
          advertiserId: item.advertiserId,
          advertiserName: item.advertiserName,
          appName: item.appName,
          applyName: item.applyName,
          convertId: item.selection[0].convertId,
          convertName: item.selection[0].convert_name,
          url: this.params.landingType === 'QUICK_APP' ? null : item.url,
          webUrl: item.webUrl,
        });
        if (this.params.landingType === 'QUICK_APP') {
          this.eventsUrl.push({
            advertiserId: item.advertiserId,
            assetIds: item.assetIds,
            externalAction: item.externalAction,
            externalActionName: item.externalActionName,
            deepExternalAction: item.deepExternalAction,
            deepExternalActionName: item.deepExternalActionName,
            url: item.url,
          })
        }
      });
      this.showAccount = false;
    },
    chooseTransformTarget() {
      this.showTransformDialog = true;
    },
    handleCancel(callback) {
      if (typeof callback === "function") {
        callback();
      }
      this.showPlan = false;
    },
    ...mapMutations(["setAccountList"]),
  },
  created() {
    this.tmpData = this.templateDetial();
    this.accountList = this.tmpData.channels || [];
    this.accountUrl = this.tmpData.urls || [];
  },
  components: {
    customSchedule,
    accountAddLink,
  },
};
</script>
<style lang='scss' scoped>
/deep/ .el-range-separator {
margin-right: 10px;
}
.per-p {
margin-bottom: 10px;
}
.plan-dialog-container {
height: 700px;
padding: 10px;
overflow: auto;
.plan-part {
h3 {
font-size: 20px;
margin-bottom: 25px;
}
> div {
margin-bottom: 20px;
> span {
width: 110px;
text-align: right;
margin-right: 15px;
}
}
}
.simulation-checkbox {
border: 1px solid #32b81f !important;
color: #32b81f !important;
background-color: #f1fbf0 !important;
padding: 5px 30px;
}
.normal-checkbox {
padding: 5px 30px;
background-color: #fff;
color: #ccc;
border: 1px solid #ccc;
}
.button-list {
margin-left: 40px;
}
.choose-target {
margin-top: 10px;
> p {
padding: 4px 9px;
border-radius: 4px;
background: #f5f5f5;
border: 1px solid #ccc;
font-size: 14px;
}
}
}
.transfor-box {
display: flex;
flex-direction: column;
height: 100%;
.transform-container {
flex: 1;
max-height: 800px;
}
}
.change-target-footer {
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 10px;
}
.container {
padding: 10px;
p {
line-height: 20px;
.item-title {
color: #9a98a5;
font-weight: bold;
}
.item-result {
color: #878c8c;
}
}
}
</style>
