<template>
  <div class="multi-account-container">
    <h3 style="margin-bottom: 20px">账号列表</h3>
    <div class="link-box" style="max-height: 750px; overflow: auto">
      <div class="link-left">
        <div
          v-for="(item, index) in paramsList"
          :key="item.id"
          class="account-item"
        >
          <div class="account-name">{{ item.advertiserName }}</div>
          <div class="input-list">
            <div class="input-item">
              <span style="margin-top: 10px" class="required input-left"
              >链接：</span
              >
              <!-- :readonly="landingType !== 'LINK'" -->
              <el-input
                v-if="landingType === 'QUICK_APP'"
                v-model="item.webUrl"
                style="width: 220px"
                placeholder="请输入相关链接"
                clearable
              ></el-input>
              <el-input
                v-else
                v-model="item.url"
                style="width: 220px"
                placeholder="请输入相关链接"
                @change="handleUrlChange(item, index)"
                clearable
              ></el-input>
              <el-button
                v-if="landingType === 'LINK'"
                type="primary"
                size="mini"
                class="mini-button"
                @click="handleChoiseDownLoadDetail(item, index)"
              >
                选择已有
              </el-button>
              <el-button
                v-else-if="landingType === 'QUICK_APP'"
                type="primary"
                size="mini"
                class="mini-button"
                @click="handleChoiseDownLoadDetail(item, index, 1)"
              >
                选择已有
              </el-button>
              <el-button
                v-else
                type="primary"
                size="mini"
                class="mini-button"
                @click="hadnleChoiseLink(item, index)"
              >
                选择已有
              </el-button>
            </div>
            <!-- v-if="isApply" -->
            <template v-if="isApply">
              <div class="input-item">
                <span class="required input-left" style="margin-top: 10px"
                >应用名：</span
                >
                <el-input
                  clearable
                  style="width: 220px"
                  v-model="item.appName"
                  placeholder="应用名"
                ></el-input>
                <el-button
                  v-if="landingType === 'QUICK_APP'"
                  type="primary"
                  size="mini"
                  class="mini-button"
                  @click="handAssets(item, index, 1)"
                >
                  选择已有
                </el-button>
              </div>
              <div class="input-item">
                <span class="required input-left" style="margin-top: 10px"
                >应用包名：</span
                >
                <el-input
                  clearable
                  style="width: 220px"
                  v-model="item.applyName"
                  placeholder="应用包名"
                ></el-input>
              </div>
            </template>
            <template v-if="landingType === 'QUICK_APP'">
              <div class="input-item">
                <span class="required input-left" style="margin-top: 10px"
                >监测链接：</span>
                <el-input
                  v-model="item.url"
                  style="width: 220px"
                  placeholder="请输入监测链接"
                  clearable
                ></el-input>
              </div>
              <div class="input-item">
                <span class="required input-left" style="margin-top: 10px"
                >优化目标：</span>
                <el-input
                  :disabled="true"
                  style="width: 220px"
                  v-model="item.externalActionName"
                ></el-input>
                <el-button
                  type="primary"
                  size="mini"
                  class="mini-button"
                  @click="handAssets(item, index, 2)"
                >
                  选择已有
                </el-button>
                <el-input v-model="item.externalAction" style="display:none;"></el-input>
              </div>
              <div class="input-item">
                <span class="input-left" style="margin-top: 10px"
                >深度目标：</span>
                <el-input
                  :disabled="true"
                  style="width: 220px"
                  v-model="item.deepExternalActionName"
                ></el-input>
                <el-input style="display: none" v-model="item.deepExternalAction"></el-input>
              </div>
            </template>
            <!-- <div class="input-item">
              <span class="required input-left" style="margin-top: 5px"
                >转化目标：</span
              >
              <el-input
                v-model.number="item.tmpSelectionConvertId"
                style="width: 220px"
                type="number"
                placeholder="请输入转化目标id(仅数字)"
                clearable
                @change="handleConvertIdChange(item, index)"
              ></el-input>
              <el-button
                :disabled="tagetAble(item)"
                type="primary"
                class="mini-button"
                @click="chooseTransformTarget(item, index)"
              >
                选择已有</el-button
              >
            </div> -->
            <div class="input-item" v-if="isShowDetail">
              <span style="margin-top: 10px" class="required input-left"
              >落地页：</span
              >
              <!-- :readonly="landingType !== 'LINK'" -->
              <el-input
                readonly
                v-model="item.webUrl"
                style="width: 220px"
                placeholder="请选择链接"
                clearable
              ></el-input>
              <el-button
                type="primary"
                size="mini"
                class="mini-button"
                @click="handleChoiseDownLoadDetail(item, index, 1)"
              >
                选择已有
              </el-button>
            </div>
            <div class="input-item target-item" v-if="landingType !== 'QUICK_APP'">
              <span class="required input-left" style="margin-top: 5px"
              >转化目标：</span
              >
              <div
                class="target-group"
                v-if="item.tmpTargetList && item.tmpTargetList.length"
                style="margin-top: 5px"
              >
                <el-radio-group
                  @change="handleConvertIdChange(item, index)"
                  v-model="item.tmpSelectionConvertId"
                >
                  <el-radio
                    :key="n"
                    v-for="(m, n) in item.tmpTargetList"
                    :label="m.convert_id"
                  >{{ m.external_name }}
                  </el-radio
                  >
                </el-radio-group>
              </div>
              <div class="empty" style="margin-top: 5px" v-else>
                <p v-if="item.url" style="margin-top: -8px">
                  当前链接暂无转化，前往
                  <el-button
                    type="primary"
                    class="mini-button"
                    @click="handleAddTarget(item, index)"
                  >新建
                  </el-button
                  >
                </p>
                <p v-else="item.url">请先填写链接地址</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: left" class="multi-footer">
      <div>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
        <el-button @click="$emit('closeD')">取消</el-button>
      </div>
    </div>
    <!-- 转化目标 -->
    <el-drawer
      title="转化目标"
      append-to-body
      size="750px"
      :visible.sync="showTransformDialog"
    >
      <div class="transfor-box">
        <div class="transform-container">
          <dy-table :height="'700'" :dataList="dataList" :rowList="rowList">
            <template #operation="{ row }">
              <el-button
                class="button-small"
                size="small"
                type="primary"
                @click="handleApplyTarget(row)"
              >应用
              </el-button
              >
            </template>
          </dy-table>
        </div>
        <div class="change-target-footer">
          <div class="footer-box">
            <el-button type="primary" @click="confirm">确定</el-button>
            <el-button @click="cancel">取消</el-button>
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 新建转化目标 -->
    <el-drawer
      append-to-body
      title="新建转化目标"
      :visible.sync="showNewChange"
      size="700px"
    >
      <div class="transfor-box">
        <div class="transform-container">
          <el-form
            label-position="left"
            label-width="120px"
            class="form-container"
          >
            <el-form-item
              label="推广账号"
              style="margin-bottom: 14px"
              class="necessary"
            >
              <el-popover
                :disabled="options.length === 0"
                placement="top-start"
                width="300"
                trigger="hover"
              >
                <el-tag
                  :key="tag.id"
                  v-for="tag in options"
                  :disable-transitions="false"
                  style="margin: 6px"
                  closable
                  @close="handleRemoveTag(tag.id)"
                >
                  {{ tag.advertiserName }}
                </el-tag>
                <el-select
                  :ref="'AccountSlecter'"
                  v-model="changeParams.channelIds"
                  multiple
                  placeholder="请选择"
                  collapse-tags
                  @remove-tag="handleRemoveTag(tag)"
                  @focus="handleShowAccount()"
                  slot="reference"
                  class="account-selector"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.advertiserName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-popover>
            </el-form-item>
            <!-- 转化名称 -->
            <el-form-item
              label="转化名称"
              style="margin-bottom: 14px"
              class="necessary"
            >
              <el-input
                type="text"
                placeholder="请输入内容"
                v-model="changeParams.name"
                maxlength="25"
                show-word-limit
                style="width: 216px"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="转化来源：" class="necessary">
              <el-radio-group
                v-model="changeParams.convertSourceType"
                size="medium"
              >
                <el-radio-button label="AD_CONVERT_SOURCE_TYPE_APP_DOWNLOAD"
                >app下载
                </el-radio-button
                >
                <el-radio-button label="AD_CONVERT_SOURCE_TYPE_H5_API"
                >落地页API
                </el-radio-button
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="应用类型："
              :class="{
                necessary:
                  changeParams.convertSourceType ===
                  'AD_CONVERT_SOURCE_TYPE_APP_DOWNLOAD',
              }"
            >
              <el-radio-group v-model="changeParams.app_type" size="medium">
                <el-radio-button label="APP_ANDROID">安卓</el-radio-button>
                <el-radio-button label="APP_IOS">苹果</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="应用中文名"
              style="margin-bottom: 14px"
              :class="{
                necessary:
                  changeParams.convertSourceType ===
                  'AD_CONVERT_SOURCE_TYPE_APP_DOWNLOAD',
              }"
            >
              <el-input
                type="text"
                placeholder="下载app类型时必填"
                v-model="changeParams.appName"
                style="width: 216px"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="包名"
              style="margin-bottom: 14px"
              :class="{
                necessary:
                  changeParams.convertSourceType ===
                  'AD_CONVERT_SOURCE_TYPE_APP_DOWNLOAD',
              }"
            >
              <el-input
                type="text"
                placeholder="需与下载链接指向的应用一致"
                v-model="changeParams.package_name"
                style="width: 216px"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="点击监测链接"
              :class="{
                necessary:
                  changeParams.convertSourceType ===
                  'AD_CONVERT_SOURCE_TYPE_APP_DOWNLOAD',
              }"
              style="margin-bottom: 14px"
            >
              <el-input
                type="textarea"
                placeholder="请输入下载app类型时必填"
                v-model="changeParams.action_track_url"
                style="width: 216px"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="下载/落地页链接"
              style="margin-bottom: 14px"
              class="necessary"
            >
              <el-input
                type="textarea"
                placeholder="请输入下载/落地页链接"
                v-model="changeParams.url"
                style="width: 216px"
              >
              </el-input>
            </el-form-item>
          </el-form>
        </div>

        <div class="change-target-footer">
          <div class="footer-box">
            <el-button type="primary" @click="handleAddChange">确定</el-button>
            <el-button @click="showNewChange = false">取消</el-button>
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 账号选择器 -->
    <select-account
      @cancel="showAccount = false"
      @confirm="handleSelected"
      :showAccount="showAccount"
      :seleted="options"
    />
    <!-- 选择链接 -->
    <el-drawer
      append-to-body
      title="选择链接"
      :visible.sync="showChoiceLink"
      size="800px"
      :destroy-on-close="true"
    >
      <div class="link-container">
        <dy-table
          :height="'700'"
          :dataList="linkData"
          :rowList="linkRowList"
          :showPagination="true"
          :page="linkPage"
          :pageSize="linkPageSize"
          :total="linkTotal"
          :loading="linkLoading"
        >
          <template #filter>
            <el-input
              placeholder="请输入搜索内容"
              v-model="linkSearchKey"
              class="margin-right-ten"
              style="width: 200px"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="handleLinkPageChange"
              ></el-button>
            </el-input>
            <el-select
              style="width: 200px; margin-right: 10px"
              v-model="linkSearchType"
              placeholder="请选择"
              clearable
              @change="handleLinkPageChange"
            >
              <el-option
                v-for="item in linkSeaechTypeList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
          <template #operation="{ row }">
            <el-button
              class="button-small"
              size="small"
              type="primary"
              @click="handleApplyLink(row)"
            >应用
            </el-button
            >
          </template>
        </dy-table>
      </div>
    </el-drawer>
    <!-- 下载详情 原站点选择 -->
    <el-dialog
      title="下载详情"
      :visible.sync="showSelectSite"
      append-to-body
      width="900px"
      top="20px"
    >
      <downloadDetail
        v-if="showSelectSite"
        :currenAccountId="currenAccountId"
        @selectedSuccess="handleSendSite"
        @close="showSelectSite = false"
      />
    </el-dialog>
    <assets
      :showAssets="showAssets"
      :currenAccountId="currenAccountId"
      :landingType="landingType"
      @selectedAssets="handAssetsSet"
      @close="showAssets = false"
    />
    <external-action
      :showExternalAction="showExternalAction"
      :currenAccountId="currenAccountId"
      :landingType="landingType"
      :assetsId="assetsId"
      :marketingPurpose="marketingPurpose"
      :applyName="applyName"
      @handExternalActionSet="handExternalActionSet"
      @close="showExternalAction = false"
    />
  </div>
</template>

<script>
import downloadDetail from "../components/downloadDetail.vue";
import assets from "../components/assets.vue";
import externalAction from "../components/externalAction.vue";
import {
  addConvert,
  conversionTrackingList,
  getAppManagement,
  getOeRemark,
  actionTrackUrl
} from "@/api/toutiao.js";
import selectAccount from "../components/selectAccount";

const INTERNET_REGEXP =
  /^(((ht|f)tps?):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
export default {
  props: {
    showD: Boolean,
    accountList: {
      type: Array,
      default: () => {
        [];
      },
    },
    accountUrl: {
      type: Array,
      default: () => {
        [];
      },
    },
    priorityDownload: Boolean,
    inputTitle: {
      default: "展示",
      type: String,
    },
    type: String,
    title: {
      default: "多账号分别填写",
      type: String,
    },
    landingType: String,
    marketingPurpose: String,
    eventsUrl: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      params: {},
      dataList: [],
      selection: [],
      chooseList: [],
      showLink: false,
      currentAccount: 1,
      showTransformDialog: false,
      rowList: [
        {
          label: "转化目标ID",
          prop: "convert_id",
        },
        {
          label: "转化类型名称",
          prop: "external_action_name",
        },
        {
          label: "转化名称",
          prop: "external_name",
        },
        {
          label: "检测链接",
          prop: "action_track_url",
          popover: true,
        },
        {
          label: "操作",
          action: "operation",
        },
      ],
      paramsList: [],
      tmpData: {},
      currentEditItem: null,
      showNewChange: false,
      changeParams: {},
      options: [],
      showAccount: false,
      showChoiceLink: false,
      linkLoading: false,
      linkPage: 1,
      linkPageSize: 15,
      linkTotal: 0,
      linkSearchKey: null,
      linkSearchType: null,
      linkSeaechTypeList: [
        {
          name: "查询全部",
          value: "ALL",
        },
        {
          name: "广告主创建",
          value: "CREATE_ONLY",
        },
        {
          name: "被共享应用",
          value: "SHARED_ONLY",
        },
      ],
      linkData: [],
      linkRowList: [
        {
          label: "appid",
          prop: "app_cloud_id",
          width: 40,
        },
        {
          label: "应用名",
          prop: "app_name",
          width: 50,
        },
        {
          label: "包名",
          prop: "package_name",
          popover: true,
        },
        {
          label: "下载地址",
          prop: "download_url",
          popover: true,
        },
        {
          label: "操作",
          action: "operation",
          width: 40,
        },
      ],
      currentAccountData: null,
      currenAccountId: null,
      currentAccountIndex: null,
      tmpSelectionConvertId: null,
      showSelectSite: false,
      DetailFlag: false,
      showAssets: false,
      showExternalAction: false,
      assetsId: null,
      applyName: null,
    };
  },
  components: {
    selectAccount,
    downloadDetail,
    assets,
    externalAction,
  },
  watch: {
    showLink: {
      handler(newV) {
        if (newV) {
          this.chooseList = JSON.parse(JSON.stringify(this.accountList));
        }
      },
    },
  },
  computed: {
    // 转化按钮是否可用
    tagetAble() {
      return (item) => {
        if (this.landingType === "LINK") {
          if (!item.url) {
            return true;
          }
          return false;
        } else {
          if (!item.url || !item.appName || !item.applyName) {
            return true;
          }
          return false;
        }
      };
    },
    // 是否展示应用相关输入
    isApply: function () {
      // 销售线索收集 LINK 应用推广 APP
      if (this.landingType === "LINK") {
        return false;
      }
      //下载链接 DOWNLOAD_URL EXTERNAL_URL落地页链接
      // if (this.type === "EXTERNAL_URL") {
      //   return false;
      // }
      return true;
    },
    // 是否展示落地页下载详情选择
    isShowDetail: function () {
      // 销售线索收集 LINK 应用推广 APP
      // 选择应用推广并且为落地页链接
      if (this.landingType === "APP" && this.type === "EXTERNAL_URL") {
        return true;
      }
      return false;
    },
  },
  methods: {
    handAssets(item, index, type) {
      this.currentAccountData = item;
      this.currentAccountIndex = index;
      this.currenAccountId = item.advertiserId;
      if (type === 1) {
        this.showAssets = true;
      } else {
        this.showExternalAction = true;
        this.assetsId = item.assetIds;
        this.applyName = item.applyName;
      }
    },
    handAssetsSet(data) {
      const assetId = data.asset_id;
      this.paramsList[this.currentAccountIndex].appName = data.asset_name;
      this.paramsList[this.currentAccountIndex].applyName = data.package_name;
      this.paramsList[this.currentAccountIndex].assetIds = assetId;
      actionTrackUrl({
        advertiserId: this.currenAccountId,
        assetId: assetId
      }).then((res) => {
        if (res.track_url_groups && res.track_url_groups.length !== 0) {
          this.paramsList[this.currentAccountIndex].url = res.track_url_groups[0].action_track_url;
        }
      })
    },
    handExternalActionSet(data) {
      const externalAction = data.external_action;
      const externalActionName = externalAction === 'AD_CONVERT_TYPE_ACTIVE' ? '激活' : '激活且付费';
      this.paramsList[this.currentAccountIndex].externalAction = externalAction;
      this.paramsList[this.currentAccountIndex].externalActionName = externalActionName;
      let deepExternalAction = null;
      let deepExternalActionName = null;
      if (data.deep_goals.length !== 0) {
        deepExternalAction = data.deep_goals[0].deep_external_action;
        deepExternalActionName = deepExternalAction === 'AD_CONVERT_TYPE_ACTIVE' ? '激活' : '激活且付费';
      }
      this.paramsList[this.currentAccountIndex].deepExternalAction = deepExternalAction;
      this.paramsList[this.currentAccountIndex].deepExternalActionName = deepExternalActionName;
    },
    handleSendSite(url) {
      // 如果是落地页选择
      if (this.DetailFlag) {
        this.paramsList[this.currentAccountIndex].webUrl = url;
      } else {
        this.paramsList[this.currentAccountIndex].url = url;
        this.handleUrlChange(this.currentAccountData, this.currentAccountIndex);
      }

      this.showSelectSite = false;
    },
    // 选择橙子建站和第三方地址
    handleChoiseDownLoadDetail(item, index, type) {
      this.currentAccountData = item;
      this.currentAccountIndex = index;
      this.currenAccountId = item.advertiserId;
      this.showSelectSite = true;
      // 落地页下载详情选择
      if (type) {
        this.DetailFlag = true;
      } else {
        this.DetailFlag = false;
      }
    },
    // 推广目的为应用推广并且下载方式为落地页链接显示落地页选择

    // 多账号添加
    handleConvertIdChange(item, index) {
      this.paramsList[index].selection = [
        {
          convertId: item.tmpSelectionConvertId,
          convert_name: "",
        },
      ];
    },
    handleApplyLink(row) {
      this.paramsList[this.currentAccountIndex].url = row.download_url;
      this.paramsList[this.currentAccountIndex].applyName = row.package_name;
      this.paramsList[this.currentAccountIndex].appName = row.app_name;
      this.handleUrlChange(this.currentAccountData, this.currentAccountIndex);
      this.showChoiceLink = false;
    },
    hadnleChoiseLink(item, index) {
      if (this.landingType === "LINK") {
        return;
      }
      this.currentAccountData = item;
      this.currentAccountIndex = index;
      this.showChoiceLink = true;
      setTimeout(() => {
        this.handleLinkPageChange();
      }, 200);
    },
    handleLinkPageChange(page) {
      typeof page == "number" ? (this.page = page) : "";
      this.linkLoading = true;
      getAppManagement({
        page: this.linkPage,
        pageSize: this.linkPageSize,
        advertiserId: this.currentAccountData.advertiserId,
        searchKey: this.linkSearchKey,
        searchType: this.linkSearchType,
      })
        .then((res) => {
          this.linkData = res.list;
          this.linkTotal = res.page_info.total_number;
        })
        .finally(() => {
          this.linkLoading = false;
        });
    },
    // 新增转化相关
    handleSelected(item) {
      this.options = [];
      item.forEach((value) => {
        this.options.push(JSON.parse(JSON.stringify(value)));
      });
      this.changeParams.channelIds = item.map((value) => {
        return value.id;
      });
      this.showAccount = false;
    },
    handleShowAccount() {
      this.$refs["AccountSlecter"].blur();
      this.showAccount = true;
    },
    handleRemoveTag(item) {
      this.options.forEach((value, index) => {
        if (value.id === item) {
          this.options.splice(index, 1);
          this.changeParams.channelIds.splice(index, 1);
          return;
        }
      });
    },
    handleAddChange() {
      if (!this.changeParams.channelIds.length) {
        return this.$message.error("推广账号不能为空");
      }
      if (!this.changeParams.name) {
        return this.$message.error("转换名称不能为空");
      }
      if (!this.changeParams.convertSourceType) {
        return this.$message.error("请选择转化来源");
      }
      if (
        this.changeParams.convertSourceType !== "AD_CONVERT_SOURCE_TYPE_H5_API"
      ) {
        if (!this.changeParams.app_type) {
          return this.$message.error("请选择应用类型");
        }
        if (!this.changeParams.appName) {
          return this.$message.error("请输入应用中文名");
        }
        if (!this.changeParams.package_name) {
          return this.$message.error("请输入包名");
        }
        if (!this.changeParams.action_track_url) {
          return this.$message.error("请输入点击监测链接");
        }
      }
      if (!this.changeParams.url) {
        return this.$message.error("请输入下载/落地页链接");
      }

      addConvert({
        convert: {
          name: this.changeParams.name,
          appName: this.changeParams.appName,
          convertSourceType: this.changeParams.convertSourceType,
          appType: this.changeParams.app_type,
          url: this.changeParams.url,
          actionTrackUrl: this.changeParams.action_track_url,
          packageName: this.changeParams.package_name,
          convertType: "AD_CONVERT_TYPE_PAY",
        },
        channelIds: this.changeParams.channelIds,
        type: 2,
      }).then((res) => {
        this.$message.success("新增成功");
        this.showNewChange = false;
      });
    },
    handleClose() {
      this.$emit("closeD");
    },
    handleApplyTarget(row) {
      this.paramsList[this.currentEditItem].tmpSelectionConvertId =
        row.convert_id;
      this.paramsList[this.currentEditItem].selection = [
        {
          convertId: row.convert_id,
          convert_name: row.external_name,
        },
      ];
      this.showTransformDialog = false;
    },
    // 待删除
    chooseTransformTarget(val, index) {
      this.currentEditItem = index;
      conversionTrackingList({
        advertiserId: val.advertiserId,
        downloadType: this.type,
        landingType:
          this.landingType === "QUICK_APP" ? "APP" : this.landingType,
        applyName: val.applyName,
        appType: this.type === "DOWNLOAD_URL" ? "APP_ANDROID" : null,
        downloadUrl: val.url,
      }).then((res) => {
        this.dataList = res;
        if (!res.length) {
          return this.$confirm("暂无转化目标，前往新建?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            // 通过账号名称获取账号id
            getOeRemark({
              page: 1,
              pageSize: 9999,
              name: this.paramsList[this.currentEditItem].advertiserName,
            })
              .then((res) => {
                let tmpItem = {};
                res.list.forEach((item) => {
                  if (
                    item.advertiserId ===
                    this.paramsList[this.currentEditItem].advertiserId
                  ) {
                    return (tmpItem = {...item});
                  }
                });
                // 回显参数
                this.changeParams = {
                  name: "",
                  convertSourceType:
                    this.type === "DOWNLOAD_URL"
                      ? "AD_CONVERT_SOURCE_TYPE_APP_DOWNLOAD"
                      : "AD_CONVERT_SOURCE_TYPE_H5_API",
                  app_type: "APP_ANDROID",
                  appName: this.paramsList[this.currentEditItem].appName,
                  package_name: this.paramsList[this.currentEditItem].applyName,
                  url: this.paramsList[this.currentEditItem].url,
                  channelIds: [tmpItem.id],
                };
                this.options = [
                  {
                    ...tmpItem,
                  },
                ];
                this.showNewChange = true;
              })
              .finally(() => {
              });
          });
        } else {
          this.showTransformDialog = true;
        }
      });
    },
    // 账户下没有转化目标时添加转化
    handleAddTarget(item, index) {
      this.currentAccountIndex = index;
      this.currentAccountData = item;
      // 通过账号名称获取账号id
      getOeRemark({
        page: 1,
        pageSize: 9999,
        name: this.paramsList[this.currentAccountIndex].advertiserName,
      })
        .then((res) => {
          let tmpItem = {};
          res.list.forEach((item) => {
            if (
              item.advertiserId ===
              this.paramsList[this.currentAccountIndex].advertiserId
            ) {
              return (tmpItem = {...item});
            }
          });
          // 回显参数
          this.changeParams = {
            name: "",
            convertSourceType:
              this.type === "DOWNLOAD_URL"
                ? "AD_CONVERT_SOURCE_TYPE_APP_DOWNLOAD"
                : "AD_CONVERT_SOURCE_TYPE_H5_API",
            app_type: "APP_ANDROID",
            appName: this.paramsList[this.currentAccountIndex].appName,
            package_name: this.paramsList[this.currentAccountIndex].applyName,
            url: this.paramsList[this.currentAccountIndex].url,
            channelIds: [tmpItem.id],
          };
          this.options = [
            {
              ...tmpItem,
            },
          ];
          this.showNewChange = true;
        })
        .finally(() => {
        });
    },
    handleUrlChange(item, index) {
      if (!item.url) {
        item.tmpTargetList = [];
        return;
      }
      this.currentAccountData = item;
      this.currentAccountIndex = index;
      conversionTrackingList({
        advertiserId: item.advertiserId,
        downloadType: this.type,
        landingType:
          this.landingType === "QUICK_APP" ? "APP" : this.landingType,
        applyName: item.applyName,
        appType: this.type === "DOWNLOAD_URL" ? "APP_ANDROID" : null,
        downloadUrl: item.url,
      }).then((res) => {
        item.tmpTargetList = res;
        this.$forceUpdate();
      });
    },
    handleConfirm() {
      let confirmFlag = true;
      this.paramsList.forEach((item, index) => {
        if (!confirmFlag) {
          return;
        }
        if (this.landingType === "QUICK_APP") {
          // 有落地页输入框判定是否选择
          if (!item.webUrl) {
            confirmFlag = false;
            return this.$message.error("请选择链接");
          }
          if (!item.appName) {
            confirmFlag = false;
            return this.$message.error("请填写应用名");
          }
          if (!item.applyName) {
            confirmFlag = false;
            return this.$message.error("请填写应用包名");
          }
          if(!item.url){
            confirmFlag = false;
            return this.$message.error("请填写监测链接");
          }
          if (!item.externalAction) {
            confirmFlag = false;
            return this.$message.error("请选择优化目标");
          }
        } else if (this.landingType === "APP") {
          if (!item.url) {
            confirmFlag = false;
            return this.$message.error("请填写链接地址");
          }
          if (!item.appName) {
            confirmFlag = false;
            return this.$message.error("请填写应用名");
          }
          if (!item.applyName) {
            confirmFlag = false;
            return this.$message.error("请填写应用包名");
          }
          if (!item.tmpSelectionConvertId) {
            confirmFlag = false;
            return this.$message.error("请选择转化目标");
          }
        } else {
          if (!item.url) {
            confirmFlag = false;
            return this.$message.error("请填写链接地址");
          }
          if (!item.tmpSelectionConvertId) {
            confirmFlag = false;
            return this.$message.error("请选择转化目标");
          }
        }
        // 有落地页输入框判定是否选择
        if (this.isShowDetail && !item.webUrl) {
          confirmFlag = false;
          return this.$message.error("请选择落地页");
        }
      });
      if (confirmFlag) {
        this.$emit("selectedAccountList", this.paramsList);
      }
    },
    confirm() {
      this.showTransformDialog = false;
    },
    cancel() {
      this.showTransformDialog = false;
    },
  },
  created() {
    this.accountUrl.forEach((item, index) => {
      if (this.landingType === 'QUICK_APP') {
        let eventsArray = this.eventsUrl[index];
        if (!eventsArray) {
          eventsArray = {};
        }
        const externalActionName = eventsArray.externalAction ? eventsArray.externalAction === 'AD_CONVERT_TYPE_ACTIVE' ? '激活' : '激活且付费' : '';
        const deepExternalActionName = eventsArray.deepExternalAction ? eventsArray.deepExternalAction === 'AD_CONVERT_TYPE_ACTIVE' ? '激活' : '激活且付费' : '';
        this.paramsList.push({
          advertiserName: item.advertiserName,
          advertiserId: item.advertiserId,
          appName: item.appName,
          applyName: item.applyName,
          convertId: item.convertId,
          webUrl: item.webUrl,
          selection: [
            {
              convertId: item.convertId,
              convert_name: item.convertName || null,
            },
          ],
          tmpSelectionConvertId: item.convertId,
          assetIds: Number(eventsArray.assetIds),
          externalAction: eventsArray.externalAction,
          externalActionName: externalActionName,
          deepExternalAction: eventsArray.deepExternalAction,
          deepExternalActionName: deepExternalActionName,
          url: eventsArray.url,
        });
      } else if (this.landingType === 'APP') {
        this.paramsList.push({
          advertiserName: item.advertiserName,
          advertiserId: item.advertiserId,
          appName: item.appName,
          applyName: item.applyName,
          convertId: item.convertId,
          url: item.url,
          webUrl: item.webUrl,
          selection: [
            {
              convertId: item.convertId,
              convert_name: item.convertName || null,
            },
          ],
          tmpSelectionConvertId: item.convertId,
        });
      } else {
        this.paramsList.push({
          advertiserName: item.advertiserName,
          advertiserId: item.advertiserId,
          appName: item.appName,
          applyName: item.applyName,
          convertId: item.convertId,
          url: item.url,
          webUrl: item.webUrl,
          selection: [
            {
              convertId: item.convertId,
              convert_name: item.convertName || null,
            },
          ],
          tmpSelectionConvertId: item.convertId,
        });
      }
    });
    this.paramsList.forEach((item, index) => {
      if (item.url && this.landingType !== 'QUICK_APP') {
        this.handleUrlChange(item, index);
      }
    });
    this.showLink = this.showD;
  },
};
</script>

<style lang='scss' scoped>
/deep/ .necessary > .el-form-item__label {
position: relative;
}
/deep/ .necessary > .el-form-item__label::before {
position: absolute;
top: 0;
left: -8px;
content: "*";
color: #f56c6c;
margin-right: 4px;
}
.form-container {
width: 400px;
height: 520px;
border: 1px solid #fbfbfb;
padding: 20px;
background-color: #fbfbfb;
border-radius: 20px;
margin: 10px auto;
flex: 1;
}

.multi-account-container {
padding: 10px;
display: flex;
flex-direction: column;
height: 100%;
.link-box {
flex: 1;
}
.multi-footer {
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 10px;
}
}
.transfor-box {
display: flex;
flex-direction: column;
height: 100%;
.transform-container {
flex: 1;
}
}
.change-target-footer {
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 10px;
}
.link-box {
width: 100%;
.link-left {
display: flex;
align-items: center;
justify-content: flex-start;
flex-wrap: wrap;
margin-bottom: 20px;
.account-item {
padding: 8px;
width: 400px;
border: 1px solid #ccc;
border-radius: 4px;
// height: 320px;
margin-left: 10px;
.account-name {
height: 20px;
line-height: 20px;
text-align: center;
}
.input-list {
.target-item {
height: 105px;
}
.input-item {
margin-top: 10px;
display: flex;
align-items: flex-start;
justify-content: flex-start;
.input-left {
text-align: right;
width: 84px;
}
.input-right {
width: 300px;
display: flex;
align-items: center;
justify-content: flex-start;
.choose-target {
margin-left: 10px;
}
}
.target-group {
width: 300px;
height: 100px;
overflow-y: auto;
.el-radio {
margin-bottom: 5px;
}
}
}
}
}
}
.link-right {
width: 500px;
> p {
margin-bottom: 20px;
}
}
}
.choose-target {
margin-top: 10px;
width: 100px;
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-wrap: wrap;
}
.transform-container {
padding: 10px;
h3 {
padding: 5px 10px;
background: #f5f5f5;
border: 1px solid #ccc;
}
.detail {
display: flex;
justify-content: flex-start;
align-items: flex-start;
> div {
width: 250px;
height: 300px;
border: 1px solid #ccc;
border-right: none;
border-top: none;
&:not(:nth-of-type(2)) {
box-sizing: border-box;
padding: 20px;
}
&:nth-of-type(2) {
p {
background: #e5e5e5;
padding: 15px;
color: #00bf8a;
}
}
}
.account-list {
> p {
margin-bottom: 25px;
> span:nth-of-type(1) {
border-radius: 10px;
padding: 5px;
background: #e5e5e5;
color: #fff;
margin-right: 10px;
}
}
}
}
}
.link-container {
padding: 10px;
}
.mini-button {
padding: 4px 10px !important;
margin: 4px 0 0 4px;
}
/deep/ .mini-button span {
font-size: 12px !important;
}
</style>
