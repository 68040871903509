<template>
  <div class="out-box">
    <div class="toutiao-container" :class="{ goToLeft: showTemplate }">
      <!-- 投放记录相关 -->
      <div class="record-box">
        <dy-table
          ref="tableOut"
          :loading="recordLoading"
          :dataList="recordData"
          :rowList="recordRowList"
          :showPagination="true"
          :page="recordPage"
          :pageSize="recordPageSize"
          :total="recordTotal"
          :height="'700'"
          @pageChange="handleRecordPageChange"
          @handleRowClick="handleRecordDetial"
          @selectionChange="handleDeletes"
          :isUseCheckbox="true"
        >
          <template #filter>
            <el-button
              class="margin-right-ten"
              type="primary"
              size="mini"
              @click="handletest"
            >
              新增
            </el-button>
            <c-input
              placeholder="请输入广告名称"
              v-model="recordSearch"
              class="margin-right-ten"
              style="width: 200px"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="handleRecordPageChange(page)"
              ></el-button>
            </c-input>
            <el-button
              type="primary"
              size="mini"
              @click="handleRecordPageChange(page)"
            >
              刷新
            </el-button>
            <el-button
              :disabled="selecteList.length == 0"
              type="primary"
              size="mini"
              @click="handleDeleteAll"
            >
              批量删除
            </el-button>
          </template>
          <template #accountType="{ row }">
            <span>{{ row.accountType === 1 ? "多账号一致" : "" }}</span>
          </template>
          <template #campaignName="{ row }">
            <span>{{
                row.campaignName ? row.campaignName : "使用广告组"
              }}</span>
          </template>
          <template #landingType="{ row }">
            <span>{{
                row.landingType === "LINK"
                  ? "销售线索收集"
                  : row.landingType === "APP"
                    ? "应用推广"
                    : "快应用"
              }}</span>
          </template>
          <template #planType="{ row }">
            <span>{{
                row.planType === 1 ? "所有计划一个组" : "一个组一个计划"
              }}</span>
          </template>
          <template #budgetMode="{ row }">
            <span>{{
                row.budgetMode === "BUDGET_MODE_INFINITE"
                  ? "不限 "
                  : row.budgetMode === "BUDGET_MODE_DAY"
                    ? "日预算"
                    : "总预算"
              }}</span>
          </template>
          <template #operation="{ row }">
            <span>{{ row.operation === "enable" ? "开启" : "关闭" }}</span>
          </template>
          <template #marketingPurpose="{ row }">
            <span>{{
                row.marketingPurpose === "CONVERSION"
                  ? "行动转化"
                  : row.marketingPurpose === "INTENTION"
                    ? "用户意向"
                    : "品牌认知"
              }}</span>
          </template>
          <template #budget="{ row }">
            <span>{{
                !row.budget || row.budgetMode == "BUDGET_MODE_INFINITE"
                  ? "不限"
                  : row.budget
              }}</span>
          </template>
        </dy-table>
        <!-- 记录详情 -->
        <el-drawer
          title="记录详情"
          :visible.sync="showRecordDetail"
          size="1700px"
          append-to-body
        >
          <recordDetail
            v-if="showRecordDetail"
            :detailId="recordDetailId"
            :landingType="currentLandingType"
            @close="showRecordDetail = false"
          />
        </el-drawer>
      </div>
      <!-- 规则模板相关 -->
      <div class="batch-container">
        <div class="batch-platform flex-row-start-center">
          <i
            class="el-icon-back"
            @click="showTemplate = false"
            style="
              font-size: 20px;
              color: rgb(204, 204, 204);
              font-weight: bold;
              cursor: pointer;
              margin-right: 10px;
            "
          ></i>
          <el-button
            type="primary"
            class="margin-right-ten"
            @click="handleShowTemplate"
          >规则模板
          </el-button
          >
        </div>
        <div class="main-container">
          <OceanEngineTemplate ref="refFirst"/>
        </div>
        <div class="button-list">
          <div class="list-left">
            <el-button type="" @click="handleUpdateTemplate"
            >更新模板规则
            </el-button
            >
            <el-button @click="handleClearTemplate" type="danger"
            >清除规则
            </el-button
            >
          </div>
          <div class="list-right">
            <el-button @click="handleSaveTemplate" type="warning"
            >保存为新模板
            </el-button
            >
            <el-button
              :loading="uploadLoading"
              @click="handleUploadAd"
              type="primary"
            >生成广告计划
            </el-button
            >
          </div>
        </div>
        <!-- 模板选择框 -->
        <el-drawer
          title="规则模板列表"
          :visible.sync="showTemplateList"
          size="1000px"
          :destroy-on-close="true"
        >
          <div class="tmp-box">
            <dy-table
              :loading="tempateLoading"
              :rowList="rowList"
              :dataList="templateList"
              :page="page"
              :pageSize="pageSize"
              :total="total"
              :height="'700px'"
              :showPagination="true"
              @pageChange="handlePageChange"
            >
              <template #filter>
                <el-button class="margin-right-ten" @click="handlePageChange"
                >刷新数据
                </el-button
                >
                <c-input
                  placeholder="请输入模板名"
                  class="margin-right-ten"
                  v-model="templateName"
                  style="width: 200px"
                >
                  <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="handlePageChange"
                  ></el-button>
                </c-input>
              </template>
              <template #operation="row">
                <el-button
                  type="primary"
                  size="small"
                  @click="handleUseTemplate(row.row)"
                >引用
                </el-button
                >
                <el-button
                  type="danger"
                  size="small"
                  @click="handleDeleteTemplate(row.row)"
                >删除
                </el-button
                >
              </template>
            </dy-table>
          </div>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
import OceanEngineTemplate from "@/views/small-plane/oceanengine";
import recordDetail from "./recordDetail.vue";
import {
  getRuleTemplate,
  detailRuleTemplate,
  addRuleTemplate,
  updateRuleTemplate,
  uploadAd,
  getUploadAdRecord,
  deleteRuleTemplate,
  deleteUploadAdRecord,
} from "@/api/toutiao";

export default {
  data() {
    return {
      checkBox: false,
      showTemplateList: false,
      tempateLoading: false,
      templateName: null,
      templateList: [],
      page: 1,
      pageSize: 15,
      total: 0,
      rowList: [
        {
          label: "ID",
          prop: "id",
        },
        {
          label: "规则ID",
          prop: "adRuleId",
        },
        {
          label: "模板名称",
          prop: "templateName",
        },
        {
          label: "创建时间",
          prop: "createdTime",
        },
        {
          label: "操作",
          action: "operation",
        },
      ],
      tmpDetail: {},
      isComplete: false,
      newTemplateName: "",
      templateId: null,
      uploadLoading: false,
      // 记录相关
      selecteList: [],
      showTemplate: false,
      recordPage: 1,
      recordPageSize: 15,
      recordTotal: 0,
      recordRowList: [
        {
          label: "ID",
          prop: "id",
        },
        {
          label: "账号类型",
          prop: "accountType",
          slot: true,
        },
        {
          label: "计划规则",
          prop: "planType",
          slot: true,
        },
        {
          label: "广告组名称",
          prop: "campaignName",
          slot: true,
        },
        {
          label: "推广目的",
          prop: "landingType",
          slot: true,
        },
        {
          label: "广告组预算",
          prop: "budget",
          slot: true,
        },
        {
          label: "预算类型",
          prop: "budgetMode",
          slot: true,
        },
        {
          label: "广告组状态",
          prop: "operation",
          slot: true,
        },
        {
          label: "营销目的",
          prop: "marketingPurpose",
          slot: true,
        },
        {
          label: "创建时间",
          prop: "createdTime",
        },
      ],
      recordData: [],
      recordLoading: false,
      recordSearch: null,
      showRecordDetail: false,
      recordDetailId: null,
      currentLandingType: "QUICK_APP",
      judgeCompleteFlag: false,
    };
  },
  provide: function () {
    return {
      templateDetial: this.getTmpDetail,
      updateAllTemplate: this.handleUpdataTemplate,
    };
  },
  methods: {
    /*
     *==========
     *记录相关 ==
     *==========
     */
    handleDeleteAll() {
      this.$confirm("确定要批量删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteUploadAdRecord(this.selecteList.join(",")).then((res) => {
          this.$message.success("删除成功");
          this.handleRecordPageChange(1);
        });
      });
    },
    handleDeletes(data) {
      this.selecteList = data.map((item) => {
        return item.id;
      });
    },
    handleRecordDetial(row) {
      this.recordDetailId = row.id;
      this.currentLandingType = row.landingType;
      this.showRecordDetail = true;
    },
    handleRecordPageChange(page) {
      if (typeof page === "number") {
        this.recordPage = page;
      }
      getUploadAdRecord({
        page: this.recordPage,
        pageSize: this.recordPageSize,
        search: this.recordSearch,
      })
        .then((res) => {
          this.recordData = res.list;
          this.recordTotal = res.total;
          if (page === "click") {
            // 直接打开新增计划详情
            this.handleRecordDetial(this.recordData[0]);
          }
        })
        .finally(() => {
        });
    },
    /*
     *==========
     *模板相关 ==
     *==========
     */
    handletest() {
      this.showTemplate = !this.showTemplate;
    },
    // 判断各模块是否有数据
    handleJudgeComplete() {
      // 账号
      if (!this.tmpDetail.channels || this.tmpDetail.channels.length === 0) {
        return this.$message.error("请完善账号模块");
      }
      // 广告组
      if (
        !this.tmpDetail.adRule ||
        JSON.stringify(this.tmpDetail.adRule) === "{}"
      ) {
        return this.$message.error("请完善广告组数据");
      }
      if (this.tmpDetail.campaigns && this.tmpDetail.campaigns.length) {
        // 已选广告组是否有没选账号的
        let campaignsFlag = this.tmpDetail.campaigns.some((item) => {
          if (!item.campaignId) {
            return true;
          } else {
            return false;
          }
        });
        if (campaignsFlag) {
          return this.$message.error("请完善广告组");
        }
      } else {
        this.tmpDetail.campaigns = null;
      }
      // 计划基本信息
      if (
        !this.tmpDetail.planRule ||
        JSON.stringify(this.tmpDetail.planRule) === "{}"
      ) {
        return this.$message.error("请完善计划基本信息数据模块");
      }
      // 多账号链接完整度检查
      let AccountFlag = false;
      const events = this.tmpDetail.events;
      // 链接和计划同存
      AccountFlag = this.tmpDetail.urls.some((item, index) => {
        if (this.tmpDetail.adRule.landingType === "QUICK_APP") {
          if (!item.webUrl || !item.appName || !item.applyName) {
            return true;
          }
          const event = events[index];
          if (!event.externalAction) {
            return true;
          }
          if (!event.url) {
            return true;
          }
        } else if (this.tmpDetail.adRule.landingType === "LINK") {
          if (!item.url || !item.convertId) {
            return true;
          }
        } else {
          if (!item.url || !item.convertId || !item.appName || !item.applyName) {
            return true;
          }
        }
      });
      if (AccountFlag) {
        return this.$message.error("请完善计划基本信息下多账号填写");
      }
      // 快应用直达链接
      if (
        this.tmpDetail.adRule.landingType === "QUICK_APP" &&
        !this.tmpDetail.planTarget.openUrl
      ) {
        return this.$message.error("请完善计划基本信息下直达链接");
      }
      // 定向包
      if (!this.tmpDetail.audiences || this.tmpDetail.audiences.length === 0) {
        return this.$message.error("请完善定向包模块");
      }
      // 创意基本信息
      if (
        !this.tmpDetail.basisCreative ||
        JSON.stringify(this.tmpDetail.basisCreative) === "{}"
      ) {
        return this.$message.error("请完善创意基本信息模块");
      }
      if (!this.handleJudgeShowCreatCards()) {
      } else {
        if (!this.tmpDetail.creativeCards.length) {
          this.judgeCompleteFlag = false;
          return this.$message.error("请完善创意基础信息模块下推广卡片部分");
        }
      }
      // 创意卡片判断
      let creativeFlag = this.tmpDetail.creativeCards.some((item) => {
        if (!item.componentId) {
          return true;
        } else {
          return false;
        }
      });
      if (creativeFlag) {
        return this.$message.error("请完善创意基础信息模块下推广卡片部分");
      }
      // 标题包
      if (!this.tmpDetail.documents || this.tmpDetail.documents.length === 0) {
        this.judgeCompleteFlag = false;
        return this.$message.error("请完善标题包模块");
      }
      // 创意素材
      if (!this.tmpDetail.materials || this.tmpDetail.materials.length === 0) {
        return this.$message.error("请完善创意素材");
      }
      if (this.tmpDetail.adRule.landingType !== "QUICK_APP") {
        this.tmpDetail.events = [];
        this.tmpDetail.planTarget.openUrl = null;
        this.tmpDetail.urls.forEach((item) => {
          item.webUrl = null;
          if (this.tmpDetail.adRule.landingType !== "APP") {
            item.appName = null;
            item.applyName = null;
          }
        });
      } else {
        this.tmpDetail.urls.forEach((item) => {
          item.convertId = null;
          item.url = null;
        })
      }
      this.judgeCompleteFlag = true;
    },
    handleJudgeShowCreatCards() {
      // 穿山甲不需要推广卡片
      if (
        this.tmpDetail.planRange &&
        this.tmpDetail.planRange.deliveryRange === "UNION" &&
        this.tmpDetail.planRange.inventoryType.includes("INVENTORY_UNION_SLOT")
      ) {
        return false;
      }
      //只选择头条不需要
      if (this.tmpDetail.planRange.inventoryType === "INVENTORY_FEED") {
        return false;
      }
      //只选择西瓜不需要
      if (this.tmpDetail.planRange.inventoryType === "INVENTORY_VIDEO_FEED") {
        return false;
      }
      return true;
    },
    getTmpDetail() {
      return this.tmpDetail;
    },
    handlePageChange(page) {
      if (typeof page === "number") {
        this.page = page;
      }
      this.page = this.page || page;
      this.handleGetTemplate();
    },
    // 更新模板
    handleUpdateTemplate() {
      if (!this.templateId) {
        return this.$message.error(
          "未引用规则模板，请先引用模板或者保存为新模板"
        );
      }
      this.handleJudgeComplete();
      if (!this.judgeCompleteFlag) {
        return;
      }
      this.$prompt("模板名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: this.tmpDetail.templateName || "",
      })
        .then(({value}) => {
          this.newTemplateName = value;
          let tmpAudienceIds = this.tmpDetail.audiences.map((item) => item.id);
          updateRuleTemplate(this.templateId, {
            ...this.tmpDetail,
            audienceIds: tmpAudienceIds,
            templateName: value,
          })
            .then((res) => {
              this.$message.success("修改成功");
            })
            .finally(() => {
            });
        })
        .catch(() => {
        });
    },
    // 展示模板列表
    handleShowTemplate() {
      this.showTemplateList = true;
      setTimeout(() => {
        this.handleGetTemplate();
      }, 200);
    },
    // 获取模板列表
    handleGetTemplate() {
      this.tempateLoading = true;
      getRuleTemplate({
        templateName: this.templateName,
        page: this.page,
        pageSize: this.pageSize,
      })
        .then((res) => {
          this.templateList = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.tempateLoading = false;
        });
    },
    // 每个模块更新后可调此接口更新所有模块同步数据
    handleUpdataTemplate(type) {
      this.$refs.refFirst.$refs.accountTmp.handleUpdateData(type);
      this.$refs.refFirst.$refs.adtRules.handleUpdateData(type);
      this.$refs.refFirst.$refs.planBaseInfo.handleUpdateData(type);
      this.$refs.refFirst.$refs.oriPackage.handleUpdateData(type);
      this.$refs.refFirst.$refs.originalityBase.handleUpdateData(type);
      this.$refs.refFirst.$refs.titlePackageRef.handleUpdateData(type);
      this.$refs.refFirst.$refs.originalityRef.handleUpdateData(type);
    },
    // 引用模板
    handleUseTemplate(row) {
      this.templateId = row.id;
      detailRuleTemplate(row.id)
        .then((res) => {
          this.tmpDetail = res;
          this.handleUpdataTemplate();
        })
        .finally(() => {
          this.showTemplateList = false;
        });
    },
    // 删除模板
    handleDeleteTemplate(row) {
      this.$confirm("确定要该模板吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteRuleTemplate(row.id)
          .then((res) => {
            this.$message.success("删除成功");
          })
          .finally(() => {
            this.handleGetTemplate();
          });
      });
    },
    handleClearTemplate() {
      this.$confirm("确定要清除当前模板?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.templateId = null;
        localStorage.setItem("toutiao", {});
        this.tmpDetail = {};
        this.handleUpdataTemplate("clear");
        this.$message.success("清除成功");
      });
    },
    // 保存为模板
    handleSaveTemplate() {
      this.handleJudgeComplete();
      if (!this.judgeCompleteFlag) {
        return;
      }
      this.$prompt("请输入模板名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({value}) => {
          this.newTemplateName = value;
          // 定向包数据处理
          // return;
          let tmpAudienceIds = this.tmpDetail.audiences.map((item) => item.id);
          addRuleTemplate({
            ...this.tmpDetail,
            templateName: value,
            audienceIds: tmpAudienceIds,
          }).then((res) => {
            this.$message.success("新增成功");
          });
        })
        .catch(() => {
        });
    },
    // 生成广告计划
    handleUploadAd() {
      this.handleJudgeComplete();
      if (!this.judgeCompleteFlag) {
        return;
      }
      // 判定创意素材是否为图片 是则清空推广卡片
      if (this.tmpDetail.materials[0].list[0].type === 1) {
        this.tmpDetail.creativeCards = [];
      }
      // return;
      this.$confirm("确定要将此条模板生成计划?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.uploadLoading = true;
        let tmpAudienceIds = this.tmpDetail.audiences.map((item) => item.id);
        uploadAd({
          ...this.tmpDetail,
          audienceIds: tmpAudienceIds,
        })
          .then((res) => {
            this.$message.success("生成计划成功");
            this.tmpDetail = {};
            localStorage.setItem("toutiao", JSON.stringify({}));
            this.handleUpdataTemplate("clear");
            this.showTemplate = false;
            this.handleRecordPageChange("click");
          })
          .finally(() => {
            this.uploadLoading = false;
          });
      });
    },
  },
  created() {
    this.handleRecordPageChange();
    // 缓存模板
    window.addEventListener("beforeunload", (e) => {
      if (JSON.stringify(this.tmpDetail) !== "{}") {
        localStorage.setItem("toutiao", JSON.stringify(this.tmpDetail));
      }
    });
  },
  mounted() {
    let tmpdata = localStorage.getItem("toutiao");
    if (!tmpdata) {
      return;
    }
    let channels = JSON.parse(tmpdata).channels;
    if (tmpdata && channels && channels.length > 0) {
      this.tmpDetail = JSON.parse(localStorage.getItem("toutiao"));
      this.handleUpdataTemplate();
    }
  },
  components: {OceanEngineTemplate, recordDetail},
  // 缓存
  beforeRouteLeave(to, from, next) {
    if (JSON.stringify(this.tmpDetail) !== "{}") {
      localStorage.setItem("toutiao", JSON.stringify(this.tmpDetail));
    }
    next();
  },
};
</script>
<style lang='scss' scoped>
.out-box {
width: 100%;
overflow: hidden;
.goToLeft {
margin-left: -100%;
}
}
.toutiao-container {
overflow: hidden;
width: 200%;
margin-left: 0;
transition: all 0.3s ease;
// 记录相关
.record-box {
width: 50%;
float: left;
}
// 模板
.batch-container {
width: 50%;
float: left;
background-color: #fff;
float: left;
// margin-left: 100%;
z-index: 2;
.batch-platform {
margin-bottom: 20px;
}
.main-container {
position: relative;
}
.button-list {
margin-top: 60px;
display: flex;
align-items: center;
justify-content: space-between;
}
}
.tmp-box {
padding: 20px;
}
}
</style>
