<template>
  <div class="small-plan-part">
    <div class="title flex-row-between-center">
      <span>创意素材</span>
      <span class="edit" @click="handleEditResource">编辑</span>
    </div>
    <div class="container">
      <div class="result-item" v-for="(item, index) in tmpData.materials">
        <div class="title-box">
          <p class="name">
            <span>
              {{ item.name }}
            </span>
            <span style="color: #00bf8a">
              {{ "(" + item.list.length + ")" }}
            </span>
          </p>
          <i class="el-icon-close" @click="handleDeleteResource(index)"></i>
        </div>
        <div class="img-box">
          <img
            v-for="(item2, index2) in echoimg(item)"
            :src="
              item2.url.includes('.mp4')
                ? item2.url + '?vframe/jpg/offset/0'
                : item2.url
            "
            alt=""
          />
        </div>
      </div>
    </div>
    <el-drawer title="添加创意素材" :visible.sync="showResource" size="1200px">
      <el-form label-width="90px">
        <el-form-item label="创意方式：">
          <span>程序化创意</span>
          <!-- <el-input v-model="params.show" style="width: 350px"></el-input> -->
        </el-form-item>
        <el-form-item class="resource-tabs" label="素材内容：">
          <el-tabs
            v-model="editTabsValue"
            type="card"
            closable
            @tab-remove="handleRemoveTab"
            @tab-click="handleEditPackageName"
            style="width: 870px"
          >
            <el-tab-pane
              v-for="(item, index) in editableTabs"
              :key="index"
              :label="item.name"
              :name="index.toString()"
            >
              <div class="resource-type">
                <el-badge type="primary" :value="resourceList(1)" class="item">
                  <div
                    class="type-box"
                    :class="{ active: typeValue == 1 }"
                    @click="handleChangeType(1)"
                  >
                    <div class="left left-video-vertical">
                      <div>
                        <i class="el-icon-caret-right"></i>
                      </div>
                    </div>
                    <div class="right">竖版视频</div>
                  </div>
                </el-badge>
                <el-badge type="primary" :value="resourceList(2)" class="item">
                  <div
                    class="type-box"
                    :class="{ active: typeValue == 2 }"
                    @click="handleChangeType(2)"
                  >
                    <div class="left left-video-horizon">
                      <div>
                        <i class="el-icon-caret-right"></i>
                      </div>
                    </div>
                    <div class="right">横版视频</div>
                  </div>
                </el-badge>
                <el-badge type="primary" :value="resourceList(3)" class="item">
                  <div
                    class="type-box"
                    :class="{ active: typeValue == 3 }"
                    @click="handleChangeType(3)"
                  >
                    <div class="left left-bigimg-vertical">
                      <div></div>
                    </div>
                    <div class="right">竖版大图</div>
                  </div>
                </el-badge>
                <el-badge type="primary" :value="resourceList(4)" class="item">
                  <div
                    class="type-box"
                    :class="{ active: typeValue == 4 }"
                    @click="handleChangeType(4)"
                  >
                    <div class="left left-video-horizon">
                      <div></div>
                    </div>
                    <div class="right">横板大图</div>
                  </div>
                </el-badge>
                <el-badge type="primary" :value="resourceList(5)" class="item">
                  <div
                    class="type-box type-box-noborder"
                    :class="{ active: typeValue == 5 }"
                    @click="handleChangeType(5)"
                  >
                    <div class="left left-video-horizon">
                      <div></div>
                    </div>
                    <div class="right">小图</div>
                  </div>
                </el-badge>
              </div>
              <div class="resource-format">
                <p>
                  每个素材组包含1个视频和1张封面，最多添加10个视频素材
                  <span style="margin-left: 6px; color: #ccc"
                    >视频格式要求</span
                  >
                  <el-tooltip placement="bottom" effect="light">
                    <div slot="content">
                      <ul>
                        <li
                          class="tips-resource"
                          v-for="(item, index) in tipsType"
                        >
                          {{ item }}
                        </li>
                      </ul>
                    </div>
                    <i style="margin-left: 6px" class="el-icon-question"></i>
                  </el-tooltip>
                </p>
                <p>
                  通投智选创意必须包含：1个横版视频、1个竖版视频、1个横版大图，或启用自动派生
                </p>
              </div>
              <div class="resource-item-box">
                <template
                  v-for="(item, index) in editableTabs[editTabsValue].list"
                >
                  <div
                    :key="index"
                    v-if="item.imageMode == resourceTypeList[typeValue - 1]"
                    class="resource-item"
                    :style="backgroundImg(item)"
                  >
                    <div class="item-left">
                      <el-button
                        class="changeVideo"
                        type="primary"
                        @click="handleShowChoiceResource(2, index)"
                        >替换</el-button
                      >
                    </div>
                    <div @click="handleDeleteType(index)" class="item-delete">
                      X
                    </div>
                  </div>
                </template>
                <div class="resource-add" @click="handleShowChoiceResource(1)">
                  <div>
                    <i class="el-icon-plus"></i>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
          <el-button size="small" type="primary" @click="handleaddTab">
            多图包+
          </el-button>
          <el-button
            size="small"
            type="warning"
            @click="handleShowChoiceResource(3)"
          >
            一图包+
          </el-button>
        </el-form-item>
      </el-form>
      <div class="demo-drawer__footer">
        <el-button @click="showResource = false">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-drawer>
    <!-- 选择素材 -->
    <el-drawer
      title="选择素材"
      :visible.sync="showChoiceResource"
      size="1700px"
      id="choiceDrawer"
    >
      <resource-choice
        v-if="showChoiceResource"
        :resourceType="typeValue > 2 ? 1 : 2"
        :multipleable="Multipleable"
        :tabData="editableTabs[editTabsValue]"
        @selectSuccess="handleAddItem"
      />
    </el-drawer>
  </div>
</template>

<script>
import resourceChoice from "../components/resourceChoice.vue";
export default {
  data() {
    return {
      showResource: false,
      editTabsValue: "0",
      tpEditTabsValue: "0",
      typeValue: 1,
      editableTabs: [
        {
          name: "创意1",
          list: [],
        },
      ],
      tips: [
        [
          "视频格式：",
          "竖版：",
          "宽高比9:16，视频码率≥516kbps，大小≤100M，分辨率≥720*1280，时长≥4s",
          "竖版封面：",
          "建议最佳，宽高比9:16，720*1280≤尺寸≤1440*2560，支持JPG、PNG等图片格式",
          "横版：",
          "宽高比16:9，视频码率≥516kbps，大小≤1000M，分辨率≥1280*720，时长≥4s",
          "横版封面：",
          "建议最佳，宽高比16:9，1280*720≤尺寸≤2560*1440，支持JPG、PNG等图片格式",
        ],
        [
          "图片格式：",
          "竖版大图：",
          "建议最佳，宽高比9:16，720*1280≤尺寸≤1440*2560，支持JPG、PNG等图片格式",
          "横版大图：",
          "建议最佳，宽高比16:9，1280*720≤尺寸≤2560*1440，支持JPG、PNG等图片格式",
          "小图：",
          "穿山甲开屏图片：",
          "建议最佳：宽高比9:16，1080*1920≤尺寸≤2160*3840，支持JPG、PNG等图片格式",
          "组图：",
          "建议最佳：宽高比1.52，456*300≤尺寸≤1368*900，支持JPG、PNG等图片格式",
        ],
      ],
      resourceTypeList: [
        "CREATIVE_IMAGE_MODE_VIDEO_VERTICAL",
        "CREATIVE_IMAGE_MODE_VIDEO",
        "CREATIVE_IMAGE_MODE_LARGE_VERTICAL",
        "CREATIVE_IMAGE_MODE_LARGE",
        "CREATIVE_IMAGE_MODE_SMALL",
      ],
      tmpData: {
        materials: [],
      },
      showChoiceResource: false,
      Multipleable: true,
      currentResourceIndex: null,
      MultiplePackage: false,
    };
  },
  computed: {
    resourceList() {
      return (type) => {
        let tmpArray = this.editableTabs[this.editTabsValue].list.filter(
          (item) => item.imageMode == this.resourceTypeList[type - 1]
        );
        return tmpArray.length;
      };
    },
    backgroundImg() {
      return (item) => {
        let url =
          item.type === 1 ? item.url : item.url + "?vframe/jpg/offset/0";
        // return "url(" + url + ")";
        return {
          backgroundImage: "url(" + url + ")",
          backgroundSize: "cover",
        };
      };
    },
    tipsType: function () {
      if (this.typeValue > 2) {
        return this.tips[1];
      } else {
        return this.tips[0];
      }
    },
    echoimg() {
      return (item) => {
        if (item.list.length > 5) {
          return item.list.slice(0, 6);
        } else {
          return item.list;
        }
      };
    },
  },
  inject: ["templateDetial", "updateAllTemplate"],
  created() {
    this.tmpData = this.templateDetial();
  },
  methods: {
    handleEditPackageName() {
      // 同个栏目点击两次编辑创意包名
      if (this.editTabsValue === this.tpEditTabsValue) {
        this.$prompt("修改创意包名", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputValue: this.editableTabs[this.editTabsValue].name,
        })
          .then(({ value }) => {
            this.editableTabs[this.editTabsValue].name = value;
          })
          .catch(() => {});
      }
      this.tpEditTabsValue = this.editTabsValue;
    },
    handleDeleteResource(index) {
      this.tmpData.materials.splice(index, 1);
      this.$forceUpdate();
    },
    handleSubmit() {
      let tmpList = [];
      let isVideo = false;
      let isImg = false;
      this.editableTabs.forEach((item1) => {
        let list = [];
        item1.list.forEach((item2) => {
          if (item2.type == 1) {
            isVideo = true;
          }
          if (item2.type == 2) {
            isImg = true;
          }
          list.push({
            ...item2,
          });
        });
        // 如果存在素材就保存
        if (list.length) {
          tmpList.push({
            id: item1.id,
            list: list,
            name: item1.name,
          });
        }
      });
      // 如果存在图片视频混传
      if (isVideo && isImg) {
        return this.$message.error("不支持图片视频混传，请选择单一类型素材");
      }
      this.tmpData.materials = tmpList;
      this.editTabsValue = "0";
      this.showResource = false;
    },
    handleShowChoiceResource(type, index) {
      if (type === 1) {
        this.Multipleable = true;
        this.MultiplePackage = true;
      } else if (type === 2) {
        this.Multipleable = false;
        this.MultiplePackage = true;
        this.currentResourceIndex = index;
      } else if (type === 3) {
        this.Multipleable = true;
        this.MultiplePackage = false;
      }
      this.showChoiceResource = true;
    },
    handleUpdateData() {
      this.tmpData = this.templateDetial();
      this.editableTabs = [
        {
          name: "创意1",
          list: [],
        },
      ];
    },
    handleDeleteType(index) {
      // let index = this.editableTabs[this.editTabsValue].list.findIndex(item=>{
      //   item.
      // })
      this.editableTabs[this.editTabsValue].list.splice(index, 1);
    },
    handleChangeType(type) {
      this.typeValue = type;
    },
    // 多图创意包
    handleaddTab() {
      let newTabName = "创意" + (this.editableTabs.length + 1);
      this.editableTabs.push({
        name: newTabName,
        list: [],
      });
      this.editTabsValue = this.editableTabs.length - 1 + "";
    },
    handleAddItem(videos) {
      // 多图一包
      if (this.MultiplePackage) {
        // 修改视频
        if (!this.Multipleable) {
          this.editableTabs[this.editTabsValue].list[
            this.currentResourceIndex
          ] = {
            imageMode: this.resourceTypeList[this.typeValue - 1],
            ...videos[0],
          };
          // 新增视频
        } else {
          videos.forEach((item) => {
            this.editableTabs[this.editTabsValue].list.push({
              imageMode: this.resourceTypeList[this.typeValue - 1],
              url: item.url,
              type: item.type,
              materialId: item.materialId,
            });
          });
        }
        // 单图一包
      } else {
        videos.forEach((item) => {
          this.editableTabs.push({
            name: item.title,
            list: [
              {
                imageMode: this.resourceTypeList[this.typeValue - 1],
                url: item.url,
                type: item.type,
                materialId: item.materialId,
              },
            ],
          });
        });
        this.editTabsValue = this.editableTabs.length - 1 + "";
      }

      this.showChoiceResource = false;
    },
    handleEditResource() {
      this.editTabsValue = "0";
      this.typeValue = 1;
      // 深拷贝materials
      if (this.tmpData.materials && this.tmpData.materials.length) {
        this.editableTabs = [];
        this.tmpData.materials.forEach((item1) => {
          let list = [];
          item1.list.forEach((item2) => {
            list.push({
              ...item2,
            });
          });
          this.editableTabs.push({
            id: item1.id,
            list: list,
            name: item1.name,
          });
        });
      } else {
        this.editableTabs = [
          {
            name: "创意1",
            list: [],
          },
        ];
      }
      this.showResource = true;
    },
    handleRemoveTab(targetName) {
      if (this.editableTabs.length > 1) {
        this.$confirm("确定要删除该创意素材?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.editableTabs.splice(targetName, 1);
          this.editTabsValue = "0";
        });
      }
    },
  },
  components: {
    resourceChoice,
  },
};
</script>
<style lang='scss' scoped>
/deep/ #choiceDrawer .el-drawer__header {
  margin-bottom: 0 !important;
}
/deep/ .resource-tabs .el-form-item__content {
  .el-tabs {
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
  }
}
.resource-type {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  .type-box.active {
    border: 1px solid #00bf8a;
  }
  .type-box {
    width: 130px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    .left {
      width: 30px;
      height: 50px;
      border: 1px solid #ccc;
      margin-right: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ccc;
        i {
          color: #fff;
        }
      }
    }
    .left-video-horizon {
      div {
        height: 20px;
      }
    }
  }
  .type-box-noborder .left {
    border: none !important;
  }
}
.resource-item-box {
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.resource-add {
  width: 110px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  div {
    width: 110px;
    height: 200px;
    border: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    cursor: pointer;
    i {
      font-size: 40px;
      color: #ccc;
    }
  }
}
.resource-item {
  width: 110px;
  height: 200px;
  border: 1px solid #ccc;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  margin-top: 10px;
  .item-left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .item-delete {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    font-size: 12px;
    background-color: #ccc;
    color: #fff;
    border-radius: 50%;
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .changeVideo {
    display: none;
    margin: 0 !important;
    margin-bottom: 10px !important;
  }
  &:hover {
    .changeVideo {
      display: inline-block;
    }
    .item-delete {
      display: flex;
    }
  }
}
.tips-resource {
  line-height: 20px;
}
.demo-drawer__footer {
  margin-top: 20px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.container {
  padding: 4px;
}
.result-item {
  height: 90px;
  margin-top: 10px;
  padding: 4px;
  background-color: #e9ecf1;
  border-radius: 4px;
  .title-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .img-box {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
      width: 30px;
      height: 40px;
      margin-right: 4px;
    }
  }
}
</style>