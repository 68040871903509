/* eslint-disable semi */
<template>
  <div class="small-plan-part pack-container">
    <div class="title flex-row-between-center">
      <span>定向包</span>
      <span class="edit" @click="editItem">编辑</span>
    </div>
    <div class="container">
      <p
        class="account-item flex-row-between-center"
        v-for="(item, index) in packList"
        :key="item.id"
      >
        <span>{{ item.name }}</span>
        <i class="el-icon-close" @click="handleDeletePack(index)"></i>
      </p>
    </div>
    <el-dialog
      title="选择定向"
      width="1100px"
      top="20px"
      v-dialogDrag
      :visible.sync="showDialog"
      :before-close="
        () => {
          handleClose();
        }
      "
    >
      <div>
        <div style="margin-bottom: 10px">
          <!-- <span class="required">定向包</span> -->
          <!-- <el-button type="primary" @click="addPackage">新建定向包</el-button> -->
        </div>
        <dy-table
          ref="orientationTable"
          :loading="loading"
          :dataList="dataList"
          :rowList="rowList"
          :showPagination="true"
          :page="page"
          :pageSize="pageSize"
          :total="total"
          :isUseCheckbox="true"
          @selectionChange="handleSelectionChange"
          @selected="handleSelected"
          @row-click="handleRowClick"
          @pageChange="handlePageChange"
          :height="'500'"
        >
          <template #filter>
            <el-button @click="initData" class="margin-right-ten"
              >刷新数据</el-button
            >
            <c-input
              placeholder="请输入搜索内容"
              v-model="search"
              class="margin-right-ten"
              style="width: 210px"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="initData"
              ></el-button>
            </c-input>
            <el-button type="primary" @click="addPackage">新建定向包</el-button>
          </template>
          <template #operation="row">
            <el-button
              size="small"
              class="button-small"
              type="primary"
              @click.stop="handleEditPack(row.row)"
              >编辑</el-button
            >
            <el-button
              :autoConfirm="true"
              class="button-small"
              size="small"
              @click="handleDelete(row)"
              >删除</el-button
            >
          </template>
        </dy-table>
      </div>
      <div slot="footer" class="package-footer">
        <div>
          <el-button @click="showDialog = false">取 消</el-button>
          <el-button
            type="primary"
            :disabled="selectedData.length === 0"
            @click="handleConfirm"
            >确 定</el-button
          >
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="新增定向模板"
      width="1200px"
      top="0vh"
      v-dialogDrag
      :visible.sync="showAddDialog"
      :before-close="
        () => {
          handleClose('add');
        }
      "
    >
      <div class="flex-row-between-start" style="height: 700px; overflow: auto">
        <div>
          <section class="base-info">
            <h3>基本信息</h3>
            <div style="margin-top: 20px">
              <div class="per-part flex-row-start-center">
                <span class="required">定向包名称</span>
                <c-input
                  v-model="params.name"
                  style="width: 400px"
                  placeholder="请输入定向包名称，最多20个字符"
                  :maxlength="20"
                ></c-input>
              </div>
              <div class="per-part flex-row-start-start">
                <span>定向包描述</span>
                <c-input
                  type="textarea"
                  v-model="params.description"
                  style="width: 400px"
                  placeholder="定向包描述"
                  :maxlength="50"
                ></c-input>
              </div>
            </div>
          </section>
          <section class="user-orientation">
            <h3>用户定向</h3>
            <div class="per-part flex-row-start-start">
              <span style="margin-top: 10px" @click="handleReductionCity"
                >地域</span
              >
              <div>
                <div class="region-type">
                  <el-radio-group v-model="params.district">
                    <el-radio-button label="NONE">不限</el-radio-button>
                    <el-radio-button label="CITY">按省市</el-radio-button>
                    <el-radio-button label="COUNTY">按区县</el-radio-button>
                    <el-radio-button label="BUSINESS_DISTRICT"
                      >按商圈</el-radio-button
                    >
                  </el-radio-group>
                </div>
                <!-- 地域筛选器 -->
                <div
                  class="region-select flex-row-start-start"
                  v-if="params.district !== 'NONE'"
                >
                  <div class="city-list">
                    <!-- 省市 区县搜索 -->
                    <el-input
                      placeholder="输入关键字进行过滤"
                      v-model="filterText"
                      v-if="
                        !['BUSINESS_DISTRICT', 'NONE'].includes(params.district)
                      "
                    >
                    </el-input>
                    <!-- 商圈级联 -->
                    <el-cascader
                      v-else
                      v-model="params.test"
                      :options="options"
                      :props="{ label: 'name', value: 'id' }"
                      clearable
                      @change="handleCascaderChange"
                      style="width: 100%"
                    >
                    </el-cascader>
                    <!-- 仅省市商圈有全选 -->
                    <el-checkbox
                      v-if="
                        !['NONE', 'COUNTY'].includes(params.district) &&
                        regionParams[params.district].cityList.length > 0
                      "
                      style="margin-left: 23px; margin-top: 5px"
                      @change="hanleCheckAll"
                      v-model="regionParams[params.district].isCheckAll"
                      >全选</el-checkbox
                    >
                    <!-- :data="cityList" -->
                    <el-tree
                      class="city-tree"
                      ref="cityTree"
                      :style="getCityTreeStyle()"
                      show-checkbox
                      node-key="id"
                      check-on-click-node
                      :default-checked-keys="
                        regionParams[params.district].echoData
                      "
                      :data="regionParams[params.district].cityList"
                      :filter-node-method="filterNode"
                      @check="handleCheckChange"
                    >
                      <span slot-scope="{ node, data }">{{ data.name }}</span>
                    </el-tree>
                  </div>
                  <!-- 已选框 -->
                  <div class="city-select">
                    <div class="title-info">
                      <span>已选</span>
                      <span
                        style="
                          font-weight: bold;
                          color: #00bf8a;
                          cursor: pointer;
                        "
                        @click="clearChooseCityList"
                        >清空</span
                      >
                    </div>
                    <div class="city-select-list">
                      <el-tag
                        style="margin-bottom: 5px"
                        v-for="item in regionParams[params.district].chooseList"
                        :key="item.id"
                        closable
                        @close="closeChooseCityItem(item)"
                      >
                        {{ item.name }}
                      </el-tag>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="per-part flex-row-start-center">
              <span>性别</span>
              <el-radio-group v-model="params.gender">
                <el-radio-button label="NONE">不限</el-radio-button>
                <el-radio-button label="GENDER_MALE">男</el-radio-button>
                <el-radio-button label="GENDER_FEMALE">女</el-radio-button>
              </el-radio-group>
            </div>
            <div class="per-part flex-row-start-center">
              <span :class="{ required: this.params.district !== 'NONE' }"
                >位置类型用户</span
              >
              <el-radio-group v-model="params.locationType">
                <el-radio-button label="ALL">该地区所有用户</el-radio-button>
                <el-radio-button label="CURRENT">正在该地区</el-radio-button>
                <el-radio-button label="HOME">居住在该地区</el-radio-button>
                <el-radio-button label="TRAVEL">该地区旅行</el-radio-button>
              </el-radio-group>
            </div>
            <div class="per-part flex-row-start-center">
              <span>年龄</span>
              <el-checkbox-group
                @change="handleCheckout('out', params.age, 6, 'NONE')"
                v-model="params.age"
              >
                <el-checkbox-button
                  label="NONE"
                  @change="(e) => handleCheckout(e, params.age, 6, 'NONE')"
                  >不限</el-checkbox-button
                >
                <el-checkbox-button label="AGE_BETWEEN_18_23"
                  >18-23</el-checkbox-button
                >
                <el-checkbox-button label="AGE_BETWEEN_24_30"
                  >24-30</el-checkbox-button
                >
                <el-checkbox-button label="AGE_BETWEEN_31_40"
                  >31-40</el-checkbox-button
                >
                <el-checkbox-button label="AGE_BETWEEN_41_49"
                  >41-49</el-checkbox-button
                >
                <el-checkbox-button label="AGE_ABOVE_50"
                  >50+</el-checkbox-button
                >
              </el-checkbox-group>
            </div>
            <div class="per-part flex-row-start-start">
              <span style="margin-top: 10px">自定义人群</span>
              <div>
                <el-radio-group v-model="isCustomCrowd">
                  <el-radio-button label="NONE">不限</el-radio-button>
                  <el-radio-button label="CUSTOM">自定义</el-radio-button>
                </el-radio-group>
                <div
                  style="
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                  "
                  v-if="isCustomCrowd === 'CUSTOM'"
                >
                  <dy-table
                    :dataList="customAudienceList"
                    class="audience-table"
                    :rowList="audienceRowList"
                    :border="true"
                    height="300px"
                  >
                    <template #operation="{ row }">
                      <el-button
                        type="text"
                        size="mini"
                        class="button-mini"
                        @click="operationAudienceCrowd('include', row)"
                        >定向</el-button
                      >
                      <el-button
                        type="text"
                        size="mini"
                        class="button-mini"
                        @click="operationAudienceCrowd('exclude', row)"
                        >排除</el-button
                      >
                    </template>
                  </dy-table>
                  <div class="crowd-select">
                    <div class="flex-row-between-center">
                      <span>已选</span>
                      <span
                        style="
                          font-weight: bold;
                          color: #00bf8a;
                          cursor: pointer;
                        "
                        @click="clearAudience"
                        >清空</span
                      >
                    </div>
                    <div class="flex-row-start-start" style="margin-top: 20px">
                      <span
                        style="margin-right: 20px; padding-bottom: 10px"
                        @click="retargetingTagsType = 'include'"
                        :class="{
                          'is-selected': retargetingTagsType === 'include',
                        }"
                        >定向({{ params.retargetingTagsInclude.length }})</span
                      >
                      <span
                        style="padding-bottom: 10px"
                        @click="retargetingTagsType = 'exclude'"
                        :class="{
                          'is-selected': retargetingTagsType === 'exclude',
                        }"
                        >排除({{ params.retargetingTagsExclude.length }})</span
                      >
                    </div>
                    <div class="crowd-list">
                      <template v-if="retargetingTagsType === 'include'">
                        <p
                          v-for="(item, index) in params.retargetingTagsInclude"
                          :key="item.custom_audience_id"
                        >
                          <el-tag
                            closable
                            @close="handleAudience('include', index)"
                          >
                            {{ item.name }}
                          </el-tag>
                        </p>
                      </template>
                      <template v-else>
                        <p
                          v-for="(item, index) in params.retargetingTagsExclude"
                          :key="item.custom_audience_id"
                        >
                          <el-tag
                            closable
                            @close="handleAudience('exclude', index)"
                          >
                            {{ item.name }}
                          </el-tag>
                        </p>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="per-part flex-row-start-center">
              <span>兴趣行为</span>
              <el-radio-group v-model="params.interestActionMode">
                <el-radio-button label="UNLIMITED">不限</el-radio-button>
                <el-radio-button label="RECOMMEND">系统推荐</el-radio-button>
              </el-radio-group>
            </div>
            <!-- <div class="per-part flex-row-start-center">
              <span>抖音达人</span>
              <el-radio-group v-model="tikTok">
                <el-radio-button label="NONE">不限</el-radio-button>
              </el-radio-group>
            </div> -->
            <div class="per-part flex-row-start-center">
              <span>平台</span>
              <el-checkbox-group
                v-model="params.platform"
                @change="handleCheckout('out', params.platform, 4, 'NONE')"
              >
                <el-checkbox-button
                  @change="(e) => handleCheckout(e, params.platform, 4, 'NONE')"
                  label="NONE"
                  >不限</el-checkbox-button
                >
                <el-checkbox-button
                  :disabled="params.platform.includes('PC')"
                  label="ANDROID"
                  >安卓</el-checkbox-button
                >
                <el-checkbox-button
                  :disabled="params.platform.includes('PC')"
                  label="IOS"
                  >苹果</el-checkbox-button
                >
                <el-checkbox-button label="PC">PC</el-checkbox-button>
              </el-checkbox-group>
            </div>
            <div class="per-part flex-row-start-center">
              <span>网络</span>
              <el-checkbox-group
                v-model="params.ac"
                @change="handleCheckout('out', params.ac, 5, 'NONE')"
              >
                <el-checkbox-button
                  @change="(e) => handleCheckout(e, params.ac, 5, 'NONE')"
                  label="NONE"
                  >不限</el-checkbox-button
                >
                <el-checkbox-button label="WIFI">WiFi</el-checkbox-button>
                <el-checkbox-button label="2G">2G</el-checkbox-button>
                <el-checkbox-button label="3G">3G</el-checkbox-button>
                <el-checkbox-button label="4G">4G</el-checkbox-button>
              </el-checkbox-group>
            </div>
            <div class="per-part flex-row-start-center">
              <span>过滤已转化用户</span>
              <el-radio-group v-model="params.hideIfConverted">
                <el-radio-button label="NO_EXCLUDE">不过滤</el-radio-button>
                <el-radio-button label="AD">广告计划</el-radio-button>
                <el-radio-button label="CAMPAIGN">广告主</el-radio-button>
                <el-radio-button label="ADVERTISER">广告账户</el-radio-button>
                <el-radio-button label="APP">APP</el-radio-button>
                <el-radio-button label="CUSTOMER">公司账户</el-radio-button>
              </el-radio-group>
            </div>
            <div class="per-part flex-row-start-center">
              <span>已安装用户</span>
              <el-radio-group v-model="params.hideIfExists">
                <el-radio-button :label="0">不限</el-radio-button>
                <el-radio-button :label="1">过滤</el-radio-button>
                <el-radio-button :label="2">定向</el-radio-button>
              </el-radio-group>
            </div>
            <div class="per-part flex-row-start-start">
              <span style="margin-top: 10px">文章分类</span>
              <div>
                <el-radio-group v-model="articleType">
                  <el-radio-button label="NONE">不限</el-radio-button>
                  <el-radio-button label="CUSTOM">自定义</el-radio-button>
                </el-radio-group>
                <div
                  class="flex-row-start-start"
                  v-if="articleType === 'CUSTOM'"
                  style="margin-top: 10px"
                >
                  <div class="article-kinds">
                    <el-checkbox v-model="isCheckAllArticle" @change="change"
                      >全选</el-checkbox
                    >
                    <div class="tree-container">
                      <p v-for="(item, index) in articleList" :key="item.value">
                        <label
                          @click="handleArticleChange(item, index, 'article')"
                        >
                          <i
                            v-if="!item.checked"
                            class="iconfont icon-xuanze"
                          ></i>
                          <i
                            v-else
                            class="iconfont icon-xuanze2-copy"
                            style="color: #00bf8a"
                          ></i>
                          <span
                            :style="{
                              color: item.checked ? '#00bf8a' : 'inherit',
                            }"
                            >{{ item.label }}</span
                          >
                        </label>
                      </p>
                    </div>
                  </div>
                  <div class="article-selected">
                    <div class="flex-row-between-center title-info">
                      <span>已选</span>
                      <span
                        style="
                          font-weight: bold;
                          color: #00bf8a;
                          cursor: pointer;
                        "
                        @click="clearArticle"
                        >清空</span
                      >
                    </div>
                    <div class="article-selected-container">
                      <p
                        v-for="(item, index) in articleList.filter(
                          (item) => item.checked
                        )"
                        :key="item.value"
                      >
                        <el-tag
                          closable
                          @close="handleArticleCancel(item, index, 'article')"
                          >{{ item.label }}</el-tag
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="per-part flex-row-start-center">
              <span>运营商</span>
              <el-checkbox-group
                @change="handleCheckout('out', params.carrier, 4, 'NONE')"
                v-model="params.carrier"
              >
                <el-checkbox-button
                  @change="(e) => handleCheckout(e, params.carrier, 4, 'NONE')"
                  label="NONE"
                  >不限</el-checkbox-button
                >
                <el-checkbox-button label="MOBILE">移动</el-checkbox-button>
                <el-checkbox-button label="UNICOM">联通</el-checkbox-button>
                <el-checkbox-button label="TELCOM">电信</el-checkbox-button>
              </el-checkbox-group>
            </div>
            <div class="per-part flex-row-start-center">
              <span>新用户</span>
              <el-checkbox-group
                @change="handleCheckout('out', params.activateType, 4, 'NONE')"
                v-model="params.activateType"
              >
                <el-checkbox-button
                  @change="
                    (e) => handleCheckout(e, params.activateType, 4, 'NONE')
                  "
                  label="NONE"
                  >不限</el-checkbox-button
                >
                <el-checkbox-button label="WITH_IN_A_MONTH"
                  >一个月以内</el-checkbox-button
                >
                <el-checkbox-button label="ONE_MONTH_2_THREE_MONTH"
                  >一个月到三个月</el-checkbox-button
                >
                <el-checkbox-button label="THREE_MONTH_EAILIER"
                  >三个月或更早</el-checkbox-button
                >
              </el-checkbox-group>
            </div>
            <div class="per-part flex-row-start-start">
              <span style="margin-top: 10px">手机品牌</span>
              <div>
                <el-radio-group v-model="chooseDeviceBrandType">
                  <el-radio-button label="NONE">不限</el-radio-button>
                  <el-radio-button label="CUSTOM">按品牌</el-radio-button>
                </el-radio-group>
                <div
                  class="flex-row-start-start"
                  v-if="chooseDeviceBrandType === 'CUSTOM'"
                  style="margin-top: 10px"
                >
                  <div class="article-kinds">
                    <el-checkbox
                      v-model="isCheckAllDeviceBound"
                      @change="
                        (val) => {
                          change(val, 'deviceBound');
                        }
                      "
                      >全选</el-checkbox
                    >
                    <div class="tree-container">
                      <p v-for="(item, index) in deviceBrand" :key="item.value">
                        <label
                          @click="
                            handleArticleChange(item, index, 'deviceBound')
                          "
                        >
                          <i
                            v-if="!item.checked"
                            class="iconfont icon-xuanze"
                          ></i>
                          <i
                            v-else
                            class="iconfont icon-xuanze2-copy"
                            style="color: #00bf8a"
                          ></i>
                          <span
                            :style="{
                              color: item.checked ? '#00bf8a' : 'inherit',
                            }"
                            >{{ item.label }}</span
                          >
                        </label>
                      </p>
                    </div>
                  </div>
                  <div class="article-selected">
                    <div class="flex-row-between-center title-info">
                      <span>已选</span>
                      <span
                        style="
                          font-weight: bold;
                          color: #00bf8a;
                          cursor: pointer;
                        "
                        @click="clearArticle"
                        >清空</span
                      >
                    </div>
                    <div class="article-selected-container">
                      <p
                        v-for="(item, index) in deviceBrand.filter(
                          (item) => item.checked
                        )"
                        :key="item.value"
                      >
                        <el-tag
                          closable
                          @close="
                            handleArticleCancel(item, index, 'deviceBound')
                          "
                          >{{ item.label }}</el-tag
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="per-part flex-row-start-center">
              <span>手机价格</span>
              <el-radio-group v-model="params.launchPrice">
                <el-radio-button label="NONE">不限</el-radio-button>
                <el-radio-button label="CUSTOM">自定义</el-radio-button>
              </el-radio-group>
            </div>
            <div
              class="per-part flex-row-start-center"
              v-if="params.launchPrice === 'CUSTOM'"
            >
              <span></span>
              <div class="price-container">
                <priceSlider
                  :price="phonePrice"
                  :width="500"
                  @change="handlePriceChange"
                />
              </div>
            </div>
            <div
              class="per-part flex-row-start-center"
              style="margin-bottom: 10px"
            >
              <span>智能放量</span>
              <el-radio-group v-model="params.autoExtendEnabled">
                <el-radio-button :label="0">不启用</el-radio-button>
                <el-radio-button :label="1">启用</el-radio-button>
              </el-radio-group>
            </div>
            <div
              class="per-part flex-row-start-center"
              v-if="params.autoExtendEnabled === 1"
              style="margin-bottom: 0"
            >
              <span></span>
              <div class="ai-largesse">
                <div class="title-info">
                  <span>可放开定向</span>
                  <span
                    style="font-weight: bold; color: #00bf8a; cursor: pointer"
                    @click="params.autoExtendTargets = []"
                    >清空</span
                  >
                </div>
                <el-checkbox-group v-model="params.autoExtendTargets">
                  <el-checkbox label="AGE">年龄</el-checkbox>
                  <el-checkbox label="REGION">地域</el-checkbox>
                  <el-checkbox label="GENDER">性别</el-checkbox>
                  <el-checkbox label="INTEREST_ACTION">行为兴趣</el-checkbox>
                  <el-checkbox label="CUSTOM_AUDIENCE"
                    >自定人群-定向</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="button-list">
        <el-button type="primary" @click="handleSubmit">确定</el-button>
        <el-button @click="showAddDialog = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import priceSlider from "../components/priceSlider";
import {
  getAudience,
  getRegion,
  getCustomCrowd,
  addAudience,
  deleteAudience,
  editAudience,
} from "@/api/toutiao.js";
import { articleList, deviceBrand } from "@/views/small-plane/localData.js";
import axios from "axios";
export default {
  data() {
    return {
      page: 1,
      total: 0,
      isEdit: false,
      editId: null,
      search: "",
      type: null,
      options: [], // 商圈相关信息
      articleList,
      deviceBrand,
      cityList: [], // 城市数据
      dataList: [],
      pageSize: 15,
      tikTok: "NONE", // 抖音达人
      loading: false,
      filterText: "",
      copyOptions: [], // 商圈相关信息副本
      copyCityList: [], // 城市数据副本
      isCheckAll: false, // 地域是否勾选全选
      showDialog: false,
      chooseCityList: [],
      articleKeyWords: "",
      articleType: "NONE", // 文章分类
      showAddDialog: false,
      isCustomCrowd: "NONE", // 自定义人群的类型
      customAudienceList: [], //  人群包数据
      isCheckAllArticle: false, // 是否全选文章类型
      isCheckAllDeviceBound: false, // 是否全选手机品牌
      chooseDeviceBrandType: "NONE",
      retargetingTagsType: "include", //
      tmpData: {},
      packList: [],
      accountList: [],
      tagsIncludeString: [],
      tagsExcludeString: [],
      echoCityData: [],
      // 定向包参数
      params: {
        career: [], // 职业选项
        ac: ["NONE"],
        age: ["NONE"],
        gender: "NONE",
        hideIfExists: 0,
        district: "NONE", // 地域
        carrier: ["NONE"], //  运营商
        platform: ["NONE"], // 平台
        launchPrice: "NONE", //手机价格
        locationType: "ALL", //位置类型
        autoExtendEnabled: 0, // 智能放量
        autoExtendTargets: [], // 智能放量
        activateType: ["NONE"], // 新用户
        retargetingTagsExclude: [], // 定向人群
        retargetingTagsInclude: [], // 排除人群
        hideIfConverted: "AD", // 已过滤转化用户
        interestActionMode: "UNLIMITED", // 兴趣行为
      },
      regionParams: {
        CITY: {
          cityList: [],
          chooseList: [],
          isCheckAll: false,
        },
        COUNTY: {
          cityList: [],
          chooseList: [],
          isCheckAll: false,
        },
        BUSINESS_DISTRICT: {
          cityList: [],
          chooseList: [],
          isCheckAll: false,
        },
      },
      audienceRowList: [
        {
          label: "名称",
          prop: "name",
          popover: true,
        },
        {
          label: "覆盖人数",
          prop: "cover_num",
          popover: true,
        },
        {
          label: "属性",
          prop: "tag",
        },
        {
          label: "操作",
          action: "operation",
          width: "150",
        },
      ],
      chooseAccountList: [
        {
          name: "乐佳奇-T1-40",
        },
        {
          name: "乐佳奇-T1-37",
        },
        {
          name: "乐佳奇-T1-36",
        },
        {
          name: "乐佳奇-T1-50",
        },
      ],
      rowList: [
        {
          label: "id",
          prop: "id",
        },
        {
          label: "定向包名称",
          prop: "name",
        },
        {
          label: "定向包描述",
          prop: "description",
        },
        {
          label: "平台",
          prop: "platform",
        },
        {
          label: "操作",
          action: "operation",
        },
      ],
      phonePrice: null,
      rowclickFlag: false,
      selectedData: [],
    };
  },
  watch: {
    filterText(val) {
      this.$refs.cityTree.filter(val);
    },
    isCustomCrowd: {
      handler(newV) {
        if (newV === "CUSTOM") {
          this.customCrowd();
        }
      },
    },
  },
  inject: ["templateDetial"],
  computed: {
    checkedTreeNodes() {
      let type = this.params.district;
      return this.regionParams[type].chooseList.map((item) => {
        return item.id;
      });
    },
  },
  methods: {
    handleSelected(item) {
      this.handleSelectionChange(item, true);
    },
    handleSelectionChange(item, flag) {
      if (!this.rowclickFlag && !flag) {
        return;
      }
      let tmpSelected = this.selectedData.map((item) => {
        return {
          ...item,
        };
      });
      let tmpSelectedId = this.selectedData.map((item) => {
        return item.id;
      });
      let tmpItemId = item.map((item) => {
        return item.id;
      });
      let tmptableId = this.dataList.map((item) => {
        return item.id;
      });
      // 取消勾选
      tmpSelected.forEach((item2, index2) => {
        if (!tmpItemId.includes(item2.id) && tmptableId.includes(item2.id)) {
          this.selectedData.splice(index2, 1);
        }
      });
      // 存入勾选
      item.forEach((item3) => {
        if (!tmpSelectedId.includes(item3.id)) {
          this.selectedData.push({
            ...item3,
          });
        }
      });
      this.rowclickFlag = false;
    },
    hanleCheckAll(value) {
      if (value) {
        let type = this.params.district;
        const ids = this.regionParams[type].cityList.map((item) => item.id);
        this.$refs.cityTree.setCheckedKeys(ids);
        this.$nextTick(() => {
          const nodes = this.$refs.cityTree.getCheckedNodes();
          if (type !== "BUSINESS_DISTRICT") {
            const firstLevelNodes = nodes.filter((item) => +item.level === 1);
            this.regionParams[type].chooseList = firstLevelNodes;
          } else {
            // ! 防止重复数据添加
            nodes.forEach((node) => {
              const val = this.regionParams[type].chooseList.find(
                (key) => key.id === node.id
              );
              if (!val) {
                this.regionParams[type].chooseList.push(node);
              }
            });
          }
        });
      } else {
        let type = this.params.district;
        this.$refs.cityTree.setCheckedKeys([]);
        if (type !== "BUSINESS_DISTRICT") {
          this.regionParams[type].chooseList = [];
        } else {
          const nodes = this.regionParams[type].cityList;
          nodes.forEach((item) => {
            const id = item.id;
            const targetIndex = this.regionParams[type].chooseList.findIndex(
              (val) => val.id === id
            );
            if (targetIndex > -1) {
              this.regionParams[type].chooseList.splice(targetIndex, 1);
            }
          });
        }
      }
    },
    handleInitRegion(type) {
      this.isCheckAll = false;
      const excludeCityIds = [50, 11, 12, 31];
      // if (type === "CITY") {
      const arrCity = JSON.parse(JSON.stringify(this.copyCityList));
      arrCity.forEach((item) => {
        if (excludeCityIds.includes(item.id)) {
          item.children = [];
        } else {
          for (let j = 0; j < item.children.length; j++) {
            const val = item.children[j];
            val.children = [];
          }
        }
      });
      this.regionParams["CITY"].cityList = arrCity;

      // }
      // if (type === "COUNTY") {
      const arrCounty = JSON.parse(JSON.stringify(this.copyCityList));
      arrCounty.forEach((item) => {
        item.disabled = true;
        if (!excludeCityIds.includes(item.id)) {
          const children = item.children;
          if (children.length > 0) {
            for (let j = 0; j < children.length; j++) {
              children[j].disabled = true;
            }
          }
        }
      });
      this.regionParams["COUNTY"].cityList = arrCounty;
      // }
      // 商圈
      // if (type === "BUSINESS_DISTRICT") {
      getRegion({
        regionType: "BUSINESS_DISTRICT",
      }).then((res) => {
        this.copyOptions = res;
        const arrBusiness = JSON.parse(JSON.stringify(this.copyOptions));
        arrBusiness.forEach((city) => {
          city.children.forEach((county) => {
            delete county.children;
          });
        });
        this.options = arrBusiness;
        // 回显商圈
        if (this.params.test && this.params.test.length > 0) {
          let tmpCityId = [];
          res.forEach((item) => {
            item.children.forEach((item2) => {
              item2.children.forEach((item3) => {
                if (this.params.test.includes(item3.id + "")) {
                  tmpCityId.push({
                    ...item3,
                  });
                }
              });
            });
          });
          this.chooseCityList = tmpCityId;
          this.regionParams["BUSINESS_DISTRICT"].chooseList = tmpCityId;
          this.regionParams["BUSINESS_DISTRICT"].cityList = [];
        }
        // this.handleCascaderChange(this.params.test);
      });
      // }
    },
    handlePriceChange(data) {
      this.phonePrice = data;
    },
    handleRowClick(row, column, event) {
      this.rowclickFlag = true;
      this.$refs.orientationTable.$refs.dyTable.toggleRowSelection(row);
    },
    handleConfirm() {
      this.packList = [];
      this.params = {
        career: [], // 职业选项
        ac: ["NONE"],
        age: ["NONE"],
        gender: "NONE",
        hideIfExists: 0,
        district: "NONE", // 地域
        carrier: ["NONE"], //  运营商
        platform: ["NONE"], // 平台
        launchPrice: "NONE", //手机价格
        locationType: "ALL", //位置类型
        autoExtendEnabled: 0, // 智能放量
        autoExtendTargets: [], // 智能放量
        activateType: ["NONE"], // 新用户
        retargetingTagsExclude: [], // 定向人群
        retargetingTagsInclude: [], // 排除人群
        hideIfConverted: "AD", // 已过滤转化用户
        interestActionMode: "UNLIMITED", // 兴趣行为
      };
      this.selectedData.forEach((item) => {
        this.packList.push({
          id: item.id,
          name: item.name,
        });
      });
      this.tmpData.audiences = this.packList;
      this.showDialog = false;
    },
    handleDeletePack(index) {
      this.packList.splice(index, 1);
    },
    handleSelectPack(data) {
      // this.tmpPackList = data;
    },
    handleDelete(row) {
      this.$confirm("确定要删除此条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteAudience(row.row.id).then((res) => {
          this.$message.success("删除成功");
          this.initData();
        });
      });
    },
    handleEditPack(row) {
      this.regionParams = {
        CITY: {
          cityList: [],
          chooseList: [],
          isCheckAll: false,
        },
        COUNTY: {
          cityList: [],
          chooseList: [],
          isCheckAll: false,
        },
        BUSINESS_DISTRICT: {
          cityList: [],
          chooseList: [],
          isCheckAll: false,
        },
      };
      this.isEdit = true;
      this.editId = row.id;
      this.showAddDialog = true;
      this.params = {
        ...row,
      };
      if (row.autoExtendTargets) {
        this.$set(
          this.params,
          "autoExtendTargets",
          row.autoExtendTargets.split(",")
        );
      } else {
        this.$set(this.params, "autoExtendTargets", []);
      }
      if (row.age) {
        this.$set(this.params, "age", row.age.split(","));
      }
      // 自定义人群
      if (row.retargetingTagsInclude || row.retargetingTagsExclude) {
        this.isCustomCrowd = "CUSTOM";
        this.tagsIncludeString = row.retargetingTagsInclude
          ? row.retargetingTagsInclude.split(",")
          : [];
        this.tagsExcludeString = row.retargetingTagsExclude
          ? row.retargetingTagsExclude.split(",")
          : [];
        this.params.retargetingTagsInclude = [];
        this.params.retargetingTagsExclude = [];
      } else {
        this.isCustomCrowd = "NONE";
        this.tagsIncludeString = [];
        this.tagsExcludeString = [];
        this.params.retargetingTagsInclude = [];
        this.params.retargetingTagsExclude = [];
      }
      // 平台
      if (row.platform) {
        this.$set(this.params, "platform", row.platform.split(","));
      }
      //网络
      if (row.ac) {
        this.$set(this.params, "ac", row.ac.split(","));
      }
      // 运营商
      if (row.carrier) {
        this.$set(this.params, "carrier", row.carrier.split(","));
      }
      // 新用户
      if (row.activateType) {
        this.$set(this.params, "activateType", row.activateType.split(","));
      }
      // 文章分类
      if (row.articleCategory !== "NONE") {
        this.articleType = "CUSTOM";
        let tmparticle = row.articleCategory.split(",");
        this.$set(this.params, "articleCategory", tmparticle);
        tmparticle.forEach((item) => {
          this.articleList.forEach((item2) => {
            if (item2.value == item) {
              return (item2.checked = true);
            }
          });
        });
      } else {
        this.articleType = "NONE";
      }
      // 手机品牌
      if (row.deviceBrand != "NONE" && row.deviceBrand != "") {
        this.chooseDeviceBrandType = "CUSTOM";
        let tmpdeviceBrand = row.deviceBrand.split(",");
        this.$set(this.params, "deviceBrand", tmpdeviceBrand);
        tmpdeviceBrand.forEach((item) => {
          this.deviceBrand.forEach((item2) => {
            if (item2.value == item) {
              return (item2.checked = true);
            }
          });
        });
      } else {
        this.chooseDeviceBrandType = "NONE";
      }
      // 手机价格
      if (row.launchPrice != "NONE") {
        this.phonePrice = row.launchPrice.split(",");
        this.params.launchPrice = "CUSTOM";
      } else {
        this.phonePrice = [0, 6000];
      }
      // 回显city
      if (row.city) {
        // let tmpcity = row.city.split(",");
        this.regionParams[row.district].echoData = row.city.split(",");
        // this.echoCityData = row.city.split(",");
      }
      // 回显商圈
      if (row.businessIds) {
        this.$set(this.params, "test", row.businessIds.split(","));
        // this.handleCascaderChange(this.params.test);
      }
      setTimeout(() => {
        this.handleReductionCity();
      }, 500);
      // 初始化地域
      this.handleInitRegion(row.district);
    },
    handleUpdateData(type) {
      this.tmpData = this.templateDetial();
      if (type === "clear") {
        this.tmpData.channels = [];
        this.tmpData.audiences = [];
      }
      this.accountList = this.tmpData.channels;
      this.packList = this.tmpData.audiences;
    },
    // 用于多选checkbox排他
    handleCheckout(value, arr, length, first) {
      if (value === true) {
        arr.splice(0, length);
        arr.push(first);
        return;
      }
      if (value === "out") {
        if (
          arr.includes(first) &&
          arr.length > 1 &&
          arr.length !== length - 1
        ) {
          let index = arr.findIndex((item) => item === first);
          arr.splice(index, 1);
        }
        if (arr.length === length - 1) {
          arr.splice(0, length);
          arr.push(first);
        }
      }
      if (arr.includes("PC")) {
        arr = ["PC"];
      }
    },
    initData() {
      getAudience({
        page: this.page,
        pageSize: this.pageSize,
        search: this.search,
      })
        .then((res) => {
          this.total = res.total;
          this.dataList = res.list;
          let tmpseleted = [];
          // 回显已勾选项
          setTimeout(() => {
            this.dataList.forEach((item1, index1) => {
              this.selectedData.forEach((item2) => {
                if (item1.id === item2.id) {
                  tmpseleted.push(index1);
                }
              });
            });
            tmpseleted.forEach((item) => {
              this.$refs.orientationTable.$refs.dyTable.toggleRowSelection(
                this.dataList[item],
                true
              );
            });
          }, 200);
          if (this.regionParams["CITY"].cityList.length) {
            return;
          }
          axios.get("/customCity.json").then((res) => {
            // this.regionParams["CITY"].cityList = JSON.parse(
            //   JSON.stringify(res.data)
            // );
            // this.regionParams["COUNTY"].cityList = JSON.parse(
            //   JSON.stringify(res.data)
            // );
            this.copyCityList = JSON.parse(JSON.stringify(res.data));
          });
        })
        .finally(() => {});
    },
    editItem() {
      this.showDialog = true;
      this.selectedData = [];
      this.selectedData = this.packList.map((item) => {
        return {
          ...item,
        };
      });
      this.initData();
    },
    handleClose(type) {
      this.params = {
        career: [], // 职业选项
        ac: ["NONE"],
        age: ["NONE"],
        gender: "NONE",
        hideIfExists: 0,
        district: "NONE", // 地域
        carrier: ["NONE"], //  运营商
        platform: ["NONE"], // 平台
        launchPrice: "NONE", //手机价格
        locationType: "ALL", //位置类型
        autoExtendEnabled: 0, // 智能放量
        autoExtendTargets: [], // 智能放量
        activateType: ["NONE"], // 新用户
        retargetingTagsExclude: [], // 定向人群
        retargetingTagsInclude: [], // 排除人群
        hideIfConverted: "AD", // 已过滤转化用户
        interestActionMode: "UNLIMITED", // 兴趣行为
      };
      if (type === "add") {
        this.showAddDialog = false;
      } else {
        this.showDialog = false;
      }
    },
    addPackage() {
      this.isEdit = false;
      this.params = {
        career: [], // 职业选项
        ac: ["NONE"],
        age: ["NONE"],
        gender: "NONE",
        hideIfExists: 0,
        district: "NONE", // 地域
        carrier: ["NONE"], //  运营商
        platform: ["NONE"], // 平台
        launchPrice: "NONE", //手机价格
        locationType: "ALL", //位置类型
        autoExtendEnabled: 0, // 智能放量
        autoExtendTargets: [], // 智能放量
        activateType: ["NONE"], // 新用户
        retargetingTagsExclude: [], // 定向人群
        retargetingTagsInclude: [], // 排除人群
        hideIfConverted: "AD", // 已过滤转化用户
        interestActionMode: "UNLIMITED", // 兴趣行为
      };
      this.regionParams = {
        CITY: {
          cityList: [],
          chooseList: [],
          isCheckAll: false,
          echoData: [],
        },
        COUNTY: {
          cityList: [],
          chooseList: [],
          isCheckAll: false,
          echoData: [],
        },
        BUSINESS_DISTRICT: {
          cityList: [],
          chooseList: [],
          isCheckAll: false,
          echoData: [],
        },
      };
      this.phonePrice = [0, 6000];
      this.showAddDialog = true;
      this.handleInitRegion();
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 回显还原城市
    handleReductionCity() {
      if (!this.$refs.cityTree) {
        return;
      }
      let checkedNodes = this.$refs.cityTree.getCheckedNodes();
      this.regionParams[this.params.district].chooseList = [];
      checkedNodes.forEach((item) => {
        this.regionParams[this.params.district].chooseList.push({ ...item });
      });
    },
    handleSubmit() {
      let type = this.params.district;
      if (!this.params.name) {
        return this.$message.error("请输入定向包名");
      }
      // 地域选择
      if (
        this.params.district !== "NONE" &&
        this.regionParams[type].chooseList.length === 0
      ) {
        return this.$message.error("请勾选地域");
      }
      if (this.params.district === "NONE") {
        this.params.city = [];
        this.params.businessIds = [];
      } else if (this.params.district === "CITY") {
        //城市
        let tmpCity = [];
        this.regionParams[type].chooseList.forEach((item) => {
          if (item.children.length > 0) {
            item.children.forEach((item2) => {
              tmpCity.push(item2.id);
            });
          } else {
            tmpCity.push(item.id);
          }
        });
        this.params.city = tmpCity;
        this.params.businessIds = [];
      } else if (this.params.district === "COUNTY") {
        //区县
        let tmpCity = [];
        this.regionParams[type].chooseList.forEach((item) => {
          tmpCity.push(item.id);
        });
        this.params.city = tmpCity;
        this.params.businessIds = [];
      } else {
        //商圈
        let businessIds = [];
        this.regionParams[type].chooseList.forEach((item) => {
          businessIds.push(item.id);
        });
        this.params.city = [];
        this.params.businessIds = businessIds;
      }
      // return;
      // 位置类型用户
      if (this.params.district !== "NONE" && !this.params.locationType) {
        return this.$message.error("请选择位置类型用户");
      }
      // 自定义人群
      if (this.isCustomCrowd === "NONE") {
        this.params.retargetingTagsInclude = [];
        this.params.retargetingTagsExclude = [];
      } else {
        if (
          this.params.retargetingTagsInclude.length === 0 &&
          this.params.retargetingTagsExclude.length === 0
        ) {
          return this.$message.error("请选择自定义人群");
        }
        let tmpInclude = this.params.retargetingTagsInclude.map(
          (item) => item.custom_audience_id
        );
        let tmpExclude = this.params.retargetingTagsExclude.map(
          (item) => item.custom_audience_id
        );
        this.tagsIncludeString = tmpInclude;
        this.tagsExcludeString = tmpExclude;
      }
      // 文章分类
      if (this.articleType !== "NONE") {
        if (!this.articleList.some((item) => item.checked)) {
          return this.$message.error("请选择文章分类项");
        } else {
          let tmpAricle = [];
          this.articleList.forEach((item) => {
            if (item.checked) {
              tmpAricle.push(item.value);
            }
          });
          this.params.articleCategory = tmpAricle;
        }
      } else {
        this.params.articleCategory = ["NONE"];
      }
      // 手机品牌
      if (this.chooseDeviceBrandType != "NONE") {
        if (!this.deviceBrand.some((item) => item.checked)) {
          return this.$message.error("请选择手机品牌");
        } else {
          let tmpBrand = [];
          this.deviceBrand.forEach((item) => {
            if (item.checked) {
              tmpBrand.push(item.value);
            }
          });
          this.params.deviceBrand = tmpBrand;
        }
      } else {
        this.params.deviceBrand = ["NONE"];
      }
      // 平台和手机品牌是否冲突
      if (
        this.params.deviceBrand[0] !== "NONE" &&
        this.params.platform[0] !== "NONE"
      ) {
        if (this.params.deviceBrand.length) {
          if (!this.params.platform.includes("ANDROID")) {
            return this.$message.error("选择手机品牌后平台必须选中android");
          }
        }
      }
      if (this.params.launchPrice != "NONE") {
        if (!this.phonePrice.length) {
          return this.$message.error("请选择手机价格");
        }
      }
      let tmpParams = {
        name: this.params.name,
        description: this.params.description,
        district: this.params.district,
        // 地域
        city: this.params.city.length ? this.params.city.join(",") : null,
        businessIds: this.params.businessIds.length
          ? this.params.businessIds.join(",")
          : null,
        gender: this.params.gender,
        // 人群
        retargetingTagsInclude: this.tagsIncludeString.length
          ? this.tagsIncludeString.join(",")
          : null,
        retargetingTagsExclude: this.tagsExcludeString.length
          ? this.tagsExcludeString.join(",")
          : null,
        locationType: this.params.locationType,
        age: this.params.age.join(","),
        interestActionMode: this.params.interestActionMode,
        platform: this.params.platform.join(","),
        ac: this.params.ac.join(","),
        hideIfConverted: this.params.hideIfConverted,
        hideIfExists: this.params.hideIfExists,
        articleCategory: this.params.articleCategory.join(","),
        carrier: this.params.carrier.join(","),
        activateType: this.params.activateType.join(","),
        deviceBrand: this.params.deviceBrand.join(","),
        launchPrice:
          this.params.launchPrice != "NONE"
            ? this.phonePrice.join(",")
            : "NONE",
        autoExtendEnabled: this.params.autoExtendEnabled,
        autoExtendTargets: this.params.autoExtendEnabled
          ? this.params.autoExtendTargets.join(",")
          : "",
      };
      if (this.isEdit) {
        editAudience(this.editId, tmpParams)
          .then((res) => {
            this.$message.success("修改成功");
            this.showAddDialog = false;
            this.initData();
          })
          .finally(() => {});
      } else {
        addAudience(tmpParams)
          .then((res) => {
            this.$message.success("新增成功");
            this.showAddDialog = false;
            this.initData();
          })
          .finally(() => {});
      }
    },
    handleCancel(cb) {
      cb();
      this.handleClose("add");
    },
    handleCheckChange(curNodeData, allNodeData) {
      let type = this.params.district;
      const { checkedKeys, checkedNodes, halfCheckedKeys, halfCheckedNodes } =
        allNodeData;
      let tmpCurNodeDataId = curNodeData.id + "";
      // 取消父元素时取消所有子元素
      if (
        tmpCurNodeDataId.length == 2 &&
        !checkedKeys.includes(curNodeData.id)
      ) {
        let tmpchoose = this.regionParams[type].chooseList.map((item) => {
          return {
            ...item,
          };
        });
        this.regionParams[type].chooseList.forEach((item) => {
          let tmpId = item.id + "";
          if (tmpId.slice(0, 2) == curNodeData.id) {
            let tmpIndex = tmpchoose.findIndex((item2) => {
              return item2.id == item.id;
            });
            if (tmpIndex !== -1) {
              tmpchoose.splice(tmpIndex, 1);
            }
          }
        });
        this.regionParams[type].chooseList = tmpchoose;
      }
      const { id } = curNodeData;
      if (checkedKeys.find((item) => item === id)) {
        this.regionParams[type].chooseList.push(curNodeData);
      } else {
        const targetIndex = this.regionParams[type].chooseList.findIndex(
          (item) => item.id === id
        );
        if (targetIndex > -1) {
          this.regionParams[type].chooseList.splice(targetIndex, 1);
        }
      }
      // * 去除半选状态的node
      if (halfCheckedKeys.length > 0) {
        for (let i = 0; i < halfCheckedKeys.length; i++) {
          const key = halfCheckedKeys[i];
          const index = this.regionParams[type].chooseList.findIndex(
            (item) => item.id === key
          );
          if (index > -1) {
            this.regionParams[type].chooseList.splice(index, 1);
          }
        }
      }
      // * 从半选状态中找到选中的节点
      halfCheckedNodes.forEach((node) => {
        if (node.children.length > 0) {
          const firstLevelChildren = node.children;
          firstLevelChildren.forEach((subNode) => {
            const id = subNode.id;
            const checkNode = checkedNodes.find((n) => n.id === id);
            if (checkNode) {
              if (
                !this.regionParams[type].chooseList.find(
                  (item) => item.id === checkNode.id
                )
              ) {
                this.regionParams[type].chooseList.push(subNode);
              }
            }
          });
        }
      });

      //*  判断有子节点的数据的node节点子节点是否全被选中 （全被选中就要合并字段)

      const containsChildrenNodeList = checkedNodes.filter(
        (item) => Array.isArray(item.children) && item.children.length > 0
      );
      containsChildrenNodeList.forEach((item, itemIndex) => {
        const children = item.children;
        if (
          children.every((child) =>
            checkedNodes.find((node) => node.id === child.id)
          )
        ) {
          children.forEach((child) => {
            const targetIndex = this.regionParams[type].chooseList.findIndex(
              (i) => i.id === child.id
            );
            if (targetIndex > -1) {
              this.regionParams[type].chooseList.splice(targetIndex, 1);
            }
          });

          // ! 收缩父级数据
          if (
            !this.regionParams[type].chooseList.find(
              (key) => key.id === item.id
            )
          ) {
            this.regionParams[type].chooseList.push(item);
          }
        }
      });
      // !再次合并数据
      for (let i = 0; i < this.regionParams[type].chooseList.length; i++) {
        const item = this.regionParams[type].chooseList[i];
        if (item.children && item.children.length > 0) {
          for (let j = 0; j < item.children.length; j++) {
            const val = item.children[j];
            const targetIndex = this.regionParams[type].chooseList.findIndex(
              (i) => i.id === val.id
            );
            if (targetIndex > -1) {
              this.regionParams[type].chooseList.splice(targetIndex, 1);
            }
          }
        }
      }
    },
    closeChooseCityItem(val) {
      this.$refs.cityTree.setChecked(val.id, false);
      const targetIndex = this.regionParams[
        this.params.district
      ].chooseList.findIndex((item) => item.id === val.id);
      this.regionParams[this.params.district].chooseList.splice(targetIndex, 1);
    },
    chooseAccount() {},
    handlePageChange(page) {
      this.page = page;
      this.initData();
    },
    handleCascaderChange(val) {
      if (val.length > 0) {
        const city = this.copyOptions.find((item) => item.id === val[0]);
        const county = city.children.find((item) => item.id === val[1]);
        this.regionParams["BUSINESS_DISTRICT"].cityList = county.children;
        // ! 已选择的商圈
        const selectedBusinessDistrict = this.regionParams[
          "BUSINESS_DISTRICT"
        ].chooseList.filter((item) =>
          this.regionParams["BUSINESS_DISTRICT"].cityList.find(
            (val) => val.id === item.id
          )
        );
        if (selectedBusinessDistrict.length > 0) {
          const ids = selectedBusinessDistrict.map((item) => item.id);
          this.$refs.cityTree.setCheckedKeys(ids);
        }
        if (
          selectedBusinessDistrict.length ===
          this.regionParams["BUSINESS_DISTRICT"].cityList.length
        ) {
          this.regionParams["BUSINESS_DISTRICT"].isCheckAll = true;
        }
      } else {
        this.regionParams["BUSINESS_DISTRICT"].cityList = [];
        this.regionParams["BUSINESS_DISTRICT"].isCheckAll = false;
      }
    },
    getCityTreeStyle() {
      return {
        height: this.params.district === "COUNTY" ? "230px" : "200px",
      };
    },
    customCrowd() {
      getCustomCrowd({
        page: 1,
        pageSize: 30,
        advertiserId: this.accountList[0].advertiserId,
      }).then((res) => {
        this.customAudienceList = res.custom_audience_list;
        // 还原定向
        let tmpinclude = [];
        let tmpexclude = [];
        if (this.tagsIncludeString.length !== 0) {
          this.tagsIncludeString.forEach((item) => {
            this.customAudienceList.forEach((item2) => {
              if (item2.custom_audience_id == item) {
                return tmpinclude.push(item2);
              }
            });
          });
        }
        // 还原排除
        if (this.tagsExcludeString.length !== 0) {
          this.tagsExcludeString.forEach((item) => {
            this.customAudienceList.forEach((item2) => {
              if (item2.custom_audience_id == item) {
                return tmpexclude.push(item2);
              }
            });
          });
        }
        this.$set(this.params, "retargetingTagsInclude", tmpinclude);
        this.$set(this.params, "retargetingTagsExclude", tmpexclude);
      });
    },
    operationAudienceCrowd(type, row) {
      if (type === "include") {
        if (
          this.params.retargetingTagsInclude.find(
            (item) => item.custom_audience_id === row.custom_audience_id
          )
        ) {
          return this.$message.warning("请勿重复添加定向人群!");
        }
        this.params.retargetingTagsInclude.push(row);
        const targetIndex = this.params.retargetingTagsExclude.findIndex(
          (item) => item.custom_audience_id === row.custom_audience_id
        );
        if (targetIndex > -1) {
          this.params.retargetingTagsExclude.splice(targetIndex, 1);
        }
      }
      if (type === "exclude") {
        if (
          this.params.retargetingTagsExclude.find(
            (item) => item.custom_audience_id === row.custom_audience_id
          )
        ) {
          return this.$message.warning("请勿重复添加排除人群!");
        }
        const targetIndex = this.params.retargetingTagsInclude.findIndex(
          (item) => item.custom_audience_id === row.custom_audience_id
        );
        if (targetIndex > -1) {
          this.params.retargetingTagsInclude.splice(targetIndex, 1);
        }
        this.params.retargetingTagsExclude.push(row);
      }
    },
    clearAudience() {
      this.retargetingTagsType === "include"
        ? (this.params.retargetingTagsInclude = [])
        : (this.params.retargetingTagsExclude = []);
    },
    handleAudience(type = "include", index) {
      if (type === "include") {
        this.params.retargetingTagsInclude.splice(index, 1);
      }
      if (type === "exclude") {
        this.params.retargetingTagsExclude.splice(index, 1);
      }
    },
    handleArticleChange(val, index, type) {
      val.checked = !val.checked;
      if (type === "article") {
        if (this.articleList.every((item) => item.checked)) {
          this.isCheckAllArticle = true;
        }
        if (this.articleList.some((item) => !item.checked)) {
          this.isCheckAllArticle = false;
        }
      }
      if (type === "deviceBound") {
        if (this.deviceBrand.every((item) => item.checked)) {
          this.isCheckAllDeviceBound = true;
        }
        if (this.deviceBrand.some((item) => !item.checked)) {
          this.isCheckAllDeviceBound = false;
        }
      }
    },
    handleArticleCancel(val, index, type) {
      val.checked = false;
      if (type === "deviceBound") {
        if (this.deviceBrand.some((item) => !item.checked)) {
          this.isCheckAllDeviceBound = false;
        }
        return;
      }
      if (type === "article") {
        if (this.articleList.some((item) => !item.checked)) {
          this.isCheckAllArticle = false;
        }
      }
    },
    clearArticle() {
      this.articleList.forEach((item) => {
        item.checked = false;
      });
      this.isCheckAllArticle = false;
    },
    change(val, type) {
      if (type === "deviceBound") {
        this.deviceBrand.forEach((item) => {
          item.checked = !!val;
        });
      } else {
        this.articleList.forEach((item) => {
          item.checked = !!val;
        });
      }
    },
    clearChooseCityList() {
      this.hanleCheckAll(false);
      let type = this.params.district;
      this.regionParams[type].chooseList = [];
      this.regionParams[type].isCheckAll = false;
      this.$refs.cityTree.setCheckedKeys([]);
    },
  },
  components: {
    priceSlider,
  },
  created() {
    this.tmpData = this.templateDetial();
    this.accountList = this.tmpData.channels;
  },
};
</script>
<style lang='scss' scoped>
h3 {
  font-size: 18px;
}
.per-part {
  margin-bottom: 20px;

  > span {
    width: 120px;
    text-align: right;
    margin-right: 10px;
  }
  .price-container {
    width: 540px;
  }
}
.choose-account {
  p {
    padding: 5px 10px;
    border: 1px solid #ccc;
    background: #f5f5f5;
    border-radius: 4px;
    font-size: 12px;
    margin-top: 10px;
  }
}
.button-list {
  padding-top: 20px;
  border-top: 1px solid #f5f5f5;
  padding-left: 130px;
}
.choose-tag {
  position: sticky;
  top: 0;
  width: 200px;
  margin-right: 20px;
  border: 1px solid #ecebeb;
  padding: 10px;
  .tag-list {
    > p {
      margin: 15px 0;
      > span:nth-of-type(1) {
        font-weight: bold;
      }
      .light-color {
        color: #ccc;
      }
    }
  }
}
.user-orientation {
  margin-top: 10px;
  padding-bottom: 30px;
  // width:800px;
  .region-select {
    .city-list {
      height: 300px;
      margin-top: 10px;
      border: 1px solid #f5f5f5;
      border-radius: 6px;
      padding: 10px;
      margin-right: 20px;
      .city-tree {
        margin-top: 10px;

        overflow: auto;
      }
    }
    .city-select {
      margin-top: 10px;
      border: 1px solid #f5f5f5;
      border-radius: 6px;
      padding: 10px;
      height: 300px;
      width: 300px;
      // display: flex;
      .title-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        width: 100%;
      }
      .city-select-list {
        overflow: auto;
        max-height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }
}
.audience-table {
  margin-top: 20px;
  border: 1px solid #f5f5f5 !important;
  /deep/ .filter-list {
    margin: 0 !important;
  }
}
/deep/ .el-table {
  margin-top: -1px;
  margin-left: -1px;
}
.crowd-select {
  width: 200px;
  margin-top: 20px;
  margin-left: 20px;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  padding: 0 10px;
  height: 300px;
  > div:nth-of-type(1) {
    height: 56px;
    line-height: 56px;
  }
  .is-selected {
    border-bottom: 1px solid #00bf8a;
    color: #00bf8a;
  }
  .crowd-list {
    > p {
      margin: 10px 0;
    }
  }
}
.article-kinds {
  width: 200px;
  height: 300px;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  padding: 10px;
  .tree-container {
    margin-top: 10px;
    height: 238px;
    overflow: auto;
    > p {
      margin-bottom: 10px;
      > label {
        cursor: pointer;
        > span {
          padding-left: 10px;
        }
      }
    }
  }
}
.article-selected {
  width: 200px;
  margin-left: 10px;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  border-radius: 4px;
  height: 300px;
  .title-info {
    height: 36px;
    line-height: 36px;
    border-bottom: 1px solid #f5f5f5;
    padding: 0 10px;
  }
  .article-selected-container {
    max-height: 250px;
    overflow: auto;
    > p {
      margin: 10px;
    }
  }
}
.pack-container {
  .container {
    .account-item {
      border-radius: 4px;
      background: #e9ecf1;
      font-size: 13px;
      padding: 8px;
      margin: 10px;
      transition: all 0.2s;
      &:hover {
        color: #00bf8a;
      }
    }
  }
}
.package-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.ai-largesse {
  width: 200px;
  height: 200px;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  padding: 10px;
  padding-top: 0;
  .title-info {
    height: 36px;
    line-height: 36px;
    border-bottom: 1px solid #f5f5f5;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .el-checkbox {
    width: 100%;
  }
}
</style>
