<template>
  <div class="small-plan-part">
    <div class="title flex-row-between-center">
      <span>检测链接</span>
      <span class="edit" @click="handleEditLink">编辑</span>
    </div>
    <div class="container">
      <el-dialog title="检测链接编辑" :visible.sync="showLink" width="700px">
        <div class="link-box">
          <div class="link-left">
            <p
              v-for="i in 10"
              :class="{ active: i == currentAccount }"
              @click="handleChangeAccount(i)"
            >
              重庆吉吉猫
            </p>
          </div>
          <div class="link-right">
            <el-form :model="params" :rules="rules" label-width="90px">
              <el-form-item label="展示：" prop="show">
                <el-input v-model="params.show" style="width: 350px"></el-input>
              </el-form-item>
              <el-form-item label="点击：">
                <el-input
                  v-model="params.click"
                  style="width: 350px"
                ></el-input>
              </el-form-item>
              <el-form-item label="视频播放：">
                <el-input v-model="params.play" style="width: 350px"></el-input>
              </el-form-item>
              <el-form-item label="视频播完：">
                <el-input
                  v-model="params.played"
                  style="width: 350px"
                ></el-input>
              </el-form-item>
              <el-form-item label="有效播放：">
                <el-input
                  v-model="params.valid"
                  style="width: 350px"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <span slot="footer">
          <el-button @click="showLink = false">取消</el-button>
          <el-button type="primary" @click="showLink = false">确定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入内容"));
      } else {
        if (/^https/.test(value)) {
          this.$refs.ruleForm.validateField("show");
        }
        callback();
      }
    };
    return {
      showLink: false,
      params: {},
      currentAccount: 1,
      rules: {
        show: [{ validator: validatePass, trigger: "blur" }],
      },
    };
  },
  mounted() {},
  watch: {},
  methods: {
    handleChangeAccount(i) {
      this.currentAccount = i;
    },
    handleEditLink() {
      this.showLink = true;
    },
  },
  components: {},
};
</script>
<style lang='scss' scoped>
.link-box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  .link-left {
    width: 140px;
    height: 255px;
    overflow-y: auto;
    text-overflow: ellipsis;
    p {
      width: 120px;
      line-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      &.active {
        color: #00bf8a;
      }
    }
  }
  .link-right {
    width: 500px;
  }
}
</style>