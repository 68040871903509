<template>
  <el-drawer
    append-to-body
    title="选择目标"
    :visible="showExternalAction"
    size="600px"
    :destroy-on-close="true"
    :before-close="handleClose"
  >
    <div class="link-container">
      <dy-table
        :height="'700'"
        :dataList="dataList"
        :rowList="rowArrayList"
      >
        <template #filter>
          <el-select
            style="width: 200px; margin-left: 10px"
            v-model="assetType"
            placeholder="请选择"
            clearable
            @change="getList"
          >
            <el-option label="三方落地页" :value="'THIRD_EXTERNAL'"></el-option>
            <el-option label="应用" :value="'APP'"></el-option>
            <el-option label="快应用" :value="'QUICK_APP'"></el-option>
          </el-select>
        </template>
        <template #deep_optimization_name="{ row }">
          <span>{{ deepName(row) }}</span>
        </template>
        <template #operation="{ row }">
          <el-button
            class="button-small"
            size="small"
            type="primary"
            @click="handleApply(row)"
          >应用
          </el-button
          >
        </template>
      </dy-table>
    </div>
  </el-drawer>
</template>
<script>
import {optimizedGoal} from "@/api/toutiao";

export default {
  props: {
    currenAccountId: Number,
    showExternalAction: {
      type: Boolean,
      default: false
    },
    landingType: String,
    assetsId: Number,
    marketingPurpose: String,
    applyName: String,
  },
  data() {
    return {
      assetType: 'QUICK_APP',
      rowArrayList: [
        {
          label: "优化目标",
          prop: "optimization_name",
        },
        {
          label: "深度目标",
          prop: "deep_optimization_name",
          slot: true,
        },
        {
          label: "操作",
          action: "operation",
        },
      ],
      dataList: [],
    }
  },
  mounted() {
  },
  watch: {
    showExternalAction(newVal) {
      if (newVal) {
        this.getList();
      }
    }
  },
  methods: {
    deepName(row) {
      if (row.deep_goals.length !== 0) {
        return row.deep_goals[0].optimization_name;
      }
      return "";
    },
    handleClose() {
      this.$emit('close')
      this.dataList = [];
    },
    getList() {
      if (!this.assetsId){
        return this.$message.error("请求选择应用");
      }
      optimizedGoal({
        advertiserId: this.currenAccountId,
        landingType: this.landingType,
        marketingPurpose: this.marketingPurpose,
        assetType: this.assetType,
        assetId: this.assetsId,
        packageName: this.applyName,
      }).then((res) => {
        this.dataList = res.goals;
      })
    },
    handleApply(row) {
      this.$emit("handExternalActionSet", row);
      this.$emit("close");
    }
  },
}
</script>
